import "../pages-css/services.css";

import { SectionMain } from "./SectionMain";
import { Section } from "./Section";
import { des } from "../assets/description";
import { Block } from "./Block";


import mvp from "../assets/images/MVP.png";

import { Rocket } from "../assets/svg/Rocket";
import { Businesses } from "../assets/svg/Businesses";
import { Startups } from "../assets/svg/Startups";
import { Enterprise } from "../assets/svg/Enterprise";
import { Money } from "../assets/svg/Money";
import { Forward } from "../assets/svg/Forward";

function MVP() {
    const name = des.pages.button.mvp;
    const header = {
        main:des.pages.header.sectionServices.mvp,
        services: des.pages.header.mvpSections.services,
        solution: des.pages.header.mvpSections.solution,
    };
    const description = {
        main:des.pages.description.mvp,
        services: des.pages.description.mvpSections.services,
        solution: des.pages.description.mvpSections.solution,
    };
    const {block1, block2, block3}= des.pages.description.mvpBlocks;



    const subheader = [
        des.pages.subheader.mvpSections.services,
        des.pages.subheader.mvpSections.solution,
    ];
        return(
            <>
                <SectionMain
                 imgPath={mvp} 
                 alt="MVP" 
                 path="/contacts" 
                 classBtn="button button--contact" 
                 classTextBtn="button--text" 
                 name={name} 
                 header={header.main}
                 description={description.main}/>

                <Section 
                 header={header.services}
                 description={description.services}
                 sectionClass="section section-block--services"
                 wrapperClass="blocks--services"
                 Blocks={
                    <>
                        <Block blockClass="block--services" subheader={subheader[0].block1} blockDescr={block1}image={<Rocket/>}/>
                        <Block blockClass="block--services" subheader={subheader[0].block2} blockDescr={block2}image={<Money/>}/>
                        <Block blockClass="block--services" subheader={subheader[0].block3} blockDescr={block3}image={<Forward/>}/>
                    </>
                }
                />
                <Section 
                 header={header.solution}
                 description={description.solution}
                 sectionClass="section--secondary section-block--services"
                 wrapperClass="blocks--services"
                 Blocks={
                    <>
                        <Block blockClass="block--services" subheader={subheader[1].block1} image={<Startups/>}/>
                        <Block blockClass="block--services" subheader={subheader[1].block2} image={<Businesses/>}/>
                        <Block blockClass="block--services" subheader={subheader[1].block3} image={<Enterprise/>}/>
                    </>
                }
                />

            </>
        )

}

export { MVP };