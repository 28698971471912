import { Link } from 'react-router-dom';


function SubNavItem(props) {

    const {path, name, image, description} = props;

    return(
        <div className="sub-nav--item">
            <Link to={path}>
                {image}
                <div>
                    <span className="sub-nav--item_header">{name}</span>
                    <div className="sub-nav--item_text">{description}</div>
                </div>
            </Link>
    </div>
    )
}
export { SubNavItem };