import { des } from "../assets/description";
import { QualityItems } from "../QyalityElem/QualityItems";

function Quality() {
    const header = des.quality.header;
    const description = des.quality.subHeader;
    return(
        <section className="section section--quality" id="quality">
            <div className="container">
                <h1>{header}</h1>
                <span>{description}</span>
                <QualityItems/>
            </div>

        </section>
    )
}
export { Quality }