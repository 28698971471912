import { SectionBanner } from "./BlockchainPageSections/SectionBanner";
import { SectionDescription } from "./BlockchainPageSections/SectionDescription";
import { SectionDo } from "./BlockchainPageSections/SectionDo";
import { SectionProcess } from "./BlockchainPageSections/SectionProcess";
import { SectionTechnologies } from "./BlockchainPageSections/SectionTechnologies";

function Blockchain({openModal=Function.prototype}) {
    return(
        <>
            <SectionBanner openModal={openModal}/>
            <SectionDescription />
            <SectionDo/>
            <SectionTechnologies/>
            <SectionProcess/>
        </>
    )
}

export { Blockchain };