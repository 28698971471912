import { Side } from "./Side";
import { des } from "../../assets/description";
import { ContactUs } from  "../../assets/svg/ContactUs";
import { Office } from "../../assets/svg/Office";
import { Email } from "./Email"




function ContactSide() {
    const sideName = des.contactSide.sideName;
    const itemName1 = des.contactSide.itemName1;
    const itemName2 = des.contactSide.itemName2;

    return(
        <div className="contact--side"> 
            <Side sideClass="side--contact-us" image={<ContactUs/>} sideName={sideName[0]} itemName1={itemName1[0]} itemName2={itemName2[0]} email={<Email/>}/>
            <Side sideClass="side--adress" image={<Office/>}  sideName={sideName[1]} itemName1={itemName1[1]} itemName2={itemName2[1]} />
        </div>
    )
}
export { ContactSide }