import { colors } from "../colors";

function Magnet() {
    return(

        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" fill={colors.red}>
                <g>
                    <g>
                        <path d="M116.001,312.002c-5.52,0-10,4.48-10,10s4.48,10,10,10s10-4.48,10-10C126.001,316.482,121.521,312.002,116.001,312.002z"
                            />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M435.999,120.003H336c-5.522,0-10,4.477-10,10v191.999c0,38.598-31.402,70-70,70c-38.525,0-70-31.168-70-70V130.003
                            c0-5.523-4.478-10-10-10H76.001c-5.522,0-10,4.477-10,10v191.999C66.001,426.767,151.234,512,256,512
                            s189.999-85.233,189.999-189.999V130.003C445.999,124.48,441.521,120.003,435.999,120.003z M86.001,140.003h80v72h-80V140.003z
                            M425.999,322.002c0,93.737-76.262,169.999-169.999,169.999S86.001,415.739,86.001,322.002v-89.999h80v89.999
                            c0,49.533,40.074,90,89.999,90c49.626,0,89.999-40.374,89.999-90v-89.999h80V322.002z M425.999,212.002h-80v-72h80V212.002z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M177.53,425.577c-22.037-16.739-38.457-40.467-46.238-66.809c-1.565-5.297-7.126-8.326-12.424-6.758
                            c-5.296,1.564-8.322,7.126-6.758,12.423c8.979,30.4,27.916,57.771,53.322,77.07c4.396,3.34,10.671,2.485,14.012-1.914
                            C182.785,435.192,181.928,428.918,177.53,425.577z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M144.508,44.746c-1.822-2.948-5.041-4.743-8.507-4.743h-23.819l12.764-25.528c2.469-4.94,0.467-10.947-4.473-13.417
                            c-4.939-2.471-10.946-0.468-13.416,4.472l-20,40c-1.55,3.1-1.384,6.781,0.438,9.729c1.822,2.948,5.041,4.743,8.507,4.743h23.819
                            L107.057,85.53c-2.47,4.94-0.468,10.947,4.473,13.417c4.956,2.478,10.955,0.451,13.416-4.472l20-40
                            C146.496,51.375,146.33,47.694,144.508,44.746z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M424.506,44.746c-1.822-2.948-5.041-4.743-8.507-4.743H392.18l12.764-25.528c2.469-4.94,0.467-10.947-4.473-13.417
                            c-4.939-2.471-10.946-0.468-13.416,4.472l-20,40c-1.55,3.1-1.384,6.781,0.438,9.729s5.041,4.743,8.507,4.743h23.819L387.055,85.53
                            c-2.47,4.94-0.468,10.947,4.473,13.417c4.956,2.478,10.955,0.451,13.416-4.472l20-40
                            C426.494,51.375,426.328,47.694,424.506,44.746z"/>
                    </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
        </svg>

    )
}
export { Magnet }