function Email() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="461.000000pt" height="61.000000pt" viewBox="0 0 461.000000 61.000000">

            <g transform="translate(0.000000,61.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M0 375 l0 -235 25 0 25 0 0 114 0 114 31 27 c24 20 41 25 68 23 51 -4 61 -30 61 -165 l0 -113 25 0 25 0 0 115 c0 76 -5 127 -14 149 -25 59 -110 72 -170 25 l-26 -20 0 100 0 101 -25 0 -25 0 0 -235z"/>
                <path d="M720 375 l0 -235 25 0 25 0 0 235 0 235 -25 0 -25 0 0 -235z"/>
                <path d="M890 375 l0 -235 25 0 25 0 0 235 0 235 -25 0 -25 0 0 -235z"/>
                <path d="M3850 520 c0 -49 -2 -90 -3 -90 -2 0 -19 7 -36 16 -64 32 -140 1 -172 -69 -48 -107 0 -228 97 -243 34 -5 51 -2 78 14 28 17 35 18 40 6 3 -8 15 -14 26 -14 20 0 20 5 20 235 l0 235 -25 0 c-25 0 -25 -1 -25 -90z m-26 -121 c24 -19 26 -26 26 -104 0 -78 -2 -85 -26 -104 -15 -12 -41 -21 -58 -21 -87 0 -125 142 -59 221 30 35 78 39 117 8z"/>
                <path d="M1648 577 c-134 -38 -207 -156 -196 -317 13 -175 146 -275 340 -255 91 8 128 22 128 46 0 10 -1 19 -2 19 -2 0 -25 -7 -52 -15 -57 -18 -155 -19 -210 -4 -58 16 -123 81 -141 142 -55 184 47 356 210 357 77 0 123 -17 169 -64 52 -51 71 -112 63 -193 -8 -76 -52 -135 -91 -120 -13 5 -16 23 -16 111 0 97 -2 107 -25 134 -30 36 -68 46 -135 37 -39 -5 -50 -11 -50 -25 0 -16 7 -17 59 -13 50 5 61 3 80 -16 11 -11 21 -34 21 -49 l0 -29 -66 1 c-62 1 -67 -1 -96 -33 -26 -29 -30 -39 -25 -74 3 -22 14 -49 25 -59 28 -29 89 -33 134 -10 36 19 36 19 53 -1 24 -28 87 -18 126 19 31 30 59 106 59 159 0 96 -65 200 -147 237 -56 25 -155 31 -215 15z m130 -293 c33 -9 31 -66 -4 -93 -55 -44 -124 -10 -108 53 10 41 51 56 112 40z"/>
                <path d="M3030 550 c0 -25 4 -30 25 -30 21 0 25 5 25 30 0 25 -4 30 -25 30 -21 0 -25 -5 -25 -30z"/>
                <path d="M3220 495 c0 -43 -1 -45 -30 -45 -23 0 -30 -4 -30 -20 0 -16 7 -20 30 -20 l30 0 0 -118 c0 -107 2 -121 20 -137 28 -25 113 -27 118 -3 2 13 -5 17 -35 20 -51 4 -63 30 -63 146 l0 92 50 0 c43 0 50 3 50 20 0 17 -7 20 -50 20 l-50 0 0 45 c0 38 -3 45 -20 45 -17 0 -20 -7 -20 -45z"/>
                <path d="M443 446 c-89 -40 -111 -200 -39 -272 37 -37 98 -51 167 -37 35 7 45 13 47 31 3 20 1 21 -23 12 -40 -15 -108 -12 -134 6 -21 15 -51 70 -51 94 0 6 41 10 110 10 98 0 110 2 110 18 0 34 -21 93 -43 117 -28 32 -98 42 -144 21z m104 -48 c13 -12 23 -32 23 -45 0 -22 -3 -23 -75 -23 -67 0 -75 2 -75 19 0 27 48 71 78 71 14 0 36 -10 49 -22z"/>
                <path d="M1130 443 c-43 -22 -80 -91 -80 -148 0 -57 37 -126 80 -148 41 -21 115 -17 154 8 78 51 78 229 0 280 -39 25 -113 29 -154 8z m127 -47 c26 -23 28 -29 28 -101 0 -72 -2 -78 -28 -101 -81 -69 -179 28 -147 145 20 76 93 104 147 57z"/>
                <path d="M2190 443 c-43 -22 -80 -91 -80 -148 0 -57 37 -126 80 -148 41 -21 115 -17 154 8 78 51 78 229 0 280 -39 25 -113 29 -154 8z m127 -47 c26 -23 28 -29 28 -101 0 -72 -2 -78 -28 -101 -81 -69 -179 28 -147 145 20 76 93 104 147 57z"/>
                <path d="M4083 446 c-89 -40 -111 -200 -39 -272 37 -37 98 -51 167 -37 35 7 45 13 47 31 3 20 1 21 -23 12 -40 -15 -108 -12 -134 6 -21 15 -51 70 -51 94 0 6 41 10 110 10 98 0 110 2 110 18 0 34 -21 93 -43 117 -28 32 -98 42 -144 21z m104 -48 c13 -12 23 -32 23 -45 0 -22 -3 -23 -75 -23 -67 0 -75 2 -75 19 0 27 48 71 78 71 14 0 36 -10 49 -22z"/>
                <path d="M2480 430 c0 -18 7 -20 85 -20 l85 0 -90 -125 c-50 -69 -90 -130 -90 -135 0 -6 49 -10 125 -10 118 0 125 1 125 20 0 17 -8 20 -89 22 l-88 3 83 115 c46 63 84 123 84 133 0 15 -12 17 -115 17 -108 0 -115 -1 -115 -20z"/>
                <path d="M3030 295 l0 -155 25 0 25 0 0 155 0 155 -25 0 -25 0 0 -155z"/>
                <path d="M4340 398 c89 -240 96 -253 125 -253 26 0 30 7 85 150 45 117 54 151 43 153 -30 7 -41 -10 -82 -128 -24 -66 -45 -119 -48 -116 -3 3 -24 58 -48 123 -38 107 -44 118 -69 121 l-26 3 20 -53z"/>
                <path d="M2780 300 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20 -63 0 -70 -2 -70 -20z"/>
                <path d="M3460 170 c0 -27 3 -30 30 -30 27 0 30 3 30 30 0 27 -3 30 -30 30 -27 0 -30 -3 -30 -30z"/>
            </g>
</svg>
    )
}

export { Email }