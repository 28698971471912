import styled from "styled-components";


const BlockWrapper = styled.div`
text-align: left;
margin-bottom: 30px;
width: 50vw;
@media screen and (max-width: 1000px) {
    width: 100%;
}
`;

const H2 = styled.h2`
    margin-bottom: 15px;
`


const Block = ({subheader, p1, p2, p3, p4, p5, p6, p7, p8}) => {
    return (
        <BlockWrapper>
            <H2>{subheader}</H2>
                {p1}
                {p2}
                {p3}
                {p4}
                {p5}
                {p6}
                {p7}
                {p8}            
        </BlockWrapper>
    );
};

export { Block };