import styled from "styled-components";

import { Block } from "./Block";
import privacy from "./privacy-policy";

const Privacy = styled.div`
    padding: 60px 5% 10px;
    margin: 60px 0 10px;
    display: grid;
    place-content: center;
    position: relative;
    `
const H1 = styled.h1`
    font-size: 36px;
    margin-bottom: 30px;
    color: var(--color-secondary);
    width: 50vw;
    @media screen and (max-width: 1000px) {
    width: 100%;
   }
`
const Separator = styled.div`
   width: 90%;
   height:1px;
   background: rgba(0, 0, 0, .2);
   margin: 0 auto;
`
const P = styled.p`
    margin: ${props => props.margin || '0'};
    color: var(--color);
`



const PrivacyPolicy = () => {
    const {header, subheader, block1, block2, block3, block4, block5, block6, block7, block8,
           block9, block10, block11, block12, block13, block14, block15, block16, block17,
           block18, block19, block20} = privacy;
    return (
       <>
        <Privacy>
            <H1>{header}</H1>
            <Block subheader={subheader[0]} 
                   p1={<P margin='0 0 10px'>{block1[0]}</P>}
                   p2={<P margin='0 0 10px'>{block1[1]}</P>}
                   p3={<P>{block1[2]}</P>}
                   p4={<P>{block1[3]}</P>}
                   p5={<P>{block1[4]}</P>}
                   p6={<P>{block1[5]}</P>} 
                   p7={<P>{block1[6]}</P>}
                   p8={<P>{block1[7]}</P>}/>
            <Block subheader={subheader[1]}
                   p1={<P>{block2[0]}</P>} />
            <Block subheader={subheader[2]} 
                   p1={<P margin='0 0 10px'>{block3[0]}</P>}
                   p2={<P margin='0 0 10px'>{block3[1]}</P>}
                   p3={<P margin='0 0 10px'>{block3[2]}</P>}
                   p4={<P>{block3[3]}</P>}/>
            <Block subheader={subheader[3]}
                   p1={<P>{block4[0]}</P>} />
            <Block subheader={subheader[4]}
                   p1={<P>{block5[0]}</P>} />
            <Block subheader={subheader[5]} 
                   p1={<P margin='0 0 10px'>{block6[0]}</P>}
                   p2={<P margin='0 0 10px'>{block6[1]}</P>}
                   p3={<P >{block6[2]}</P>}/>
            <Block subheader={subheader[6]}
                   p1={<P>{block7[0]}</P>} />
            <Block subheader={subheader[7]}
                   p1={<P>{block8[0]}</P>} />
             <Block subheader={subheader[8]}
                   p1={<P>{block9[0]}</P>} />
            <Block subheader={subheader[9]} 
                   p1={<P margin='0 0 10px'>{block10[0]}</P>}
                   p2={<P>{block10[1]}</P>}/>
            <Block subheader={subheader[10]} 
                   p1={<P margin='0 0 10px'>{block11[0]}</P>}
                   p2={<P margin='0 0 10px'>{block11[1]}</P>}
                   p3={<P>{block11[2]}</P>}
                   p4={<P>{block11[3]}</P>}
                   p5={<P>{block11[4]}</P>}
                   p6={<P>{block11[5]}</P>} 
                   p7={<P margin='0 0 10px'>{block11[6]}</P>}
                   p8={<P>{block11[7]}</P>}/>
            <Block subheader={subheader[11]} 
                   p1={<P margin='0 0 10px'>{block12[0]}</P>}
                   p2={<P>{block12[1]}</P>}
                   p3={<P>{block12[2]}</P>}
                   p4={<P>{block12[3]}</P>}
                   p5={<P margin='0 0 10px'>{block12[4]}</P>}
                   p6={<P margin='0 0 10px'>{block12[5]}</P>} 
                   p7={<P>{block12[6]}</P>}/>
            <Block subheader={subheader[12]} 
                   p1={<P margin='0 0 10px'>{block13[0]}</P>}
                   p2={<P margin='0 0 10px'>{block13[1]}</P>}
                   p3={<P margin='0 0 10px'>{block13[2]}</P>}
                   p4={<P margin='0 0 10px'>{block13[3]}</P>}
                   p5={<P>{block13[4]}</P>}
                   p6={<P>{block13[5]}</P>} 
                   p7={<P margin='0 0 10px'>{block13[6]}</P>}
                   p8={<P>{block13[7]}</P>}/>
            <Block subheader={subheader[13]} 
                   p1={<P margin='0 0 10px'>{block14[0]}</P>}
                   p2={<P margin='0 0 10px'>{block14[1]}</P>}
                   p3={<P margin='0 0 10px'>{block14[2]}</P>}
                   p4={<P>{block14[3]}</P>}/>
            <Block subheader={subheader[14]} 
                   p1={<P margin='0 0 10px'>{block15[0]}</P>}
                   p2={<P margin='0 0 10px'>{block15[1]}</P>}
                   p3={<P>{block15[2]}</P>}/>
            <Block subheader={subheader[15]} 
                   p1={<P>{block16[0]}</P>}/>
            <Block subheader={subheader[16]} 
                   p1={<P margin='0 0 10px'>{block17[0]}</P>}
                   p2={<P>{block17[1]}</P>}/>
            <Block subheader={subheader[17]} 
                   p1={<P>{block18[0]}</P>}/>
            <Block subheader={subheader[18]} 
                   p1={<P>{block19[0]}</P>}/>
            <Block subheader={subheader[19]} 
                   p1={<P margin='0 0 10px'>{block20[0]}</P>}
                   p2={<P>{block20[1]}</P>}
                   p3={<P margin='0 0 10px'>{block20[2]}</P>}
                   p4={<P>{block20[3]}</P>}/>

        </Privacy>
        <Separator></Separator>
        </>
    )
}

export { PrivacyPolicy }