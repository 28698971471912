
import { AboutUs } from "./AboutUs";
import "../pages-css/company.css";
import { des } from "../assets/description";
import work from "../assets/images/how-we-work.png";


function About() {
    const header = des.pages.header.about;
    const description = des.pages.description.about;
    return(
        <>
            <AboutUs header={header[0]} description={description}/>
            <AboutUs header={header[1]} path={work} alt="work"/>
        </>
    )
}

export { About };