import { colors } from "../colors";

function Resize() {
    return(

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" fill={colors.red}>
                    <g>
                        <g>
                            <path d="M501.801,0H10.199C4.566,0,0,4.566,0,10.199v491.602C0,507.434,4.566,512,10.199,512h491.602
                                c5.633,0,10.199-4.566,10.199-10.199V10.199C512,4.566,507.434,0,501.801,0z M491.602,491.602H20.398V20.398h471.203V491.602z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M451.453,50.348H278.217c-4.126,0-7.844,2.484-9.423,6.296c-1.579,3.811-0.706,8.198,2.211,11.115l44.922,44.922
                                L244.708,183.9c-1.912,1.912-2.987,4.507-2.987,7.212c0,2.705,1.075,5.299,2.987,7.212l68.968,68.968
                                c1.912,1.912,4.507,2.987,7.212,2.987s5.299-1.075,7.213-2.986l71.218-71.219l44.922,44.922c2.917,2.917,7.303,3.789,11.115,2.211
                                c3.81-1.579,6.296-5.298,6.296-9.423V60.548C461.653,54.915,457.086,50.348,451.453,50.348z M441.253,209.16l-34.723-34.723
                                c-1.912-1.912-4.507-2.987-7.212-2.987c-2.705,0-5.3,1.075-7.212,2.987l-71.218,71.219l-54.544-54.544l71.219-71.219
                                c1.912-1.912,2.987-4.507,2.987-7.212s-1.075-5.299-2.987-7.212L302.84,70.746h138.413V209.16z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M270.986,309.981l-68.968-68.968c-3.984-3.982-10.442-3.982-14.425,0l-71.204,71.204l-44.922-44.922
                                c-2.917-2.916-7.305-3.79-11.115-2.21c-3.81,1.579-6.296,5.299-6.296,9.423v173.238c0,5.633,4.566,10.199,10.199,10.199h173.238
                                c4.125,0,7.843-2.486,9.422-6.297c1.579-3.81,0.707-8.198-2.21-11.115l-44.922-44.922l71.204-71.204
                                C274.969,320.422,274.969,313.964,270.986,309.981z M178.145,388.398c-3.983,3.984-3.983,10.442,0,14.425l34.723,34.723H74.454
                                V299.131l34.722,34.723c3.984,3.983,10.442,3.983,14.425,0l71.204-71.204l54.544,54.544L178.145,388.398z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M64.255,96.892c-5.633,0-10.199,4.566-10.199,10.199v54.056c0,5.633,4.566,10.199,10.199,10.199
                                c5.633,0,10.199-4.566,10.199-10.199v-54.056C74.454,101.459,69.888,96.892,64.255,96.892z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M64.255,56.879c-5.633,0-10.199,4.566-10.199,10.199v4.316c0,5.633,4.566,10.199,10.199,10.199
                                c5.633,0,10.199-4.567,10.199-10.199v-4.316C74.454,61.445,69.888,56.879,64.255,56.879z"/>
                        </g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
            </svg>

    )
}

export { Resize }