import { LoginForm } from "./LoginForm";
import { ModalClose } from "./ModalClose";

const LoginContent = ({closeModal = Function.prototype, err = Function.prototype}) => {

    return (
        <div className="login-modal">
            <ModalClose closeModal={closeModal}/>
            <h1 className="login-header">Login</h1>
            <LoginForm  err={err}/>
        </div>
    )
}

export { LoginContent }