import { des } from "../assets/description";
import { AdvItems } from "./AdvElem/AdvItems";
function Advantages() {
    const header = des.advantages.header;
    return(
        <section className="section--secondary section--advantages">
            <div className="container">
                <h1>{header}</h1>
                <AdvItems />
            </div>
        </section>

    )
}

export { Advantages }