
function AdvItem({image, text}) {
    return(
       
             <div className="advantages--item">
                <div className="advantages--img">
                    {image}
                </div>
                <div>{text}</div>
             </div>
       
    )
}

export { AdvItem }