import { SubNavItem } from "./SubNavItem";
import {des} from '../assets/description';
import { Mvp } from '../assets/svg/Mvp';
import { SaaS } from "../assets/svg/SaaS";
import {Pwa} from "../assets/svg/Pwa";
import {Marketplace} from "../assets/svg/Marketplace"
import { Blockchain } from "../assets/svg/Blockchain";
import { Management } from "../assets/svg/Management";
import { colors } from "../assets/colors";

function SubListServices() {
    return(
        <div className="sub-nav">
            <div className="sub-nav--list">
                <SubNavItem path="/saas-development" name="saas development" description={des.saas} image={<SaaS/>}/>
                <SubNavItem path="/mvp-development" name="minimum viable product development" description={des.mvp} image={<Mvp/>}/>
                <SubNavItem path="/pwa-development" name="progressive web apps development" description={des.pwa} image={<Pwa/>}/>
                <SubNavItem path="/marketplace-development" name="online marketplace development" description={des.marketplace} image={<Marketplace/>}/>
                <SubNavItem path="/blockchain-development" name="blockchain" description={des.blockchain} image={<Blockchain fill={colors.red} />}/>
                <SubNavItem path="/project-management" name="project management" description={des.management} image={<Management/>}/>
            </div>
        </div>
    )
}
export { SubListServices }