import {Link} from 'react-router-dom';
import {useState} from 'react';


function NavItem(props, e) {
    const {path, name, subList} = props;
    const [sub, setSub] = useState(false);


    function showSub(e) {
        setSub(true);
    }
    function hideSub() {
        setSub(false);   
    }
    function reverse() {
        sub ? setSub(false) : setSub(true);
        underlineHide();
    }
    function underlineShow(e) {
        let underline = e.target.nextSibling;
            underline.style.transition = 'width .3s ease';
            underline.style.width = '100%';
    }
    function underlineHide() {
        let lines = document.querySelectorAll('.underline');
        lines.forEach(line => {
            line.style.width = '';
        })
        
    }
    function show(e) {
        showSub();
        underlineShow(e);
    }
    function hide() {
        hideSub();
        underlineHide();
    }

    return(
        
        <li className="navigation--horizontal_list-item" >
            <div className="container-list--item" onMouseLeave={hide} onClick={reverse} >
                <Link to={path}  >
                    <span onMouseEnter={show}>{name}</span>
                    <span className="underline"></span>
                </Link>

               {sub && subList}
            </div>
        </li>
    )
}

export { NavItem };