

function GraphicRed(props) {
    const {fill} = props;

    return (
        <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg" fill={fill}>
            <g id="Layer_22" data-name="Layer 22"><path d="m58 1h-38a5 5 0 0 0 -5 5v3h-9a5 5 0 0 0 -5 5v30a5 5 0 0 0 5 5h14v6h-2a5 5 0 0 0 -5 5v2a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1v-2a5 5 0 0 0 -5-5h-2v-6h14a5 5 0 0 0 5-5v-7h5a5 5 0 0 0 5-5v-26a5 5 0 0 0 -5-5zm-38 2h38a3 3 0 0 1 3 3v1h-44v-1a3 3 0 0 1 3-3zm19 57v1h-24v-1a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3zm-7-5h-10v-6h10zm16-8h-42a3 3 0 0 1 -3-3v-3h48v3a3 3 0 0 1 -3 3zm3-8h-48v-25a3 3 0 0 1 3-3h9v21a5 5 0 0 0 5 5h31zm7-4h-38a3 3 0 0 1 -3-3v-23h44v23a3 3 0 0 1 -3 3z"/><path d="m47 43h2v2h-2z"/><path d="m43 43h2v2h-2z"/><path d="m39 43h2v2h-2z"/><path d="m58 11h-38a1 1 0 0 0 -1 1v20a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1v-20a1 1 0 0 0 -1-1zm-31 18h4v-2h-4v-2h2v-2h-2v-2h2v-2h-2v-2h6v14h-6zm15.11-6.17a1.7 1.7 0 0 1 -.73.17h-.76a1.7 1.7 0 0 1 -.73-.17 1.61 1.61 0 0 1 -.89-1.45 1.22 1.22 0 0 1 .68-1.1 2.39 2.39 0 0 0 1.32-1.88l1.33 1.33a2.32 2.32 0 0 1 .67 1.65 1.61 1.61 0 0 1 -.89 1.45zm-2.11 2.1a4.2 4.2 0 0 0 .62.07h.76a4.2 4.2 0 0 0 .62-.07v6.07h-2zm9-1.93h2v8h-2zm.39-2 .61-1.84.61 1.84zm7.61 10h-4v-9a1.19 1.19 0 0 0 0-.32l-2-6a1 1 0 0 0 -1.9 0l-2 6a1.19 1.19 0 0 0 -.1.32v9h-3v-7.13a3.59 3.59 0 0 0 1-2.49 4.27 4.27 0 0 0 -1.27-3.06l-3-3a1 1 0 0 0 -1.09-.21 1 1 0 0 0 -.64.89v2.15a.39.39 0 0 1 -.21.34 3.22 3.22 0 0 0 -1.79 2.89 3.59 3.59 0 0 0 1 2.49v7.13h-3v-15a1 1 0 0 0 -1-1h-8a1 1 0 0 0 -1 1v15h-4v-18h36z"/>
            </g>
        </svg>
    )
}

export { GraphicRed };