import { ImgManagement } from "./ManagementElem/ImgManagement";
import img from "../assets/images/project.png";
import { TextManagement } from "./ManagementElem/TextManagement";

function Management() {
    return(
        <section className="section--secondary section--management">
            <div className="container container--management">
                <ImgManagement path={img} alt="Project Management"/>
                <TextManagement/>
            </div>
        </section>
    )
}
export {Management};