import { Facebook } from "../assets/svg/Facebook";
import { Twitter } from "../assets/svg/Twitter";
import { GitHub } from "../assets/svg/GitHub";
import { Instagram } from "../assets/svg/Instagram";

function SideBarSocial() {
    return(
        <div className="socials socials--sidebar">
            <Facebook />
            <Twitter />
            <GitHub />
            <Instagram />
        </div>
    )
}
export { SideBarSocial };