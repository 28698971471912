import { Link } from "react-scroll";

function ArrowDown(props) {
    const { image, arrClass, scroll} = props;



    return(
        
        <Link className={`arrow-down--${arrClass}`} to={scroll} smooth={true} duration={1000}>
            {image}
        </Link>

    )
}
export {ArrowDown};