import { NavItem } from "../elements/NavItem";
import {SubListCompany} from "../elements/SubListCompany";
import { SubListServices } from "../elements/SubListServices";

function NavHorizontal() {
    return(
            <nav className="navigation--horizontal">
                <ul className="navigation--horizontal_list">
                    <NavItem path="/" name="home" />
                    <NavItem path="#!" name="services" subList={<SubListServices/>} />
                    <NavItem path="/blockchain-development" name="blockchain" />
                    <NavItem path="#!" name="company"  subList={<SubListCompany/>} />
                    <NavItem path="/contacts" name="contact" />           
                </ul>
            </nav>
    )
}

export {NavHorizontal};