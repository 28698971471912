import { LoginInput } from "./LoginInput";
import {Lock} from "../assets/svg/login/Lock";
import { Letter } from "../assets/svg/login/Letter";
import { useState, useEffect } from "react";


const LoginForm = ({err = Function.prototype}) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailDirty, setEmailDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);

    const [emailError, setEmailError] = useState(`Email can't be empty`);
    const [passwordError, setPasswordError] = useState(`Password can't be empty`);

    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (emailError || passwordError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [emailError, passwordError])

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!re.test(String(e.target.value).toLocaleLowerCase()) ) {
            setEmailError('Incorrect email')
            if(!e.target.value) setEmailError(`Email can't be empty`)
        } else {
            setEmailError('')
        }
    }

    const passwordHandler = (e) => {
        setPassword(e.target.value);

        if (e.target.value.length < 8) {
            setPasswordError('Password must be at least 8 symbols ');
            if(!e.target.value) setPasswordError(`Password can't be empty`);
        } else {
            setPasswordError('')
        }
    }

    const blurHandler = (e) => {
        // eslint-disable-next-line default-case
        switch (e.target.name) {
            case 'email':
                setEmailDirty(true)
                break;
            // eslint-disable-next-line no-fallthrough
            case 'password':
                setPasswordDirty(true)
                break;

        }
    }

    const focusHandler = (e) => {
            // eslint-disable-next-line default-case
             switch (e.target.name) {
                case 'email':
                    setEmailDirty(false)
                    break;
            // eslint-disable-next-line no-fallthrough
                case 'password':
                    setPasswordDirty(false)
                    break;
        
                }
    }
   
    return (
        <form className="form-login" >
            {(emailDirty && emailError) && <div style={{color: 'red'}}>{emailError}</div>}
            <LoginInput 
                type='email' 
                placeholder="Enter your email...." 
                image={<Lock/>} 
                name='email'
                blurHandler={blurHandler}
                handler={emailHandler}
                focus={focusHandler}
                value={email} 
            />
            {(passwordDirty && passwordError) && <div style={{color: 'red'}}>{passwordError}</div>}
            <LoginInput 
                type='password' 
                placeholder="Enter your password...."
                image={<Letter/>}
                name='password'
                blurHandler={blurHandler}
                handler={passwordHandler}
                focus={focusHandler}
                value={password} 
            />
            
            <button  disabled={!formValid} className="button button--contact" onClick={err} >Sign in</button>
        </form>
    )
}

export { LoginForm }