import { colors } from "../colors";

function Shield() {
    return(

    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"x="0px" y="0px" viewBox="0 0 512 512" fill={colors.red}>
        <g>
            <g>
                <path d="M256.281,232.255c-9.426,0-17.067,7.641-17.067,17.067c-0.001,6.815,4.004,12.68,9.779,15.416v20.747
                    c0,4.024,3.262,7.287,7.287,7.287c4.025,0,7.287-3.262,7.287-7.287v-20.747c5.776-2.736,9.781-8.601,9.781-15.416
                    C273.348,239.896,265.707,232.255,256.281,232.255z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M438.138,111.149c-31.369-12.413-56.267-35.275-70.108-64.373c-3.764-7.913-11.9-13.027-20.729-13.027H164.699
                    c-8.828,0-16.964,5.113-20.728,13.027c-13.84,29.098-38.739,51.96-70.108,64.373c-8.799,3.481-14.484,11.869-14.484,21.368v44.412
                    c0,58.219,16.977,115.73,49.096,166.318c32.431,51.083,79.425,93.774,135.898,123.456l0.92,0.484
                    c3.353,1.762,7.03,2.643,10.708,2.643c3.676,0,7.354-0.881,10.705-2.642l0.923-0.485
                    c56.474-29.683,103.467-72.373,135.899-123.456c32.118-50.589,49.095-108.1,49.095-166.318v-44.412
                    C452.623,123.017,446.936,114.63,438.138,111.149z M438.05,176.93c0,55.447-16.192,110.257-46.825,158.507
                    c-31.069,48.936-76.153,89.867-130.374,118.367l-0.923,0.484c-2.459,1.294-5.395,1.293-7.855,0l-0.92-0.483
                    c-54.223-28.5-99.307-69.431-130.377-118.368c-30.633-48.249-46.825-103.06-46.825-158.508v-44.412c0-3.481,2.07-6.55,5.273-7.817
                    c34.81-13.774,62.477-39.225,77.906-71.664c1.361-2.863,4.332-4.713,7.568-4.713h182.604c3.236,0,6.206,1.85,7.568,4.713
                    c15.43,32.439,43.098,57.89,77.907,71.665c3.203,1.267,5.273,4.336,5.273,7.817V176.93z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M474.672,246.08c-3.925-0.888-7.827,1.575-8.713,5.501c-22.192,98.177-89.78,183.32-185.436,233.597l-22.229,11.684
                    c-1.435,0.754-3.149,0.754-4.584,0l-22.229-11.684c-95.806-50.356-163.428-135.646-185.527-234
                    c-0.881-3.926-4.781-6.395-8.706-5.512c-3.926,0.882-6.394,4.78-5.512,8.706c23.036,102.531,93.369,191.358,192.965,243.705
                    l22.229,11.684c2.841,1.492,5.956,2.239,9.072,2.239c3.115,0,6.232-0.747,9.072-2.24l22.229-11.684
                    c99.439-52.265,169.737-140.938,192.871-243.283C481.06,250.868,478.597,246.967,474.672,246.08z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M471.125,83.377c-38.909-4.105-69.503-31.416-74.4-66.414C395.372,7.292,387.061,0,377.393,0H134.609
                    c-9.667,0-17.978,7.292-19.331,16.963c-4.898,34.999-35.492,62.309-74.4,66.414c-9.859,1.04-17.294,9.369-17.294,19.373v78.244
                    c0,11.37,0.589,22.865,1.751,34.167c0.386,3.75,3.55,6.542,7.24,6.542c0.249,0,0.501-0.013,0.754-0.039
                    c4.003-0.411,6.915-3.99,6.503-7.993c-1.111-10.809-1.675-21.803-1.675-32.677V102.75c0-2.485,1.867-4.63,4.25-4.881
                    c45.576-4.808,81.476-37.248,87.304-78.887c0.358-2.555,2.417-4.409,4.898-4.409h242.783c2.481,0,4.542,1.854,4.899,4.409
                    c5.826,41.639,41.727,74.078,87.303,78.887c2.383,0.251,4.25,2.396,4.25,4.881v78.244c0,10.759-0.552,21.639-1.64,32.34
                    c-0.407,4.003,2.509,7.579,6.512,7.986c4.001,0.41,7.579-2.509,7.987-6.512c1.138-11.188,1.714-22.564,1.714-33.814V102.75
                    C488.418,92.746,480.984,84.417,471.125,83.377z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M339.46,181.865h-11.981v-34.782c0-39.259-31.939-71.198-71.199-71.198c-39.259,0-71.199,31.939-71.199,71.198v34.782
                    h-11.98c-12.778,0-23.174,10.396-23.174,23.174v104.329c0,12.778,10.396,23.174,23.174,23.174H339.46
                    c12.778,0,23.174-10.396,23.174-23.174V205.039C362.634,192.261,352.238,181.865,339.46,181.865z M199.655,147.084
                    c0-31.223,25.402-56.625,56.626-56.625s56.626,25.402,56.626,56.625v34.782H199.655V147.084z M348.061,309.368
                    c0,4.742-3.859,8.601-8.601,8.601H173.101c-4.743,0-8.601-3.859-8.601-8.601V205.039c0-4.742,3.859-8.601,8.601-8.601H339.46
                    c4.742,0,8.601,3.858,8.601,8.601V309.368z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>

    )
}
export { Shield }