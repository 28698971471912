import { colors } from "../colors";

function FastLoading() {
    return(

            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 512 512" width="512" height="512" fill={colors.red}>
                <path d="M312,72H120a8,8,0,0,0,0,16H236.984a184.781,184.781,0,0,0-57.029,40H72a8,8,0,0,0,0,16h94.121a183.5,183.5,0,0,0-31.757,64H88a8,8,0,0,0,0,16h42.791a184.842,184.842,0,0,0,0,64H88a8,8,0,0,0,0,16h46.364a183.5,183.5,0,0,0,31.757,64H72a8,8,0,0,0,0,16H179.955a184.781,184.781,0,0,0,57.029,40H120a8,8,0,0,0,0,16H312c101.458,0,184-82.542,184-184S413.458,72,312,72Zm0,352c-92.636,0-168-75.364-168-168S219.364,88,312,88s168,75.364,168,168S404.636,424,312,424Z"/><path d="M312,104c-83.813,0-152,68.187-152,152s68.187,152,152,152,152-68.187,152-152S395.813,104,312,104ZM440,264h7.758a135.443,135.443,0,0,1-34.151,82.292l-5.44-5.439a8,8,0,0,0-11.314,11.314l5.44,5.439A135.444,135.444,0,0,1,320,391.758V384a8,8,0,0,0-16,0v7.758a135.444,135.444,0,0,1-82.293-34.152l5.44-5.439a8,8,0,1,0-11.314-11.314l-5.44,5.439A135.443,135.443,0,0,1,176.242,264H184a8,8,0,0,0,0-16h-7.758a135.443,135.443,0,0,1,34.151-82.292l5.44,5.439a8,8,0,1,0,11.314-11.314l-5.44-5.439A135.444,135.444,0,0,1,304,120.242V128a8,8,0,0,0,16,0v-7.758a135.444,135.444,0,0,1,82.293,34.152l-5.44,5.439a8,8,0,0,0,11.314,11.314l5.44-5.439A135.443,135.443,0,0,1,447.758,248H440a8,8,0,0,0,0,16Z"/><path d="M397.657,234.343a8,8,0,0,0-11.314,11.314L388.686,248H334.624A24.119,24.119,0,0,0,320,233.376V195.314l2.343,2.343a8,8,0,1,0,11.314-11.314l-16-16a8,8,0,0,0-11.314,0l-16,16a8,8,0,0,0,11.314,11.314L304,195.314v38.062A24,24,0,1,0,334.624,264h54.062l-2.343,2.343a8,8,0,0,0,11.314,11.314l16-16a8,8,0,0,0,0-11.314ZM312,264a8,8,0,1,1,8-8A8.009,8.009,0,0,1,312,264Z"/><path d="M32,128a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z"/><path d="M32,368a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z"/><path d="M24,264H72a8,8,0,0,0,0-16H24a8,8,0,0,0,0,16Z"/><path d="M24,88H88a8,8,0,0,0,0-16H24a8,8,0,0,0,0,16Z"/><path d="M88,424H24a8,8,0,0,0,0,16H88a8,8,0,0,0,0-16Z"/>
            </svg>

    )
}

export { FastLoading }