
// import { Modal } from "../components/Modal";
import { Advantages } from "../sections/Advantages";
import { Blockchain } from "../sections/Blockchain";
import { Comm } from "../sections/Comm";
import { FlyingLogo } from "../sections/FlyingLogo";
import { Management } from "../sections/Management";
import { Quality } from "../sections/Quality";
import { Services } from "../sections/Services";
import { Tech } from "../sections/Tech";
import { Video } from "../sections/Video";



function Home({openModal=Function.prototype}) {


    return(
        <>
            <FlyingLogo openModal={openModal}/>
            <Quality/>
            <Comm/>
            <Services/>
            <Video />
            <Blockchain openModal={openModal}/>
            <Management/>
            <Tech/>
            <Advantages/>
            {/* {modal && <Modal closeModal={closeModal}/>} */}
        </>
    )
}

export { Home };