import { des } from "../../assets/description";
import { Item } from "./Item";
import { Contact } from "../../assets/svg/Contract";
import { BlockchainApp } from "../../assets/svg/BlockchainApp";
import { BlockchainAi } from "../../assets/svg/BlockchainAi";
import { CryptoFund } from "../../assets/svg/CryptoFund";
import { Token } from "../../assets/svg/Token";
import { Trade } from "../../assets/svg/Trade";
import { Audit } from "../../assets/svg/Audit";
import { BlockchainDev } from "../../assets/svg/BlockchainDev";

function SectionDo() {
    const header = des.pages.header.blSectDescr;
    const subheader = des.pages.header.blockchainDo.subheader;
    const description = des.pages.description.blockchainDo.description;
    const itemClass = 'do--item';
    return(
        <section className="section section--do">
            <div className="container">
                <h1>{header}</h1>
                <div className="do--items">
                    <Item subheader={subheader.smart} des={description.smart} image={<Contact/>} itemClass={itemClass}/>
                    <Item subheader={subheader.apps} des={description.apps} image={<BlockchainApp/>} itemClass={itemClass}/>
                    <Item subheader={subheader.ai} des={description.ai} image={<BlockchainAi/>} itemClass={itemClass}/>
                    <Item subheader={subheader.crypto} des={description.crypto} image={<CryptoFund/>} itemClass={itemClass}/>
                    <Item subheader={subheader.token} des={description.token} image={<Token/>} itemClass={itemClass}/>
                    <Item subheader={subheader.tranding} des={description.tranding} image={<Trade/>} itemClass={itemClass}/>
                    <Item subheader={subheader.audit} des={description.audit} image={<Audit/>} itemClass={itemClass}/>
                    <Item subheader={subheader.dev} des={description.dev} image={<BlockchainDev/>} itemClass={itemClass}/>
                </div>
            </div>
        </section>
    )
}
export { SectionDo };
