import { ElemVideo } from "./ElemVideo"
import { VideoTextMob } from "./VideoTextMob"

function VideoBlock({path, height}) {
    return(
        <div className="slider">
            <VideoTextMob/>
            <ElemVideo path={path} height={height} />
        </div>
    ) 
}

export { VideoBlock }