import { ButtonLink } from "../elements/ButtonLink"

function HeaderWrapper(props) {
    const {
        header,
        description,
        name,
        classBtn,
        classTextBtn,
        path
    } = props;
    return(
        <div className="header-wrapper--services">
            <h1 dangerouslySetInnerHTML={{__html: header}}></h1>
            <p className="description--services">{description}</p>
            <ButtonLink name={name} classBtn={classBtn} classTextBtn={classTextBtn} path={path} />
        </div>
    )
}
export { HeaderWrapper }