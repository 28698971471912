import { Image } from "../../elements/Image";
import image from "../../assets/images/blockchain.jpg";
import { des } from "../../assets/description";
import "../../pages-css/blockchain.css";
import { SectionHeader } from "./SectionHeader";

function SectionBanner({openModal=Function.prototype}) {
    const alt = des.pages.alt.blockchain;
    return (
        <section className="section section--banner">
            <div className="banner--wrapper">
                <Image path={image} alt={alt}/>
                <SectionHeader openModal={openModal}/>
            </div>
        </section>
    )
}

export { SectionBanner };