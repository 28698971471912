import { SectionMain } from "./SectionMain";
import image from "../assets/images/404.png";
import { des } from "../assets/description";

function NotFound() {
    const { Header, ButtonName, Description } = des.NOTFOUND;
    return(
        <SectionMain
        imgPath={image} 
        alt="404" 
        path="/" 
        classBtn="button button--contact" 
        classTextBtn="button--text" 
        name={ButtonName} 
        header={Header}
        description={Description}
        />
    )
}

export { NotFound };