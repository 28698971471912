import {colors} from '../colors'

function Pwa() {
    return(
        <svg id="Icons" height="512" viewBox="0 0 74 74" width="512" xmlns="http://www.w3.org/2000/svg" fill={colors.fillNav}>
            <path d="m53.5 72h-33a4 4 0 0 1 -4-4v-62a4 4 0 0 1 4-4h33a4 4 0 0 1 4 4v62a4 4 0 0 1 -4 4zm-33-68a2 2 0 0 0 -2 2v62a2 2 0 0 0 2 2h33a2 2 0 0 0 2-2v-62a2 2 0 0 0 -2-2z"/><path d="m56.5 13h-39a1 1 0 0 1 0-2h39a1 1 0 0 1 0 2z"/><path d="m56.5 59h-39a1 1 0 0 1 0-2h39a1 1 0 0 1 0 2z"/><path d="m37 68.188a3.688 3.688 0 1 1 3.688-3.688 3.692 3.692 0 0 1 -3.688 3.688zm0-5.375a1.688 1.688 0 1 0 1.688 1.687 1.689 1.689 0 0 0 -1.688-1.687z"/><path d="m41 8.75h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2z"/>
        </svg>
    )
}

export { Pwa };