import { Link } from "react-router-dom";

function VideoTextMob() {
    return(
        <div className="text-slider--mobile">
            <h1>Be a <span className="part-is-red">market leader</span> with premium custom software development</h1>
            <Link to="/contacts"  className="button button--contact" style={{margin: '0 auto'}}>
                <span className="button--text">get in touch</span>
            </Link>
        </div>
    )
}

export { VideoTextMob };