import { des } from "../../assets/description";
function TextManagement() {
    const header = des.managementSection.header;
    const text1 = des.managementSection.text1;
    const text2 = des.managementSection.text2;
    return(
        <div className="text-wrapper">
            <h1>{header}</h1>
            <p>{text1}</p>
            <br/>
            <p>{text2}</p>
        </div>
    )
}

export { TextManagement }