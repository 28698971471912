
function ServicesItem({name, image}) {
    return(
        <div className="services--item">
            <div className="item--img">
                {image}
            </div>
            <div>{name}</div>
        </div>
    )
}
export { ServicesItem };