function Angular() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250"  enableBackground="new 0 0 250 250">
            
            <path fill="#DD0031" d="M125 30l-93.1 33.2 14.2 123.1 78.9 43.7 78.9-43.7 14.2-123.1z"/>
            <path fill="#C3002F" d="M125 30v200.1-.1l78.9-43.7 14.2-123.1-93.1-33.2z"/>
            <path  fill="#FFFFFF" d="M125 52.1l-58.2 130.5h21.7l11.7-29.2h49.4l11.7 29.2h21.7l-58-130.5zm17 83.3h-34l17-40.9 17 40.9z"/>
        </svg>
    )
}
export { Angular };