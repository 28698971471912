import { HeaderDesctop } from "./HeaderDesctop";




function Header({openModal=Function.prototype, openLogin=Function.prototype}) {


    return(

            <HeaderDesctop openModal={openModal} openLogin={openLogin}/>
    )
}

export { Header };