import alegro from "../assets/images/Allegro.png";
import commerce from "../assets/images/Commerce.png";
import magneto from "../assets/images/Magento.png";
import shopify from "../assets/images/Shopify.png"
import { CommImg } from "../elements/CommImg";

function Comm() {
    return(
        <section className="section--secondary section--comm">
        <div className="container">
            <div className="wrapper-marketplace">
                <CommImg path={commerce} alt="bigcommerce"/>
                <CommImg path={alegro} alt="alegro"/>
                <CommImg path={shopify} alt="shopify"/>
                <CommImg path={magneto} alt="magneto"/>
            </div>
        </div>
    </section>
    )
}
export {Comm};