function QualityItem(props) {
    const {image, text, subheader} =props;
    return(
        <div className="quality--item">
            {image}
            <h2> {subheader} </h2>
            <p> {text} </p>
        </div>
    )
}

export { QualityItem }