function Side(props) {

    const {sideClass, image, sideName, itemName1, itemName2,email} = props;

    return(
        <div className={`side ${sideClass}`}>
            <div className="adress--side">
                {image}
                <span>{sideName}</span>
            </div>
            <div className="adress--item">{itemName1}</div>
            <div className="adress--item"> {itemName2}{email} </div>
        </div>
    )
    
}

export { Side }