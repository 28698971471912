import "../pages-css/services.css";

import { SectionMain } from "./SectionMain";
import { des } from "../assets/description";
import { Section } from "./Section";
import { Block } from "./Block";

import saas from "../assets/images/SaaS.png";

import { SaasProduct } from "../assets/svg/SaasProduct";
import { SaasIntegr } from "../assets/svg/SaasIntegr";
import { SaasSet } from "../assets/svg/SaasSet";
import { SaasCloud } from "../assets/svg/SaasCloud";
import {Ecommerce} from "../assets/svg/Ecommerce";
import {Productivity} from "../assets/svg/Productivity";
import {Hrm} from "../assets/svg/Hrm";
import {Cloud} from "../assets/svg/Cloud";
import {Crm} from "../assets/svg/Crm";
import {Erp} from "../assets/svg/Erp";
import {Cms} from "../assets/svg/Cms";
import {Inventory} from "../assets/svg/Inventory";

function SaaS() {
    const name = des.pages.button.saas;
    const header = {
        main: des.pages.header.sectionServices.saas,
        services: des.pages.header.saasSections.services,
        solution: des.pages.header.saasSections.solution
    };
    const description = {
        main: des.pages.description.saas,
        services: des.pages.description.saasSections.services,
        solution: des.pages.description.saasSections.solution
    }
    const subheader = [
        des.pages.subheader.saasSections.services,
        des.pages.subheader.saasSections.solution,
    ]
    
    return(
        <>
            <SectionMain
             imgPath={saas} 
             alt="Saas" 
             path="/contacts" 
             classBtn="button button--contact" 
             classTextBtn="button--text" 
             name={name} 
             header={header.main}
             description={description.main}/>

             <Section 
             header={header.services}
             description={description.services}
             sectionClass="section section-block--services"
             wrapperClass="blocks--services"
             Blocks={
                 <>
                 <Block blockClass="block--services" subheader={subheader[0].block1} image={<SaasProduct/>}/>
                 <Block blockClass="block--services" subheader={subheader[0].block2} image={<SaasIntegr/>}/>
                 <Block blockClass="block--services" subheader={subheader[0].block3} image={<SaasSet/>}/>
                 <Block blockClass="block--services" subheader={subheader[0].block4} image={<SaasCloud/>}/>
                 </>
             }
             />
            <Section 
             header={header.solution}
             description={description.solution}
             sectionClass="section--secondary section-block--services"
             wrapperClass="blocks--services"
             Blocks={
                 <>
                 <Block blockClass="block--services" subheader={subheader[1].block1} image={<Crm/>}/>
                 <Block blockClass="block--services" subheader={subheader[1].block2} image={<Erp/>}/>
                 <Block blockClass="block--services" subheader={subheader[1].block3} image={<Cms/>}/>
                 <Block blockClass="block--services" subheader={subheader[1].block4} image={<Inventory/>}/>
                 <Block blockClass="block--services" subheader={subheader[1].block5} image={<Ecommerce/>}/>
                 <Block blockClass="block--services" subheader={subheader[1].block6} image={<Productivity/>}/>
                 <Block blockClass="block--services" subheader={subheader[1].block7} image={<Hrm/>}/>
                 <Block blockClass="block--services" subheader={subheader[1].block8} image={<Cloud/>}/>
                 </>
             }
             />
        </>
    )
}

export { SaaS };