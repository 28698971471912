

const privacy = {
    header: 'OZ Merchant Solutions LLC`s Privacy Policy',
    subheader: [
        'Introduction', 'General Implementation Guidelines', 'Responsibilities',
        'Performance of a Contract', 'Legal Obligation', 'Vital Interests of Data Subject', 
        'Data Subject Rights', 'Right to be Informed', 'Right to Access', 'Right to Rectification', 
        'Right to Erasure', 'Right to Restrict Processing', 'Right to Data Portability', 
        'Right in Relation to Automated Decision Making', 'Data Sharing and Disclosure', 'Data Recording and Storage', 
        'Information Security', 'Records Management', ' Risk Management', 'OZ Merchant Solutions LLC', 
    ],
    block1: [
        'OZ Merchant Solutions LLC needs to gather and use certain information about individuals for various business purposes. These can include customers, suppliers, business contacts, employees and other people OZ Merchant Solutions LLC has a relationship with or may need to contact. This can include any information relating directly or indirectly to individuals, including, but not limited to names of individuals, postal addresses, email addresses, telephone numbers, any other information relating directly or indirectly to individuals.',

        'OZ Merchant Solutions LLC is committed to processing data in accordance with commonly applied data protection principles, including:',

        '- Lawfulness, fairness, and transparency',
        '- Purpose limitation',
        '- Data minimisation',
        '- Accuracy',
        '- Storage limitation',
        '- Integrity and confidentiality (security)',
        
    ],
    block2: [
        'OZ Merchant Solutions LLC implements appropriate technical and organisational measures, such as pseudonymisation, which are designed to implement data-protection principles, such as data minimisation, in an effective manner and to integrate the necessary safeguards into processing in order to meet the requirements of the GDPR and local law and protect the rights of data subjects.',
    ],

    block3: [
        `OZ Merchant Solutions LLC requires all employees and contractors to treat personal data with strict confidentiality in line with the GDPR and local data protection law.
        Lawful Basis for Processing Data`,

        `It is OZ Merchant Solutions LLC policy to identify the appropriate basis for processing of personal data. OZ Merchant Solutions LLC considers the following options as a possible legal basis:
        Consent`,

        `OZ Merchant Solutions LLC will obtain explicit consent from the data subject to collect and process their data when other bases for the processing are not applicable. OZ Merchant Solutions LLC ensures that the consent received from the data subject will be free, specific, informed, and unambiguous.`,

        `Transparent information about OZ Merchant Solutions LLC’s usage of their personal data is provided to the data subject at the time that consent is obtained and their rights with regard to their data explained, such as the right to withdraw consent.`,
    ],
    block4: [
        'Where the personal data collected and/or processed are required to fulfil a contract with the data subject, explicit consent is not required.',
    ],
    block5: [       
        `If the personal data is required to be collected and/or processed in order to comply with the local law, then explicit consent is not required.`,
    ],
    block6: [
        `In case the personal data are required to protect the vital interests of the data subject or of another natural person, then this may be used as the lawful basis of the processing. OZ Merchant Solutions LLC will retain reasonable, documented evidence that this is the case, whenever this reason is used as the lawful basis of the processing of personal data.
        Task Carried out in Public Interest`,

        'Where OZ Merchant Solutions LLC needs to perform a task that it believes is in the public interest or as part of official duty, then the data subject`s consent will not be requested. The assessment of the public interest or official duty will be documented and made available as evidence where required.Legitimate Interests',

        `If the processing of personal data is necessary for the purposes of the legitimate interests pursued by OZ Merchant Solutions LLC, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child, then this may be defined as the lawful reason for the processing.`
    ],
    block7: [
        'OZ Merchant Solutions LLC informs data subjects of their rights and how to exercise them by the provision of privacy policy online both externally and internally and in privacy notices on the website.',
    ],
    block8: [
        'OZ Merchant Solutions LLC is committed to processing personal data in a transparent manner. To this end, a privacy notice is available on the OZ Merchant Solutions LLC website and is reviewed annually.',
    ],
    block9: [
        'Data subjects have the right to receive confirmation that their data is being processed, a copy of, or access to, their personal data, and other supplementary information regarding processing. Right of access requests can be submitted by email, or post to the address indicated on the OZ Merchant Solutions LLC website.',
    ],
    block10: [
        'Individuals are entitled to have personal data rectified if it is inaccurate or incomplete. Where possible, OZ Merchant Solutions LLC aims to allow individuals to access and amend their own personal data.',

        `Right to rectification requests can be submitted via a special form on the OZ Merchant Solutions LLC website, by email, or post to the address provided on the OZ Merchant Solutions LLC website. OZ Merchant Solutions LLC keeps records of all rectification requests and their outcome.`,
    ],
    block11: [
        'The right to erasure is also known as “the right to be forgotten”, which enables an individual to request the deletion or removal of personal data where there is no compelling reason for its continued processing.',

        'Following a request under the right to erase, personal data must be erased where:',

        '- It is no longer necessary in relation to the purpose for which it was originally processed.',
        '- When the individual withdraws consent.',
        '- When the individual objects to the processing and there is no overriding legitimate interest for continuing the processing.',
        '- Where the personal data was unlawfully processed (e.g., otherwise in breach of the GDPR or national laws).',
        '- It is necessary in order to comply with a legal obligation.',

        'Erasure requests can be submitted via a special form on the OZ Merchant Solutions LLC website, by email, or post to the address provided on the OZ Merchant Solutions LLC website. Records of all erasure requests and their outcome are kept.',
    ],
    block12: [
        'When processing is restricted, OZ Merchant Solutions LLC might be permitted to store personal data, but not further process it. The restriction for the processing of personal data is applied in the following circumstances:',

        '- In case of an individual complaint about the accuracy of the collection of personal data.',
        '- When an individual has objected to the processing.',
        '- When processing is unlawful and the individual opposes erasure and requests restriction instead',
        '- When personal data are no longer needed, but the individual requires the data to establish, exercise, or defend a legal claim.',

        'OZ Merchant Solutions LLC aims to comply with the right to restrict processing through including restriction in records management, right to object, and rectification processes.',

        'Requests to restrict processing can be submitted via a special form on the OZ Merchant Solutions LLC website, by email, or post to the address provided on the OZ Merchant Solutions LLC website. A record of all restriction of processing requests, and their outcome, is maintained by OZ Merchant Solutions LLC.',
    ],
    block13: [
        'The right to data portability allows individuals to obtain and reuse their personal data across different services. It allows them to move, copy, or transfer personal data easily from one IT environment to another in a safe and secure way, without hindrance to usability.',

        'The right to data portability only applies to personal data an individual has provided to OZ Merchant Solutions LLC, where the processing is based on the individual’s consent or for the performance of a contract, and when processing is carried out by automated means.',

        'Requests for data portability can be submitted via a special form on the OZ Merchant Solutions LLC website, by email, or post to the address provided on the OZ Merchant Solutions LLC website. A record of all requests for data portability and their outcome is kept by OZ Merchant Solutions LLC. Right to Object',

        'Individuals have the right to object to processing based on:',

        '- legitimate interests, or the performance of a task in the public interest/exercise of official authority (including profiling)',
        '- direct marketing (including profiling)',
        '- processing for the purposes of scientific/historical research and statistics',

        'Requests for data portability can be submitted via a special form on the OZ Merchant Solutions LLC website, by email, or post to the address provided on the OZ Merchant Solutions LLC website. A record of all objections to processing, and their outcome, is kept by OZ Merchant Solutions LLC.',
    ],
    block14: [
        'Individuals have the right not to be subject to a decision when it is based on automated processing and it produces a legal effect or a similarly significant effect on the individual.',

        'OZ Merchant Solutions LLC does not undertake any decision-making based on personal data by solely automated processing. All automated processing is subject to human intervention and oversight. However, OZ Merchant Solutions LLC maintains a list of all semi-automated processing.',

        'OZ Merchant Solutions LLC does not undertake any automated decision making using the data of persons under the age of 16.',

        'Where semi-automated decision making must be undertaken using special categories of personal data, this is with the explicit consent of the data subject.',
    ],
    block15: [
        'OZ Merchant Solutions LLC has data sharing agreements in place with any partner organization with which regular data sharing takes place and takes all necessary precautions to ensure the security, integrity, and proper treatment of personal data.',

        'Special category personal data will never be used for the purposes of legitimate business interests.',

        'OZ Merchant Solutions LLC discloses personal data to public authorities in accordance with its legal obligation when their requests are in writing and reasoned. Records of all data sharing and disclosures, data sharing requests, conditions for sharing or disclosure, and outcomes of such activities, are maintained by OZ Merchant Solutions LLC.',

    ],
    block16: [
        'OZ Merchant Solutions LLC departments processing personal data arrange data storage in the filing system. Those departments are responsible for a regular cycle of checking, updating or discarding old data and may set out retention periods for different types of data.',
    ],
    block17: [
        'OZ Merchant Solutions LLC ensures through the set of internal by-laws that appropriate technical and organisational measures are in place, supported by privacy impact and risk assessments, to ensure a high level of security for personal and confidential data and a secure environment for information held both manually and electronically.',

        'In the event of a security breach of personal data, OZ Merchant Solutions LLC acts in accordance with the plan for responding to violations of the protection of personal data.',
    ],
    block18: [
        'OZ Merchant Solutions LLC is committed to implementing a robust records management policy, process and practices. OZ Merchant Solutions LLC maintains a record of processing activities under its responsibility and activities carried out on its behalf.',
    ],
    block19: [
        'OZ Merchant Solutions LLC approaches risk management through risk evaluation and incident management processes, as well as by the use of privacy impact assessments',
    ],
    block20: [
        'Mailing address:',

        '105 N 1ST ST.,#429',
        'San Jose, CA 95103 USA',

        'hello@oz-it.dev',
    ],

}

export default privacy;