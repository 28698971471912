function FooterItem(props) {
    const {itemClass, item1,item2, item3, item4, item5, item6} = props;
    return(
        <div className={itemClass}>
            <div className="footer--header">{itemClass}</div>
                {item1}
                {item2}
                {item3}
                {item4}
                {item5}
                {item6}
        </div>
    )
}

export { FooterItem };