import {useState, useEffect, useRef} from "react";
import { ArrowDown } from "../FlyingLogoElem/ArrowDown";
import { IconDrag } from "../FlyingLogoElem/IconDrag"
import { LogoShadows } from "../FlyingLogoElem/LogoShadows";
import {ArrowRed} from "../assets/svg/ArrowRed";
import { Wrapper } from "../FlyingLogoElem/Wrapper";
import { ArrowWhite } from "../assets/svg/ArrowWhite";
import redImg from "../assets/images/logo-large.png";
import white from "../assets/images/logo-large-white.png";
import { colors } from "../assets/colors";




function FlyingLogo({openModal=Function.prototype}){

    const [active, setActive] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [wrappWidth, setWrappWidth] = useState(50);
    const [left, setLeft] = useState(50);
    const [an, setAn] = useState('wr--an');
    const [dr, setDr] = useState('dr--an');
 


    const section = useRef(null);

    
    function mouseTouchMove(event) {
   
        let x2 = event.clientX;
        let position = (x2 * 100) / width;
        if (active) {
        if (position > 1.2 & position <= 100) {
            setWrappWidth(position);
            setLeft(position);
            setAn('');
            setDr('');
        }
    }
    }

    function handleTouchMove(event) {

        let x2 = event.touches[0].clientX;
        let position = (x2 * 100) / width;
        if (position > 1.2 & position <= 100) {
            setWrappWidth(position);
            setLeft(position);
            setAn('');
            setDr('');
        }

    }


    useEffect(() => {
       
        let container = document.querySelector('.container--change').clientWidth;

        window.onresize = () =>{
        setWidth(container);    
    }
    },[])

    return(
        <section className="section section--change" ref={section} onMouseMove={mouseTouchMove} onMouseUp={() => setActive(false)} onMouseDown={() => setActive(true)}>

            <div className="container container--change">
                <IconDrag touchMove={handleTouchMove} left={left} dr={dr}/>
                <LogoShadows/>
                <Wrapper partClass="white" img={redImg} color="red" fill={colors.red}  classTextBtn="button--text text--red" openModal={openModal}/>
                <Wrapper partClass="red"
                 an={an} 
                 img={white} 
                 color="white" 
                 wrappWidth={wrappWidth}
                 fill={colors.white} 
                 classTextBtn="button--text" 
                 arrow={<ArrowDown arrClass="white" image={<ArrowWhite/>} scroll="quality"/> }
                 openModal={openModal}/>
                <ArrowDown arrClass="red"  image={<ArrowRed/>} scroll="quality"/>
                
            </div>
        </section>
    )
}

export { FlyingLogo };