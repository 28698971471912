

function AboutUs(props) {
    const {
        path,
        alt,
        description,
        header
    } = props;
    return(
        <section className="section--about-us section ">
            <h1 dangerouslySetInnerHTML={{__html: header}}></h1>
            {description && <p>{description}</p>}
            {path && <img src={path} alt={alt} />}
        </section>

    )
}
export { AboutUs };