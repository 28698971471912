import { Facebook } from '../assets/svg/Facebook';
import { GitHub } from '../assets/svg/GitHub';
import { Instagram } from '../assets/svg/Instagram';
import { Twitter } from '../assets/svg/Twitter';

function FooterSocial(props) {
    const {name} = props;
    return(
        <div className="socials--container">
            <div className="footer--header ">{name}</div>
            <div className="socials">
                <Facebook/>
                <Twitter/>
                <GitHub/>
                <Instagram/>
            </div>       
        </div>
    )
}

export { FooterSocial };