function Block({blockClass, image, subheader, blockDescr, blockItem, style}){
    return(
        <div className={blockClass}>
            {image}
            <h2 style={style}>{subheader}</h2>
            {blockDescr && <p>{blockDescr}</p>}
            
            {blockItem}
            
        </div>
    )
}
export { Block}