import { des } from "../assets/description";

function ModalHeader() {
    const header =  des.modal.header;
    return(
        <div className="modal--header ">
        <h2>
            {header}
        </h2>
    </div>
    )
}
export { ModalHeader };