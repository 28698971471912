function Section(props) {


    const {
        header,
        description,
        sectionClass,
        wrapperClass,
        Blocks

    } = props;

    return(
        <section className={sectionClass}>
            <div className="container">
                <h1 dangerouslySetInnerHTML={{__html : header}}></h1>
                <p>{description}</p>
                <div className={wrapperClass}>
                    {Blocks}
                </div>
            </div>
        </section>
    )
}
export { Section };