
import { useState, useRef } from "react";
import { des } from "../../assets/description";
import { Button } from "../../elements/Button";
import {ArrowRed} from "../../assets/svg/ArrowRed";
import server from "../../server.php"
import { ModalClose } from "../../elements/ModalClose";


function Form({close, closeModal=Function.prototype}) {
    const header = des.form.header;
    const errorStyle = {
        color: "red", 
        fontWeight: "700", 
        fontSize: "12px",
        position: "absolute",
        width: "300px",
    
    }
    const form = useRef(null);

    const [subject, setSubject] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [select, setSelect] = useState('');
    const [message, setMessage] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const send = {
        loading: 'Loading...',
        success: 'Thank You! <br> We will contact you soon',
        failure: 'Something went wrong...'
    };


    const postData = async(url, data) => {

        let res = await fetch(url, {
            method: "POST",
            body: data
        });

        return await res.text();
    };


    const handleSubmitForm = (e) => {
        e.preventDefault();


        let statusMessage = document.createElement('div');
            
        statusMessage.classList.add('status');
        form.current.appendChild(statusMessage);

        const formData = new FormData(form.current);
        postData(server, formData)
            .then(res => {

                statusMessage.innerHTML = send.success;

            })
            .catch(() => {

                statusMessage.innerHTML = send.failure

            })
            .finally(() => {
                setSubject('');
                setName('');
                setEmail('');
                setPhone('');
                setSelect('');
                setMessage('');
                setTimeout(() => {
                    statusMessage.remove();
                }, 2000);

            });

    };
    const validateName = () => {
        if(name.length < 2 && name.length !== 0) {
            setNameError(true);
        } else {
            setNameError(false);
        }
    }
    const validateSubject = () => {
        if(subject.length < 3 && subject.length !== 0) {
            setSubjectError(true);
        } else {
            setSubjectError(false);
        }
    }
    const validateEmail = () => {
        if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email.toLocaleLowerCase()) && email.length !== 0 ) {
         
            setEmailError(true);
           
        } else {
            setEmailError(false);
        }
    }
    const validatePhone = () => {
        if(!/^[0-9]*$/.test(phone) && phone.length !== 0 ) {
         
            setPhoneError(true);
           
        } else {
            setPhoneError(false);
        }
    }
    return(
        <form className="form form-page" ref={form} onSubmit={handleSubmitForm}>
            <div className="form--header" stye={{position: 'relative'}} >
                {close && <ModalClose closeModal={closeModal}/>}
                {header} 
                
                </div>

            <div className="form--item">
                 <label htmlFor="subject">{subject.length === 0 ? 'Subject *' : ''}{subjectError &&  <div style={errorStyle}>Subject must contain at least three characters</div>}</label>
                 <input className="input" id="subject" 
                 name="subject" 
                 type="text" 
                 label="Subject *" 
                 value={subject} 
                 maxLength="50" 
                 required  
                 onChange={e => {setSubject(e.target.value); setSubjectError(false)} } 
                 onBlur={validateSubject} />
            </div>

            <div className="form--item">
                 <label htmlFor="name">{name.length === 0 && !nameError ? 'Your Name *' : ''}{nameError && <div style={errorStyle}>Name must contain at least two characters</div>}</label>
                 <input className="input" id="name" 
                 name="name" 
                 type="text" 
                 label="Your Name *"
                  value={name} 
                  maxLength="50" 
                  required 
                  onChange={e =>{ setName(e.target.value); setNameError(false)}}
                  onBlur={validateName} />
            </div>

            <div className="form--item form--item_grid">
                 <div className="form--item--unit">
                        <label htmlFor="email">{email.length  ===0 && !emailError ?'E-mail *' : ''} {emailError && <div style={errorStyle}>Invalid E-mail</div>}</label>
                        <input className="input" id="email" 
                        name="email" 
                        type="text" 
                        label="E-mail *" 
                        value={email} 
                        required 
                        onChange={e => {setEmail(e.target.value); setEmailError(false)}} 
                        onBlur={validateEmail}/>
                 </div>
                 <div className="form--item--unit">
                         <label htmlFor="phone">{phone.length === 0 && !phoneError  ? 'Phone Number' : ''}{phoneError && <div style={errorStyle}>Phone number must contain only numbers</div>}</label>
                         <input className="input" id="phone" 
                         name="phone" label="Phone Number" 
                         type="tel" 
                         value={phone} 
                         required 
                         onChange={e =>{ setPhone(e.target.value); setPhoneError(false)}}
                         onBlur={validatePhone}/> 
                 </div>
            </div>

            <div className="form--item select--item">
                        <span className="material-icons select--icon"><ArrowRed/></span>
                        <label htmlFor="name">{select.length === 0 ? 'Your Budget ?' : ''}</label>
                        <select className="input" id="budget"
                         name="budget"
                         type="text" 
                         label='Your Budget ?'
                         value={select}
                        onChange={e => setSelect(e.target.value)} >
                            <option value="" disabled  />
                            <option value="€5000 - €10 000" >€5000 - €10 000</option>
                            <option value="€10 000 - €20 000" >€10 000 - €20 000</option>
                            <option value="€20 000 - €50 000" >€20 000 - €50 000</option>
                            <option value="Over €50 000" >Over €50 000</option>
                            <option value="Can't disclose" >Can't disclose</option>
                            <option value="Not sure" >Not sure</option>
                        </select>
            </div>

            <div className="form--item">
                 <textarea name="message" id="message-description" 
                 placeholder="Project description"
                 value={message} 
                 onChange={e => setMessage(e.target.value)}/>
             </div>

            <Button classBtn="button  button--submit"  type="submit" name={des.form.btnName}/> 

        </form>
    )
}
export { Form };