import { des } from "../assets/description";
import "../pages-css/company.css";
import { CareerBlock } from "./CareerBlock";
import { Analyst } from "../assets/svg/Analyst";
import { Channel } from "../assets/svg/Channel";
import { Science } from "../assets/svg/Science";
import { WebDev } from "../assets/svg/WebDev";

function Career() {
    const header = des.pages.header.career;
    const subheader = des.pages.subheader.career;
    const description = des.pages.description.career;
    const BlockSubHeader = des.pages.subheader.blockSubHeader;
    const BlockDescription = des.pages.description.blockDescription;
    return(
        <section className="section section--company">
            <div className="container">
                <h1 dangerouslySetInnerHTML={{__html: header}}></h1>
                <p>{description}</p>
                <h2>{subheader}</h2>
                <div className="company--wrapper">
                    <CareerBlock image={<Channel />} alt="" BlockSubHeader={BlockSubHeader.block1 } BlockDescription={BlockDescription.block1}/>
                    <CareerBlock image={<Science />} alt="" BlockSubHeader={BlockSubHeader.block2 } BlockDescription={BlockDescription.block2}/>
                    <CareerBlock image={<Analyst />} alt="" BlockSubHeader={BlockSubHeader.block3 } BlockDescription={BlockDescription.block3}/>
                    <CareerBlock image={<WebDev />} alt="" BlockSubHeader={BlockSubHeader.block4 } BlockDescription={BlockDescription.block4}/>
                </div>
            </div>
        </section>
    )
}

export { Career };