import { SideBarListItem } from "./SideBarListItem";

function SideBarCompany({closePanel=Function.prototype}) {
    return(
        <div className="sublist">
            <SideBarListItem path="/about-us" name="About Us" closePanel={closePanel}/>
            <SideBarListItem path="/approach" name="Our Approach" closePanel={closePanel}/>
            <SideBarListItem path="/career" name="Careers" closePanel={closePanel}/>
        </div>
    )
}

export { SideBarCompany };