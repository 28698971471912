import { des } from "../assets/description";
import { ServicesItem } from "../elements/ServicesItem";
import { Speedometer } from "../assets/svg/Speedometer";
import { Lego } from "../assets/svg//Lego";
import { Website } from "../assets/svg/Website";
import { Mob } from "../assets/svg/Mob";

function Services() {
    const header = des.services.header;
    const subheader = des.services.subHeader;
    return(
        <section className="section section--services">
        <div className="container">
            <h1>{header}</h1>
            <h2>{subheader}</h2>
            <div className="services--items">
                <ServicesItem image={<Speedometer/>} name="Fast-loading" />
                <ServicesItem image={<Lego/>} name="Intuitive UX" />
                <ServicesItem image={<Website/>} name="Beautiful UI" />
                <ServicesItem image={<Mob/>} name="Mobile-friendly" />
            </div>
        </div>
    </section>
    )
}
export { Services }