import { des } from "../assets/description"
import { Project } from "./TechElem/Project";
function Tech() {
    const headerSection = des.tech.headerSection;
    return(
        <section className="section section--tech">
            <div className="container">
                <h1>{headerSection}</h1>        
                <Project/>
            </div>
        </section>
    )
}

export { Tech };