function CareerBlock(props) {
    const {
        image,
        BlockSubHeader,
        BlockDescription,
        margin

    } = props;
    return(
        <div className="company--block">
        {image}
            <div className="--block" style={margin}>
                <h3>{BlockSubHeader}</h3>
                <span>{BlockDescription}</span>
            </div>
    </div>
    )
}
export { CareerBlock };