import { Item } from "./Item";
import { des } from "../../assets/description";
import { Ethereum } from "../../assets//svg/Ethereum";
import { HyperledgerFabric } from "../../assets//svg/HyperledgerFabric";
import { EOS } from "../../assets//svg/EOS";
import { HyperledgerIndy } from "../../assets//svg/HyperledgerIndy";
import { HyperledgerSawtooth} from "../../assets//svg/HyperledgerSawtooth";
import { Parity } from "../../assets//svg/Parity";


function SectionTechnologies() {
    const itemClass = 'technology';
    const header = des.pages.header.blSectTech;
    return(
        <section className="section section--technologies">
                <div className="container">
                    <h1>{header}</h1>
                    <div className="technologies--wrapper">
                        <Item des="Ethereum" image={<Ethereum/>} itemClass={itemClass}/>
                        <Item des="Hyperledger Fabric" image={<HyperledgerFabric/>} itemClass={itemClass}/>
                        <Item des="EOS" image={<EOS/>} itemClass={itemClass}/>
                        <Item des="Hyperledger Indy" image={<HyperledgerIndy/>} itemClass={itemClass}/>
                        <Item des="Hyperledger Sawtooth" image={<HyperledgerSawtooth/>} itemClass={itemClass}/>
                        <Item des="Parity" image={<Parity/>} itemClass={itemClass}/>
                    </div>
                </div>
        </section>
    )
}
export { SectionTechnologies }