import { colors } from "../colors";
function Shop() {
    return(

        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" fill={colors.red}>
            <g>
                <g>
                    <path d="M480.451,40.081H31.549C14.152,40.081,0,54.233,0,71.629v304.613c0,17.396,14.152,31.548,31.549,31.548h151.826
                        l-6.141,49.129H151.79c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h208.42c4.143,0,7.5-3.358,7.5-7.5
                        c0-4.142-3.357-7.5-7.5-7.5h-25.443l-6.142-49.129h151.826c17.396,0,31.549-14.152,31.549-31.548V71.629
                        C512,54.233,497.848,40.081,480.451,40.081z M192.351,456.919l6.142-49.129h27.603l2.708,10.83
                        c3.126,12.503,14.31,21.234,27.197,21.234c12.887,0,24.071-8.731,27.197-21.234l2.708-10.83h27.603l6.14,49.129H192.351z
                        M241.557,407.79h28.885l-1.798,7.192c-1.453,5.813-6.652,9.872-12.645,9.872s-11.191-4.06-12.645-9.872L241.557,407.79z
                        M497,376.242L497,376.242c0,9.125-7.424,16.548-16.549,16.548H31.549c-9.125,0-16.549-7.423-16.549-16.548V71.629
                        c0-9.125,7.424-16.548,16.549-16.548h448.902c9.125,0,16.549,7.423,16.549,16.548V376.242z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M424.338,72.146H39.564c-4.143,0-7.5,3.357-7.5,7.5v288.58c0,4.143,3.357,7.5,7.5,7.5h16.033c4.143,0,7.5-3.357,7.5-7.5
                        c0.001-4.142-3.357-7.5-7.499-7.5h-8.533V87.146h377.273c4.143,0,7.5-3.358,7.5-7.5C431.838,75.503,428.481,72.146,424.338,72.146
                        z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M472.436,72.146h-16.033c-4.143,0-7.5,3.357-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h8.533v273.58H87.662
                        c-4.143,0-7.5,3.358-7.5,7.5c0,4.143,3.357,7.5,7.5,7.5h384.773c4.143,0,7.5-3.357,7.5-7.5V79.646
                        C479.936,75.503,476.578,72.146,472.436,72.146z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M358.1,155.184c-1.348-1.723-3.381-2.771-5.565-2.87l-176.354-8.017c-0.138-0.006-0.273-0.009-0.412-0.007
                        c-1.044-3.427-3.25-6.399-6.236-8.391l-21.597-14.398c-3.446-2.297-8.103-1.366-10.4,2.081c-2.297,3.447-1.366,8.103,2.08,10.4
                        l21.596,14.396c0.102,0.068,0.177,0.171,0.211,0.289l27.509,96.281c0.858,3.002,2.27,5.7,4.083,8.03l-10.065,20.13
                        c-2.423,4.845-2.169,10.488,0.679,15.096c1.033,1.671,2.343,3.094,3.848,4.234c-1.97,3.439-3.103,7.417-3.103,11.657
                        c0,12.976,10.557,23.532,23.532,23.532c12.975,0,23.532-10.557,23.532-23.532c0-3.009-0.574-5.885-1.608-8.532h68.377
                        c-1.034,2.647-1.608,5.523-1.608,8.532c0,12.976,10.557,23.532,23.532,23.532c12.975,0,23.532-10.557,23.532-23.532
                        c0-3.764-0.893-7.321-2.471-10.479c1.514-1.372,2.471-3.348,2.471-5.553c0-4.142-3.357-7.5-7.5-7.5H196.825
                        c-0.107,0-0.288,0-0.439-0.245c-0.151-0.244-0.07-0.405-0.022-0.502l9.272-18.543c1.887,0.485,3.853,0.747,5.865,0.747
                        c0.927,0,1.863-0.055,2.805-0.165l105.386-12.398c13.611-1.602,24.562-11.706,27.25-25.146l12.606-63.034
                        C359.977,159.132,359.448,156.907,358.1,155.184z M320.129,295.565c4.705,0,8.532,3.828,8.532,8.532
                        c0,4.705-3.827,8.532-8.532,8.532s-8.532-3.827-8.532-8.532S315.424,295.565,320.129,295.565z M207.904,295.565
                        c4.705,0,8.532,3.827,8.532,8.532c0,4.705-3.827,8.532-8.532,8.532c-4.705,0-8.532-3.827-8.532-8.532
                        S203.199,295.565,207.904,295.565z M189.512,192.387l-9.399-32.895l29.985,1.363l5.199,31.532H189.512z M212.554,246.958
                        c-4.182,0.492-8.047-2.087-9.201-6.13l-9.555-33.441h23.972l6.301,38.216L212.554,246.958z M256.517,241.786l-17.532,2.063
                        l-6.011-36.461h23.543V241.786z M256.517,192.387H230.5l-5.084-30.835l31.101,1.414V192.387z M294.499,237.317l-22.982,2.704
                        v-32.634h25.691L294.499,237.317z M298.566,192.387h-27.049v-28.74l29.529,1.342L298.566,192.387z M332.232,221.371
                        c-1.41,7.048-7.154,12.349-14.293,13.189l-8.217,0.967l2.547-28.139h22.759L332.232,221.371z M338.029,192.387h-24.401
                        l2.418-26.716l27.08,1.231L338.029,192.387z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>

    )
}
export { Shop }