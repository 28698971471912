import { Link } from "react-router-dom";

function SideBarListItem({path, name, closePanel=Function.prototype}) {
    return(
        <div className="sublist--item">
            <Link to={path} onClick={closePanel}>{name}</Link>
        </div>
    )
}

export { SideBarListItem };