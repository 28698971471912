

function IconDrag({touchMove=Function.prototype, left,dr}) {



    return(
        <div className={`drag  ${dr}`}  onTouchMove={touchMove} style={{left: left + '%'}}>
            <span className="icon-drag"></span>
        </div>
    )
}
export {IconDrag};