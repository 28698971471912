import { Link } from 'react-router-dom';
import {Logo} from '../assets/svg/Logo';

function HeaderLogo() {
    return(
        <div className="logo">
            <Link to="/">
                <Logo/>
            </Link>
        </div>
    )
}
export { HeaderLogo };