import { colors } from "../colors";

function SaasIntegr() {
    return(

            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"x="0px" y="0px" viewBox="0 0 480 480" fill={colors.red}>
                <g>
                    <g>
                        <path d="M472,0H8C3.582,0,0,3.582,0,8v464c0,4.418,3.582,8,8,8h464c4.418,0,8-3.582,8-8V8C480,3.582,476.418,0,472,0z M16,16h216
                            v58.704c-25.016-8.772-52.407,4.397-61.179,29.413s4.397,52.407,29.413,61.179c10.282,3.605,21.484,3.605,31.766,0V232h-80
                            c-4.418,0-8,3.582-8,8c0,1.731,0.561,3.415,1.6,4.8c4.194,5.518,6.444,12.269,6.4,19.2c0,17.673-14.327,32-32,32s-32-14.327-32-32
                            c-0.044-6.931,2.206-13.682,6.4-19.2c2.651-3.535,1.935-8.549-1.6-11.2c-1.385-1.039-3.069-1.6-4.8-1.6H16V16z M236.448,384.8
                            c-2.735,1.355-4.46,4.148-4.448,7.2v72H16V248h58.704c-8.772,25.016,4.397,52.407,29.413,61.179s52.407-4.397,61.179-29.413
                            c3.605-10.282,3.605-21.484,0-31.766H232v80c0,4.418,3.582,8,8,8c1.731,0,3.415-0.561,4.8-1.6c5.518-4.194,12.269-6.444,19.2-6.4
                            c17.673,0,32,14.327,32,32c0,17.673-14.327,32-32,32c-6.931,0.044-13.682-2.206-19.2-6.4
                            C242.39,383.778,239.16,383.468,236.448,384.8z M464,464H248v-58.704c25.016,8.772,52.407-4.397,61.179-29.413
                            s-4.397-52.407-29.413-61.179c-10.282-3.605-21.484-3.605-31.766,0V248h88c4.418,0,8-3.582,8-8c0-2.518-1.186-4.889-3.2-6.4
                            c-14.138-10.604-17.004-30.661-6.4-44.8c10.604-14.138,30.661-17.004,44.8-6.4c14.138,10.604,17.004,30.661,6.4,44.8
                            c-1.819,2.426-3.974,4.581-6.4,6.4c-3.535,2.651-4.251,7.665-1.6,11.2c1.511,2.014,3.882,3.2,6.4,3.2h80V464z M464,232h-62.472
                            c13.294-22.935,5.479-52.305-17.457-65.599s-52.305-5.479-65.599,17.457c-8.629,14.887-8.629,33.255,0,48.143H248v-80
                            c0-4.418-3.582-8-8-8c-1.731,0-3.415,0.561-4.8,1.6c-5.518,4.194-12.269,6.444-19.2,6.4c-17.673,0-32-14.327-32-32
                            s14.327-32,32-32c6.931-0.044,13.682,2.206,19.2,6.4c2.41,1.822,5.64,2.132,8.352,0.8c2.735-1.355,4.46-4.148,4.448-7.2V16h216
                            V232z"/>
                    </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>

    )
}
export { SaasIntegr }