import { GraphicRed } from "../assets/svg/GraphicRed";


function RightText(props) {
    const {partClass, text, fill} = props;
    return(
        <div className={`${partClass}--text-right`}>
            <GraphicRed fill={fill}/>
            <h2 className="change--header">{text}</h2>
        </div>
    )
}

export { RightText };