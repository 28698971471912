import { colors } from "../colors";

function Commerce() {
    return(

        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 488.942 488.942" fill={colors.red}>
                <g>
                    <path d="M485.592,105.36c-2.795-3.354-7.075-5.278-11.741-5.278H132.063c-3.408,0-6.603,1.036-9.171,2.896l-5.368-26.176
                        c-0.405-1.978-2.145-3.396-4.163-3.396H82.506v-3.623c0-6.665-5.422-12.087-12.087-12.087H12.087C5.422,57.695,0,63.117,0,69.782
                        v18.514c0,6.665,5.422,12.087,12.087,12.087h58.332c6.665,0,12.087-5.422,12.087-12.087v-6.391h27.388l7.308,35.63
                        c0.018,0.109,0.022,0.219,0.043,0.329l38.238,208.439c1.669,9.112,10.234,16.251,19.5,16.251l255.946-0.001
                        c9.265,0,17.831-7.138,19.503-16.249l38.237-208.44C489.51,113.272,488.389,108.715,485.592,105.36z M74.006,88.295
                        c0,1.978-1.609,3.587-3.587,3.587H12.087c-1.978,0-3.587-1.609-3.587-3.587v-8.207h34.349c2.348,0,4.25-1.902,4.25-4.25
                        c0-2.348-1.902-4.25-4.25-4.25H8.5v-1.807c0-1.978,1.609-3.587,3.587-3.587h58.332c1.978,0,3.587,1.609,3.587,3.587V88.295z
                        M210.942,334.053l-6.246-34.027h42.363l6.233,34.027H210.942z M203.136,291.526l-17.202-93.712h42.405l17.164,93.712H203.136z
                        M140.554,197.814h36.738l17.203,93.712h-36.75L140.554,197.814z M134.304,163.743h36.734l4.693,25.571h-36.737L134.304,163.743z
                        M184.373,189.314l-4.694-25.571h42.42l4.684,25.571H184.373z M178.118,155.243l-8.564-46.661h42.441l8.547,46.661H178.118z
                        M126.849,110.802c1.193-1.432,3.045-2.221,5.214-2.221h28.85l8.565,46.661h-36.733l-7.139-38.912
                        C125.214,114.198,125.655,112.235,126.849,110.802z M163.844,324.77l-4.539-24.744h36.751l6.246,34.027h-27.319
                        C169.866,334.053,164.766,329.802,163.844,324.77z M442.07,324.77c-0.923,5.031-6.025,9.282-11.142,9.282l-168.995,0.001
                        l-6.232-34.027l125.53,0.001c2.347,0,4.25-1.903,4.25-4.25c0-2.348-1.902-4.25-4.25-4.25l-127.087-0.001l-17.164-93.712h29.603
                        c2.348,0,4.25-1.902,4.25-4.25c0-2.348-1.902-4.25-4.25-4.25h-31.159l-4.684-25.571h146.167c2.348,0,4.25-1.902,4.25-4.25
                        c0-2.348-1.902-4.25-4.25-4.25H229.184l-8.546-46.661h253.213c2.168,0,4.019,0.788,5.212,2.221
                        c1.194,1.432,1.636,3.396,1.245,5.528l-1.426,7.772H374.97c-2.348,0-4.25,1.902-4.25,4.25c0,2.348,1.902,4.25,4.25,4.25h102.353
                        l-16.653,90.782H385.56c-2.348,0-4.25,1.902-4.25,4.25c0,2.348,1.902,4.25,4.25,4.25h73.55l-4.69,25.57H300.872
                        c-2.348,0-4.25,1.902-4.25,4.25c0,2.348,1.902,4.25,4.25,4.25h151.987L442.07,324.77z"/>
                    <path d="M219.183,369.758c-10.98,0-19.914,8.934-19.914,19.915c0,10.98,8.934,19.914,19.914,19.914s19.914-8.934,19.914-19.914
                        C239.097,378.692,230.163,369.758,219.183,369.758z M219.183,401.087c-6.294,0-11.414-5.12-11.414-11.414
                        c0-6.294,5.12-11.415,11.414-11.415c6.294,0,11.414,5.121,11.414,11.415C230.597,395.967,225.477,401.087,219.183,401.087z"/>
                    <path d="M219.183,348.098c-22.924,0-41.574,18.65-41.574,41.575c0,22.924,18.65,41.573,41.574,41.573s41.573-18.649,41.573-41.573
                        C260.756,366.748,242.106,348.098,219.183,348.098z M219.183,422.746c-18.237,0-33.074-14.837-33.074-33.073
                        c0-18.237,14.837-33.075,33.074-33.075c18.236,0,33.073,14.838,33.073,33.075C252.256,407.91,237.419,422.746,219.183,422.746z"/>
                    <path d="M386.731,348.098c-22.925,0-41.575,18.65-41.575,41.575c0,22.924,18.65,41.574,41.575,41.574
                        c22.924,0,41.574-18.65,41.574-41.574C428.306,366.748,409.655,348.098,386.731,348.098z M386.731,422.747
                        c-18.237,0-33.075-14.837-33.075-33.074c0-18.237,14.838-33.075,33.075-33.075c18.237,0,33.074,14.838,33.074,33.075
                        C419.806,407.911,404.969,422.747,386.731,422.747z"/>
                    <path d="M386.731,369.758c-10.981,0-19.914,8.934-19.914,19.915c0,10.98,8.933,19.913,19.914,19.913
                        c10.981,0,19.915-8.933,19.915-19.913C406.647,378.692,397.713,369.758,386.731,369.758z M386.731,401.086
                        c-6.294,0-11.414-5.12-11.414-11.413c0-6.294,5.12-11.415,11.414-11.415c6.294,0,11.415,5.121,11.415,11.415
                        C398.147,395.966,393.025,401.086,386.731,401.086z"/>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
        </svg>

    )
}

export { Commerce }