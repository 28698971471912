import { colors } from "../colors";

function HyperledgerIndy() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="-2.57 -1.95 133.64 126.77">
                <defs>
                <clipPath id="a"><path fill="none" d="M.06 122.312h415.267V0H.06z"/></clipPath>
                <clipPath id="b"><path fill="none" d="M.06 122.312h415.267V0H.06z"/></clipPath>
                </defs>
                <g fill={colors.red}><path d="M79.138 114.304L64.18 123l-14.958-8.696V96.91l14.958-8.696 14.958 8.696v17.394m-35.374-97.96l-8.976 5.218-8.976-5.218V5.907L34.788.688l8.976 5.219v10.437" clipPath="url(#a)"/>
                <path d="M120.041 64.287l-5.877 3.417-5.877-3.417v-6.833l5.877-3.417 5.877 3.417v6.833m-23.61 48.937l-7.592 4.415-7.592-4.415v-8.828l7.592-4.414 7.592 4.414v8.828M79.509 76.1L45.713 95.75 11.917 76.1V36.803l33.796-19.649 33.796 19.649V76.1m45.852 21.484l-17.738 10.312-17.738-10.312V76.958l17.738-10.313 17.738 10.313v20.626"/>
                <path d="M124.216 54.457l-3.259 1.894-3.259-1.894v-3.79l3.259-1.894 3.259 1.894v3.79M23.653 99.053l-11.826 6.876L0 99.053V85.301l11.827-6.876 11.826 6.876v13.752" clipPath="url(#b)"/>
                <path d="M24.322 15.821l-4.402 2.56-4.403-2.56v-5.119l4.403-2.56 4.402 2.56v5.119m104.255 36.931l-1.594.927-1.593-.927v-1.853l1.593-.926 1.594.926v1.853M17.208 19.829l-1.594.927-1.594-.927v-1.853l1.594-.926 1.594.926v1.853m66.188 12.997l-3.889 2.261-3.889-2.261v-4.522l3.889-2.261 3.889 2.261v4.522m15.815 2.626l-7.144 4.153-7.144-4.153v-8.307l7.144-4.153 7.144 4.153v8.307m-51.609 78.596l-2.192 1.275-2.193-1.275v-2.549l2.193-1.275 2.192 1.275v2.549"/>
                </g>
        </svg>
    )
}
export {  HyperledgerIndy }