import { useState } from "react";
import { HeaderLogo } from "../elements/HeaderLogo";
import { NavSidebar } from "../elements/NavSidebar";
import { NavHorizontal } from "../components/NavHorizontal"
import { SideBarPanel } from "./SideBarPanel";
import { Login } from "../elements/Login";

function HeaderDesctop({openModal=Function.prototype, openLogin=Function.prototype}) {

    const [panel, setPanel] = useState(false);
    const [icon, setIcon] = useState("menu");
    const [animation, setAnimation] = useState("")

    const togglePanel = () => {
        setPanel(panel ? false : true);
        setIcon(icon === 'menu' ? 'close' : 'menu')
        if(panel) {
            setAnimation("");
            document.documentElement.style.overflowY = '';
        } else {
            setAnimation("sidebar--open");
            document.documentElement.style.overflowY = 'hidden';
        }
    }
    const closePanel = () => {
        setPanel(false);
        setIcon('menu');
        setAnimation("");
        document.documentElement.style.overflowY = '';
    }

    return(
        <header>
            <div className="header-wrapper">
                <HeaderLogo iClass="material-icons burger" />
                <NavSidebar togglePanel={togglePanel}  name={icon} />
                <NavHorizontal/>
                <Login classLogin="login" name="Login" openLogin={openLogin}/>
            </div>
            <SideBarPanel animation={animation} closePanel={closePanel} openModal={openModal}/> 
        </header>
    )
}

export { HeaderDesctop };