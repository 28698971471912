import { des } from "../../assets/description";
function SectionDescription() {
    const header = des.pages.header.blSectDescr;
    const description = des.pages.description.blockchain;
    return(
        <section className="section section--description">
            <h1>{header}</h1>
            <div>{description}</div>
        </section>
    )
}
export { SectionDescription }