
import { colors } from "../colors";

function Vision() {
    return (

        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 482.13 482.13" fill={colors.red} >
            <g>
	<g>
		<path d="M475.266,126.603c-0.001,0-0.001,0-0.002,0l-0.016-0.016l-31.12-4.44c-4.374-0.623-7.415-4.674-6.792-9.048
			c0.299-2.102,1.422-3.998,3.12-5.272l25.152-18.864c3.535-2.651,4.251-7.665,1.6-11.2l-28.8-38.4
			c-2.651-3.535-7.665-4.251-11.2-1.6l-25.144,18.864c-3.52,2.671-8.538,1.983-11.209-1.537c-1.297-1.709-1.853-3.868-1.543-5.991
			l4.448-31.12c0.627-4.374-2.41-8.427-6.783-9.055c-0.003,0-0.006-0.001-0.009-0.001L339.48,2.139
			c-4.373-0.628-8.428,2.408-9.056,6.782c0,0.001,0,0.001,0,0.002l-4.448,31.12c-0.623,4.374-4.674,7.415-9.048,6.792
			c-2.102-0.299-3.998-1.422-5.272-3.12L292.8,18.563c-2.651-3.535-7.665-4.251-11.2-1.6l-24.04,18.04
			c-10.876-1.156-21.844-1.156-32.72,0C153.614,43.144,97.759,99.946,90.816,171.299c-0.216,2.264-0.296,4.512-0.408,6.76h-2.4
			c-3.796,0.007-7.065,2.681-7.824,6.4l-5.528,26.288l-22.144-15.272c-3.178-2.193-7.47-1.802-10.2,0.928l-24,24
			c-2.726,2.729-3.116,7.015-0.928,10.192l15.2,22.104l-26.296,5.528c-3.678,0.806-6.296,4.067-6.288,7.832v32
			c-0.002,3.784,2.649,7.052,6.352,7.832l26.296,5.528l-15.2,22.104c-2.188,3.177-1.798,7.463,0.928,10.192l24,24
			c2.73,2.73,7.022,3.121,10.2,0.928l22.104-15.24l5.528,26.296c0.759,3.719,4.028,6.393,7.824,6.4h32
			c3.784,0.002,7.052-2.649,7.832-6.352l5.528-26.296l22.104,15.2c3.177,2.188,7.463,1.798,10.192-0.928l12.424-12.464v22.8
			c0.036,11.381,6.133,21.88,16,27.552v44.448c0,17.673,14.327,32,32,32h32c17.673,0,32-14.327,32-32v-44.448
			c9.867-5.672,15.964-16.171,16-27.552v-31.664c0.217-13.323,7.187-25.623,18.504-32.656c6.639-4.307,12.935-9.122,18.832-14.4
			l-10.672-11.92c-5.275,4.728-10.911,9.037-16.856,12.888c-15.885,9.97-25.609,27.334-25.808,46.088v31.664c0,8.837-7.163,16-16,16
			h-64c-8.837,0-16-7.163-16-16v-32.328c-0.185-18.399-9.756-35.428-25.376-45.152c-63.407-39.842-82.511-123.541-42.669-186.948
			c24.72-39.341,67.862-63.286,114.325-63.452c-0.119,0.285-0.224,0.576-0.312,0.872c-0.297,2.1,0.253,4.233,1.528,5.928
			l18.856,25.144c1.988,2.573,2.179,6.107,0.48,8.88c-1.636,2.79-4.799,4.312-8,3.848l-31.12-4.448
			c-4.374-0.627-8.427,2.41-9.055,6.783c0,0.003-0.001,0.006-0.001,0.009l-6.792,47.512c-0.627,4.373,2.41,8.427,6.783,9.055
			c0.003,0,0.006,0.001,0.009,0.001l31.12,4.448c4.374,0.623,7.415,4.674,6.792,9.048c-0.299,2.102-1.422,3.998-3.12,5.272
			l-25.144,18.856c-3.535,2.651-4.251,7.665-1.6,11.2l28.8,38.4c2.651,3.535,7.665,4.251,11.2,1.6l25.152-18.856
			c3.538-2.646,8.552-1.923,11.198,1.616c1.266,1.693,1.811,3.819,1.514,5.912l-4.44,31.12c-0.628,4.373,2.408,8.428,6.782,9.056
			c0.001,0,0.001,0,0.002,0l47.52,6.792c0.374,0.048,0.751,0.072,1.128,0.072c3.983,0.005,7.362-2.921,7.928-6.864l4.44-31.12
			c0.623-4.374,4.674-7.415,9.048-6.792c2.102,0.299,3.998,1.422,5.272,3.12l18.864,25.144c2.651,3.535,7.665,4.251,11.2,1.6
			l38.4-28.8c3.535-2.651,4.251-7.665,1.6-11.2l-18.856-25.144c-1.979-2.577-2.17-6.105-0.48-8.88c1.601-2.827,4.793-4.359,8-3.84
			l31.12,4.44c4.373,0.628,8.428-2.409,9.056-6.782c0-0.001,0-0.001,0-0.002l6.784-47.52
			C482.676,131.285,479.64,127.231,475.266,126.603z M210.112,410.059h64v16h-64V410.059z M210.112,442.059h64v8
			c0,8.837-7.163,16-16,16h-32c-8.837,0-16-7.163-16-16V442.059z M146.424,304.099c4.368,3.572,8.932,6.898,13.672,9.96
			c7.559,4.777,13.249,12.004,16.12,20.472l-17.16,17.128l-16.616-11.456c-7.276-5.014-17.239-3.18-22.254,4.096
			c-1.202,1.745-2.046,3.711-2.482,5.784l-4.192,19.976H94.496l-4.192-19.944c-1.82-8.647-10.305-14.182-18.952-12.362
			c-2.073,0.436-4.039,1.28-5.784,2.482l-16.624,11.424l-14.576-14.544l11.448-16.608c5.019-7.273,3.191-17.237-4.082-22.256
			c-1.746-1.205-3.714-2.051-5.79-2.488L16,291.563v-19.008l19.944-4.2c8.644-1.833,14.166-10.326,12.333-18.971
			c-0.435-2.051-1.268-3.996-2.453-5.725l-11.456-16.656l14.576-14.544l16.632,11.456c7.279,5.009,17.242,3.169,22.251-4.11
			c1.2-1.743,2.042-3.707,2.477-5.778l1.4-6.656c1.282,9.218,3.405,18.298,6.344,27.128c-26.323,3.138-45.118,27.022-41.98,53.345
			c3.138,26.323,27.022,45.118,53.345,41.98C125.259,327.934,139.13,318.293,146.424,304.099z M134,292.827
			c-5.974,16.605-24.277,25.223-40.882,19.25c-16.605-5.974-25.223-24.277-19.25-40.882c4.57-12.703,16.631-21.164,30.131-21.136
			c0.104,0,0.2,0,0.304,0C111.66,265.922,121.707,280.391,134,292.827z M460.512,172.971l-23.2-3.312
			c-13.122-1.874-25.278,7.245-27.152,20.366c-0.9,6.301,0.74,12.702,4.56,17.794l14.056,18.744l-25.6,19.2l-14.064-18.736
			c-7.952-10.605-22.995-12.756-33.599-4.804c-5.092,3.818-8.459,9.503-9.361,15.804l-3.24,23.232l-31.672-4.528l3.312-23.2
			c1.879-13.121-7.235-25.281-20.356-27.159c-6.305-0.903-12.709,0.737-17.804,4.559l-18.744,14.056l-19.2-25.6l18.736-14.064
			c10.605-7.952,12.756-22.995,4.804-33.599c-3.818-5.092-9.503-8.459-15.804-9.361l-23.2-3.312l4.528-31.672l23.2,3.312
			c13.121,1.879,25.281-7.235,27.159-20.356c0.903-6.305-0.737-12.709-4.559-17.804l-14.104-18.76l25.6-19.2l14.064,18.744
			c7.952,10.605,22.995,12.756,33.599,4.804c5.092-3.818,8.459-9.503,9.361-15.804l3.312-23.2l31.672,4.52l-3.312,23.2
			c-1.874,13.122,7.244,25.278,20.366,27.152c6.301,0.9,12.702-0.74,17.794-4.56l18.744-14.056l19.2,25.6l-18.744,14.064
			c-10.605,7.952-12.756,22.995-4.804,33.599c3.818,5.092,9.503,8.459,15.804,9.361l23.2,3.312L460.512,172.971z"/>
	</g>
            </g>
            <g>
	<g>
		<path d="M344,76.171c-35.346,0-64,28.654-64,64c0,35.346,28.654,64,64,64c35.346,0,64-28.654,64-64
			C407.96,104.841,379.33,76.21,344,76.171z M344,188.171c-26.51,0-48-21.49-48-48s21.49-48,48-48c26.51,0,48,21.49,48,48
			C391.969,166.668,370.497,188.14,344,188.171z"/>
	</g>
            </g>
            <g>
	<g>
		<rect x="234.112" y="354.059" width="16" height="24"/>
	</g>
            </g>
            <g>
	<g>
		<path d="M207.12,275.491c-49.385-19.292-73.78-74.966-54.487-124.351c7.476-19.136,20.893-35.369,38.279-46.313l-8.552-13.536
			c-52.336,32.971-68.035,102.126-35.064,154.462c12.769,20.269,31.701,35.91,54.016,44.626c19.752,7.568,32.795,26.528,32.8,47.68
			h16C250.137,310.307,233.035,285.418,207.12,275.491z"/>
	</g>
            </g>
            <g>
	<g>
		<rect x="394.112" y="298.059" width="56" height="16"/>
	</g>
            </g>
            <g>
                <g>
                    <rect x="402.113" y="334.822" transform="matrix(0.6401 -0.7683 0.7683 0.6401 -133.6437 446.8239)" width="16" height="62.48"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="346.112" y="362.059" width="16" height="56"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="9.128" y="130.058" transform="matrix(0.9701 -0.2425 0.2425 0.9701 -32.2251 14.3351)" width="65.968" height="15.992"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="46.111" y="35.657" transform="matrix(0.5812 -0.8137 0.8137 0.5812 -34.3557 73.3688)" width="15.992" height="68.816"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="97.938" y="1.024" transform="matrix(0.9688 -0.2477 0.2477 0.9688 -5.133 27.2971)" width="16" height="66.056"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>

    )
}
export { Vision };