import {  Route,  Routes, HashRouter as Router } from 'react-router-dom';
import { useState } from "react";
import { Footer } from './components/Footer';
import { Header } from './components/Header';



import { Home } from './pages/Home';
import { Contact } from './pages/Contact';
import { NotFound } from './pages/NotFound';
import { SaaS } from './pages/SaaS';
import { PWA } from './pages/PWA';
import { MVP } from './pages/MVP';
import { MarketPlace } from './pages/MarketPlace';
import { Blockchain } from './pages/Blockchain';
import { Project } from './pages/Project';
import { About } from './pages/About';
import { Career } from './pages/Career';
import { Approach } from './pages/Approach';
import { Modal } from './components/Modal';
import { LoginModal } from './components/LoginModal';
import ScrollToTop from './components/ScrollToTop';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';
import { LegalDisclaimer } from './pages/LegalDisclaimer/LegalDisclaimer';



function App() {
  
  const [modal, setModal] = useState(false);
  const [login, setLogin] = useState(false);

  const openModal = () => {
      setModal(true);
      document.documentElement.style.overflowY = 'hidden';
  };
  const closeModal = () => {
    console.log(111)
      setModal(false);
      document.documentElement.style.overflowY = '';
  };

  const openLogin = () => {
        setLogin(true);
        document.documentElement.style.overflowY = 'hidden';
};
const closeLogin = () => {
      setLogin(false);
      document.documentElement.style.overflowY = '';
};
  const WrappedHome = function() {
    return (<Home  openModal={openModal}  />);
};
const WrappedBlockchain = function() {
    return (<Blockchain  openModal={openModal}  />);
};

  return (
    
    <>
        <Router >
            <ScrollToTop/>
            <Header openModal={openModal} openLogin={openLogin}/>
            <main>
                <Routes>
                    <Route exact path="/" element={<WrappedHome/>}/>
                    <Route  path="/saas-development" element={<SaaS/>}  />
                    <Route  path="/pwa-development" element={<PWA/>}  />
                    <Route  path="/mvp-development" element={<MVP/>}  />
                    <Route  path="/marketplace-development" element={<MarketPlace/>}  />
                    <Route  path="/blockchain-development" element={<WrappedBlockchain/>}  />
                    <Route  path="/project-management" element={<Project/>}  />
                    <Route  path="/about-us" element={<About/>}  />
                    <Route  path="/career" element={<Career/>}  />
                    <Route  path="/approach" element={<Approach/>}  />
                    <Route  path="/contacts" element={<Contact/>} />
                    <Route  path="/privacy-policy" element={<PrivacyPolicy/>} />
                    <Route  path="/legal-disclaimer" element={<LegalDisclaimer/>} />
                    <Route  path="*" element={<NotFound/>}  />
                </Routes>
                {modal && <Modal closeModal={closeModal}/>}
                {login && <LoginModal closeModal={closeLogin}/>}
            </main>
            <Footer/>
        </Router>    
    </>
  );
}

export default App;
