import { colors } from "../colors";

function Token() {
    return(

        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" fill={colors.red} >
                <g>
                    <g>
                        <g>
                            <path d="M445.014,198.054l-9.348,11.848c38.92,30.707,61.243,76.722,61.243,126.246c0,88.644-72.117,160.762-160.763,160.762
                                c-86.163,0-156.676-67.313-160.529-153.245l-0.007-0.134c-0.15-2.393-0.226-4.878-0.226-7.383c0-13.052,1.57-25.946,4.673-38.362
                                c14.261-58.355,59.371-103.465,117.766-117.736c12.379-3.095,25.273-4.663,38.324-4.663c0.156,0,0.307,0.006,0.462,0.007
                                c0.001,0.155,0.007,0.306,0.007,0.462h10.842c25.792,1.787,50.918,9.812,72.895,23.344l7.912-12.85
                                c-23.335-14.368-49.911-23.065-77.246-25.356c-3.425-41.327-21.309-79.987-50.813-109.49
                                C266.997,18.29,222.833,0.001,175.854,0.001c-46.979,0-91.143,18.29-124.353,51.501C18.29,84.711,0,128.874,0,175.854
                                c0,47.851,19.754,93.946,54.223,126.824l6.746,6.746l0.308-0.308c27.897,24.104,62.844,38.818,99.708,41.977
                                c3.545,42.683,22.393,82.272,53.54,112.096C247.396,494.666,290.589,512,336.147,512c96.966,0,175.854-78.887,175.854-175.853
                                C512,281.974,487.584,231.641,445.014,198.054z M335.811,160.299c-11.108,0.021-22.115,1.095-32.824,3.169
                                c-2.582-26.974-13.609-52.201-31.659-72.418l23.418-23.419C318.169,93.343,332.525,125.755,335.811,160.299z M183.4,15.27
                                c37.609,1.725,72.869,16.328,100.675,41.692l-23.418,23.417c-21.495-19.204-48.462-30.396-77.257-32.064V15.27z M168.308,15.27
                                v33.046c-28.803,1.667-55.776,12.864-77.259,32.062L67.633,56.962C95.439,31.598,130.7,16.995,168.308,15.27z M56.962,67.633
                                l23.417,23.417c-19.204,21.496-30.396,48.463-32.064,77.258H15.269C16.995,130.699,31.597,95.44,56.962,67.633z M60.938,288.114
                                c-27.461-27.987-43.796-65.452-45.659-104.715H48.32c1.818,30.54,14.609,59.528,35.909,81.422L60.938,288.114z M160.296,335.879
                                c-32.59-3.199-63.445-16.285-88.315-37.467l23.395-23.394c15.932,12.988,34.778,21.947,54.937,26.026l2.994-14.792
                                c-19.834-4.014-38.24-13.38-53.25-27.105c-23.436-21.263-36.877-51.622-36.877-83.295c0-30.106,11.736-58.379,33.064-79.629
                                c21.231-21.309,49.504-33.045,79.61-33.045c30.106,0,58.379,11.736,79.63,33.065c19.129,19.056,30.593,43.993,32.659,70.755
                                c-28.746,8.132-55.021,23.546-76.31,44.834c-22.776,22.776-38.833,51.258-46.426,82.33
                                C162.033,307.652,160.317,321.686,160.296,335.879z"/>
                            <path d="M320.118,391.781h8.483v16.498h15.091v-16.498h8.483c13,0,23.576-10.576,23.576-23.576v-16.029
                                c0-12.999-10.576-23.575-23.576-23.575h-32.058c-4.678,0-8.484-3.806-8.484-8.484v-16.029c0-4.678,3.806-8.484,8.484-8.484
                                h32.058c4.678,0,8.484,3.806,8.484,8.484v16.029h15.091v-16.029c0-13-10.576-23.576-23.576-23.576h-8.483v-16.498h-15.091v0
                                v16.498h-8.483c-13,0-23.576,10.576-23.576,23.576v16.029c0,13,10.576,23.576,23.576,23.576h32.058
                                c4.678,0,8.484,3.806,8.484,8.483v16.029c0,4.678-3.806,8.484-8.484,8.484h-32.058c-4.678,0-8.484-3.806-8.484-8.484v-16.029
                                h-15.091v16.029C296.542,381.205,307.118,391.781,320.118,391.781z"/>
                            <path d="M200.366,336.147c0,74.87,60.911,135.78,135.78,135.78s135.78-60.911,135.78-135.78s-60.911-135.781-135.78-135.781
                                S200.366,261.278,200.366,336.147z M456.835,336.147c0,66.549-54.141,120.688-120.688,120.688s-120.688-54.141-120.688-120.688
                                s54.141-120.689,120.688-120.689S456.835,269.6,456.835,336.147z"/>
                            <rect x="408.28" y="328.599" width="16.03" height="15.091"/>
                            <rect x="247.991" y="328.599" width="16.03" height="15.091"/>
                            <path d="M96.177,175.854h15.091c0-35.612,28.973-64.586,64.586-64.586V96.176C131.92,96.176,96.177,131.92,96.177,175.854z"/>
                            <rect x="96.172" y="191.882" width="15.091" height="16.029"/>
                        </g>
                    </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
        </svg>

    )
}
export { Token };