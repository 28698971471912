import { SubNavItem } from "./SubNavItem";
import { Pathway } from '../assets/svg/Pathway';
import {des} from '../assets/description';
import { About } from "../assets/svg/About";
import {Goal} from "../assets/svg/Goal";


function SubListCompany() {
    return(
        <div className="sub-nav">
            <div className="sub-nav--list">
                <SubNavItem path="/about-us" name="about us" description={des.about} image={<About/>} />
                <SubNavItem path="/approach" name="our approach" description={des.appoach} image={<Pathway/>} />
                <SubNavItem path="/career" name="careers" description={des.career} image={<Goal/>} />

            </div>
        </div>
    )
}
export { SubListCompany }