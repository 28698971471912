

function Item({image,subheader, des, itemClass}) {
    return(
        <div className={itemClass}>
            {image}
            { subheader && <h2>{subheader}</h2>}
            <p>{des}</p>
        </div>
    )
}
export { Item };