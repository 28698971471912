
function BlockchainItem({item, image, header, description, btn}) {
    return(
        <div className={item}>
                <div>
                    {image}
                    <h2>{header}</h2>
                    <span>{description}</span>
                  
                    {btn}
                </div>
        </div>
    )
}
export { BlockchainItem }