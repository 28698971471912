import { LeftText } from "./LeftText";
import { RightText } from "./RightText";
import { des } from "../assets/description";



function Wrapper(props){
    const {
        partClass,
        img,color, 
        arrow, fill, 
        classTextBtn, 
        wrappWidth, 
        an, 
        openModal=Function.prototype} = props
    return(
        <div className={`wrapper--${partClass} ${an}`} style={{width: wrappWidth + '%'}}>
            <div className={partClass}>
                <LeftText partClass={partClass}
                 text={des.flyingLogo.textLeft}
                 name={des.button.flyingBtn}
                 classBtn={`button button--contact  btn-${color}`}
                 classTextBtn={classTextBtn}
                 openModal={openModal}/>
                <img className="large--logo" src={img} alt="logo" />
                <RightText partClass={partClass} text={des.flyingLogo.textRight} fill={fill}/>
                {arrow}
            </div>
             
        </div>
    )
}
export {Wrapper};