import { colors } from "../colors";

function HyperledgerFabric() {
    return(
            <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="-1.82 -1.82 94.77 94.52">

                <defs>
                <clipPath id="a">
                <path fill="none" d="M.06 90.978h325.1V.06H.06z"/></clipPath>
                <clipPath id="b"><path fill="none" d="M.06 90.978h325.1V.06H.06z"/></clipPath>
                </defs>
                <path fill={colors.red} d="M14.573 67.213c-.397.832-1.403 1.188-2.235.791L.954 62.572c-.833-.397-1.188-1.403-.791-2.235l1.978-4.145c.397-.832 1.402-1.188 2.234-.791l11.385 5.432c.832.397 1.188 1.403.791 2.235l-1.978 4.145zm36.888 17.602c-.397.832-1.402 1.188-2.235.791L30.605 76.72c-.833-.396-1.188-1.402-.792-2.234l1.979-4.145c.397-.832 1.402-1.188 2.234-.791l18.622 8.885c.832.397 1.188 1.403.791 2.235l-1.978 4.145" clipPath="url(#a)"/>
                <path fill={colors.red} d="M59.421 20.681c-.398.832-1.403 1.188-2.235.791l-18.52-8.837c-.832-.397-1.188-1.402-.791-2.234l1.978-4.146c.397-.832 1.402-1.188 2.234-.791l18.52 8.837c.832.398 1.189 1.403.791 2.235l-1.977 4.145zm29.668 14.158c-.397.832-1.402 1.188-2.234.791l-11.403-5.441c-.832-.397-1.188-1.403-.791-2.235l1.978-4.145c.397-.832 1.403-1.188 2.235-.791l11.402 5.441c.832.397 1.188 1.403.791 2.235l-1.978 4.145m-56.915-4.514c-.397.832-1.402 1.188-2.235.791l-11.384-5.432c-.832-.397-1.188-1.403-.791-2.235l1.978-4.145c.397-.832 1.403-1.188 2.235-.791l11.384 5.432c.832.397 1.188 1.403.791 2.235l-1.978 4.145m36.889 17.602c-.397.832-1.403 1.188-2.235.791l-18.621-8.886c-.832-.397-1.188-1.402-.791-2.235l1.978-4.145c.397-.832 1.402-1.188 2.235-.791l18.621 8.886c.832.397 1.188 1.403.791 2.235l-1.978 4.145m2.447 23.812c-.397.832-1.403 1.188-2.235.79L57.85 67.077c-.832-.397-1.188-1.402-.791-2.234l1.978-4.146c.397-.831 1.402-1.188 2.234-.791l11.426 5.452c.832.397 1.188 1.403.791 2.235l-1.978 4.146M41.818 57.57c-.397.833-1.403 1.188-2.235.791l-18.577-8.864c-.832-.397-1.188-1.403-.791-2.235l1.978-4.145c.397-.833 1.403-1.189 2.235-.791l18.577 8.864c.832.397 1.188 1.403.791 2.235l-1.978 4.145"/>
                <path fill={colors.red} d="M12.682 52.553c-.397.832-1.402 1.188-2.234.791l-4.147-1.979c-.832-.397-1.188-1.402-.791-2.234l8.885-18.62c.397-.832 1.402-1.188 2.235-.792l4.146 1.979c.832.397 1.188 1.403.791 2.235l-8.885 18.62m17.602-36.888c-.397.832-1.403 1.188-2.235.791l-4.146-1.979c-.832-.397-1.188-1.402-.791-2.235L28.488.976c.397-.832 1.403-1.188 2.235-.791l4.146 1.979c.832.397 1.188 1.402.791 2.235l-5.376 11.266m32.439 74.382c-.397.832-1.403 1.188-2.235.791l-4.146-1.979c-.832-.397-1.188-1.403-.791-2.235l5.376-11.266c.397-.832 1.403-1.188 2.235-.791l4.146 1.978c.832.397 1.188 1.403.791 2.235l-5.376 11.267" clipPath="url(#b)"/>
                <path fill={colors.red} d="M76.816 60.512c-.397.832-1.402 1.188-2.234.791l-4.147-1.979c-.832-.397-1.188-1.402-.791-2.234l8.885-18.621c.397-.832 1.403-1.188 2.235-.791l4.147 1.979c.832.396 1.188 1.402.791 2.234l-8.886 18.621m-9.643-27.245c-.397.832-1.402 1.188-2.235.791l-4.146-1.979c-.833-.397-1.188-1.403-.791-2.234l5.376-11.267c.396-.832 1.402-1.188 2.234-.791l4.147 1.979c.832.397 1.188 1.403.791 2.235l-5.376 11.266M49.571 70.155c-.397.832-1.402 1.188-2.234.791l-4.147-1.979c-.833-.396-1.189-1.402-.791-2.234l8.885-18.62c.397-.833 1.403-1.188 2.235-.792l4.146 1.979c.832.397 1.188 1.403.791 2.235l-8.885 18.62m-23.736 2.29c-.397.832-1.403 1.188-2.235.791l-4.147-1.979c-.832-.397-1.188-1.403-.791-2.235l5.376-11.266c.398-.832 1.403-1.188 2.235-.791l4.147 1.978c.832.397 1.188 1.403.791 2.235l-5.376 11.267M39.928 42.91c-.397.832-1.403 1.188-2.235.791l-4.146-1.979c-.833-.397-1.188-1.402-.792-2.234l8.886-18.621c.397-.832 1.402-1.188 2.235-.791l4.146 1.978c.832.397 1.188 1.403.791 2.235L39.928 42.91"/>
            </svg>
    )
}
export { HyperledgerFabric }