import { colors } from "../colors";

function EOS() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.2 50">
                <g id="Layer_2" >
                <g id="Layer_1-2" >
                <path d="M16.6,0,4.9,16.1,0,39.9,16.6,50,33.2,39.9,28.2,16ZM2.7,38.8,6.4,20.7l8.4,25.5ZM7.6,16.6l9-12.4,9,12.4-9,27.2ZM18.3,46.2l8.4-25.5,3.7,18.1Z" fill={colors.red}/>
                </g>
                </g>
        </svg>
    )
}
export { EOS }