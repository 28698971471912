

const Lock = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="icon-114-lock" fill="#000">
                    <path d="M16 21.915v2.594a.5.5 0 0 0 1 0v-2.594a1.5 1.5 0 1 0-1 0Zm-1 .585v1.998a1.5 1.5 0 1 0 3 0V22.5a2.5 2.5 0 1 0-3 0ZM9 14v-3.5a7.5 7.5 0 1 1 15 0V14c1.66.005 3 1.35 3 3.01v9.98A3.002 3.002 0 0 1 23.991 30H9.01A3.008 3.008 0 0 1 6 26.99v-9.98A3.002 3.002 0 0 1 9 14Zm1 0v-3.507A6.497 6.497 0 0 1 16.5 4c3.583 0 6.5 2.907 6.5 6.493V14h-1v-3.49A5.509 5.509 0 0 0 16.5 5a5.501 5.501 0 0 0-5.5 5.51V14h-1Zm2 0v-3.5C12 8.01 14.015 6 16.5 6c2.48 0 4.5 2.015 4.5 4.5V14h-9Zm-3.003 1A2.001 2.001 0 0 0 7 17.006v9.988A2 2 0 0 0 8.997 29h15.006A2.001 2.001 0 0 0 26 26.994v-9.988A2 2 0 0 0 24.003 15H8.997Z" id="lock"/>
                </g>
            </g>
        </svg>
    )
}

export {Lock }