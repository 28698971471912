
import { LoginError } from "../elements/LoginError";
import { LoginContent } from "../elements/LoginContent";
import { useState } from "react";


const LoginModal = ({closeModal=Function.prototype}) => {
    const [error, setError] =  useState(false);

    const errorLogin = (e) => {
        e.preventDefault();
        setError(true);
    }
    return(
        <div className="login--wrapper" >
            <div className="login--container">
                <LoginContent closeModal={closeModal}  err={errorLogin}/>
                {error && <LoginError/>}
            </div>
            
        </div>
    )
}

export { LoginModal };