
const disclaimer = {
    header: 'Legal Disclaimer for OZ Merchant Solutions LLC',
    p : [
        'All the knowledge presented on the website including images, text, website layout and design, company logo, trademarks, articles, reviews, photographs, pictures is protected by copyright and other intellectual property laws.',

        `Anyone visiting OZ Merchant Solutions LLC' website is not allowed to transfer, modify, share, publish, transmit, sell, reproduce the content available on the website. When accessing OZ Merchant Solutions LLC, you obey to adhere the copyright and all the restrictions related to the website`,

        'Anyone found accountable will have to face the prosecution under the intellectual property laws.'
    ]
}

export default disclaimer;