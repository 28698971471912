import { colors } from "../colors";

function Partnership() {
    return(

    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" fill={colors.red}>
        <g>
            <g>
                <path d="M217.25,371.42c-1.86-1.87-4.44-2.93-7.08-2.93c-2.63,0-5.2,1.06-7.07,2.93c-1.86,1.86-2.92,4.43-2.92,7.07
                    c0,2.63,1.06,5.21,2.92,7.069c1.87,1.86,4.44,2.931,7.07,2.931c2.64,0,5.21-1.07,7.08-2.931c1.86-1.859,2.93-4.439,2.93-7.069
                    C220.18,375.849,219.11,373.28,217.25,371.42z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M438.973,172.277h-34.481c12.423-12.392,20.122-29.519,20.122-48.411V44.29V10c0-5.523-4.477-10-10-10h-77.8
                    c-27.031,0-49.022,21.991-49.022,49.022v32.869c0,0.07,0.009,0.137,0.01,0.206c-0.001,0.069-0.01,0.137-0.01,0.206v41.563
                    c0,18.892,7.699,36.018,20.122,48.411H200.053c12.423-12.392,20.122-29.519,20.122-48.411V44.29V10c0-5.523-4.477-10-10-10h-77.8
                    c-27.031,0-49.022,21.991-49.022,49.022v32.869c0,0.07,0.009,0.137,0.01,0.206c-0.001,0.069-0.01,0.137-0.01,0.206v41.563
                    c0,18.892,7.699,36.018,20.122,48.411h-22.57C36.294,172.277,0,208.571,0,253.183v125.304c0,5.522,4.477,10,10,10h158
                    c5.523,0,10-4.478,10-10c0-5.522-4.477-10-10-10H65.212v-64.561c0-5.522-4.477-10-10-10c-5.523,0-10,4.478-10,10v64.561H20
                    V253.183c0-33.583,27.322-60.906,60.906-60.906h28.061l32.797,49.065v59.602c0,5.522,4.477,10,10,10c5.523,0,10-4.478,10-10
                    v-59.337l36.526-49.33h36.245c29.239,0,53.027,23.788,53.027,53.027v41.024c0,5.522,4.477,10,10,10s10-4.478,10-10v-41.024
                    c0-20.869-8.813-39.707-22.896-53.027h26.723l34.484,51.588c1.808,2.706,4.82,4.362,8.073,4.44
                    c0.081,0.002,0.161,0.003,0.241,0.003c3.164,0,6.148-1.499,8.036-4.049l38.489-51.981h38.261
                    c29.239,0,53.027,23.788,53.027,53.027v41.024c0,5.522,4.477,10,10,10s10-4.478,10-10v-41.024
                    C512,205.037,479.24,172.277,438.973,172.277z M103.354,49.022c0-16.003,13.019-29.022,29.022-29.022h67.8v24.29
                    c0,15.219-12.381,27.601-27.601,27.601h-69.221V49.022z M152.182,220.939l-19.159-28.662h40.381L152.182,220.939z
                    M151.765,172.278c-26.694,0-48.411-21.717-48.411-48.411V91.891h69.221c10.284,0,19.809-3.289,27.601-8.856v40.832
                    C200.176,150.561,178.459,172.278,151.765,172.278z M307.791,49.022L307.791,49.022C307.792,33.019,320.812,20,336.814,20h67.8
                    v24.29c0,15.219-12.381,27.601-27.601,27.601h-69.222V49.022z M354.604,220.939l-19.159-28.662h40.381L354.604,220.939z
                    M356.204,172.278c-26.694,0-48.412-21.717-48.412-48.411V91.891h69.222c10.284,0,19.809-3.289,27.601-8.856v40.832
                    C404.615,150.561,382.898,172.278,356.204,172.278z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M475.405,312.486h-46.513v-19.231c0-13.151-10.7-23.852-23.852-23.852h-52.081c-13.152,0-23.852,10.7-23.852,23.852
                    v19.231h-46.513c-20.178,0-36.595,16.416-36.595,36.595v126.324C246,495.584,262.417,512,282.595,512h192.811
                    C495.583,512,512,495.584,512,475.405V349.081C512,328.902,495.583,312.486,475.405,312.486z M352.96,289.403h52.081
                    c2.124,0,3.852,1.728,3.852,3.852v19.231h-59.784v-19.231h-0.001C349.108,291.131,350.836,289.403,352.96,289.403z M492,475.405
                    c0,9.151-7.444,16.595-16.595,16.595h-192.81c-9.15,0-16.595-7.444-16.595-16.595v-72.277h86.378v1.636
                    c0,14.679,11.942,26.621,26.622,26.621c14.68,0,26.622-11.942,26.622-26.621v-1.636H492V475.405z M372.378,404.764v-13.271h13.244
                    v13.271c0,3.65-2.97,6.621-6.622,6.621C375.348,411.385,372.378,408.414,372.378,404.764z M492,383.128h-86.378v-1.635
                    c0-5.522-4.477-10-10-10h-33.243c-5.523,0-10,4.478-10,10v1.635H266v-34.047c0-9.15,7.444-16.595,16.595-16.595h56.513h79.784
                    h56.513c9.15,0,16.595,7.444,16.595,16.595V383.128z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>

    )
}
export { Partnership }