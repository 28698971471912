import { des } from "../../assets/description";
import { StackIco } from "./StackIco";
import { ProjectText } from "./ProjectText";
import { ImageTex } from "./ImageStackTex";
import { StackTex } from "./StackTech";

function Project() {
    const subHeader1 = des.tech.subHeader1;
    const text1 = des.tech.text1;
    const subHeader2 = des.tech.subHeader2;
    const text2 = des.tech.text2;
    return(
        <>
            <div className="project">
                <ProjectText subHeader1={subHeader1} text1={text1} />
                <StackIco partClass="project_ico"  imageIco={<ImageTex classIco="project_ico"/>}/>
            </div>
            <div className="stack--tech">
                <StackIco partClass="tech_ico" classIco="tech_ico" imageIco={<StackTex classIco="tech_ico"/>}/>
                <ProjectText subHeader1={subHeader2} text1={text2} />
            </div>
        </>
    )
}
export { Project };