import {colors} from '../colors';

function About() {
    return(

        <svg version="1.1" id="Capa_1"  x="0px" y="0px" width="31.178px" height="31.178px" viewBox="0 0 31.178 31.178" fill={colors.fillNav} >
        <g>
	        <g>
		<circle cx="5.042" cy="5.721" r="3.866"/>
		<path d="M30.779,6.78h-9.346V5.722h-2.479V6.78h-8.877H9.446v1.275h0.631v2.806l-1.452-0.692H6.449l-1.474,1.709l-1.426-1.709
			l-3.133,0.582l-0.2,6.949h1.328l0.072,1.443h0.202v0.879v0.649v6.884H1.551L0,27.893v1.43h1.321l1.542-0.251l0.014,0.251h1.708
			v-1.593v-0.173v-6.883h0.973v6.883v0.173v1.593h1.708l0.014-0.251l1.542,0.251h1.321v-1.43L8.59,27.557H8.325v-6.883v-0.65v-0.879
			H8.57l0.316-6.4l1.191,0.539v7.355h9.136v2.343l-5.042,5.688h1.812l3.404-3.844v3.844h1.041v-3.84l3.399,3.84h1.841l-5.07-5.688
			v-2.343h10.182V8.056h0.398V6.78H30.779z M29.887,19.7h-9.291h-1.383H10.97v-6.013l3.717,1.682l-0.014-2.317l-3.703-1.765V8.056
			h18.917V19.7z"/>
		<path d="M18.396,13.728v2.915c0,0,1.058-0.367,2.09-0.012c1.031,0.356,2.777,1.406,2.777,1.406s0.532-0.867,1.156-1.269
			c0.623-0.401,1.104-0.709,1.104-0.709v-2.254l-1.922,1.698L18.396,13.728z"/>
		<polygon points="16.404,15.174 16.027,15.174 16.027,16.77 17.348,16.77 17.348,15.174 16.95,15.174 16.95,12.959 23.535,15.069 
			27.727,11.33 20.912,9.366 15.555,12.512 16.404,12.785 		"/>
	        </g>
        </g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
        </svg>

    )
}

export {About};