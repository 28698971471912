function FooterMisc() {
    return(
        <div className="footer--misc">
            <div className="footer--copyright">
                <p>©{new Date().getFullYear()} Oz Merchant Solutions LLC</p>
            </div>
        </div>
    )
}

export { FooterMisc };