import { colors } from "../colors";

function Mob() {
    return(
        <svg height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg" fill={colors.red}>
            <g><path d="m49.916 109.021a5.75 5.75 0 1 0 5.75 5.75 5.757 5.757 0 0 0 -5.75-5.75zm0 8a2.25 2.25 0 1 1 2.25-2.25 2.253 2.253 0 0 1 -2.25 2.25z"/><path d="m33.664 12h32.5a1.75 1.75 0 0 0 0-3.5h-32.5a1.75 1.75 0 0 0 0 3.5z"/><path d="m114.589 35.251h-26.418v-29.501a5.756 5.756 0 0 0 -5.75-5.75h-65.01a5.757 5.757 0 0 0 -5.75 5.75v116.5a5.757 5.757 0 0 0 5.75 5.75h65.01a5.756 5.756 0 0 0 5.75-5.75v-31.37l8.974-8.975h17.444a1.749 1.749 0 0 0 1.75-1.75v-43.155a1.749 1.749 0 0 0 -1.75-1.749zm-56.337 0a1.75 1.75 0 0 0 -1.752 1.749v43.155a1.75 1.75 0 0 0 1.75 1.75h17.45l8.975 8.975v11.372h-69.514v-82.5h69.51v15.5zm-40.841-31.751h65.01a2.253 2.253 0 0 1 2.25 2.25v10.5h-69.51v-10.5a2.253 2.253 0 0 1 2.25-2.25zm65.01 121h-65.01a2.253 2.253 0 0 1 -2.25-2.25v-16.5h69.51v16.5a2.253 2.253 0 0 1 -2.25 2.25zm30.418-46.095h-16.418a1.75 1.75 0 0 0 -1.237.513l-8.763 8.763-8.763-8.763a1.75 1.75 0 0 0 -1.237-.513h-16.421v-39.653h52.837z"/><path d="m81.49 42.875a7.969 7.969 0 1 0 7.969 7.969 7.979 7.979 0 0 0 -7.969-7.969zm0 12.438a4.469 4.469 0 1 1 4.469-4.469 4.474 4.474 0 0 1 -4.469 4.469z"/><path d="m79.695 61.711a11.657 11.657 0 0 0 -11.644 11.644 1.749 1.749 0 0 0 1.75 1.75h23.378a1.75 1.75 0 0 0 1.75-1.75 11.658 11.658 0 0 0 -11.645-11.644zm11.544 9.894h-19.499a8.159 8.159 0 0 1 7.955-6.394h3.589a8.158 8.158 0 0 1 7.955 6.394z"/><path d="m93.266 59.739h2.518v2.519a1.75 1.75 0 1 0 3.5 0v-2.519h2.516a1.75 1.75 0 0 0 0-3.5h-2.516v-2.517a1.75 1.75 0 1 0 -3.5 0v2.517h-2.518a1.75 1.75 0 0 0 0 3.5z"/></g>
        </svg>
    )
}

export { Mob };