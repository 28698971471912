

const Login = ({classLogin, name, openLogin=Function.prototype,}) => {


    const loginStyle = {
        color: "var(--color-secondary)",
        transform: "translateY(-4px)",
        marginLeft: "40px",
        cursor: "pointer",
    }

    return (
        <span className={classLogin} style={loginStyle} onClick={openLogin}>{name}</span>
    )
}

export { Login }