import { FooterMisc } from "./FooterMisc";
import { FooterWrapper } from "./FooterWrapper";

function Footer() {
    return(
        <footer>
            <div className="container footer--container">
            <FooterWrapper/>
            <FooterMisc/>
            </div>
        </footer>
    )
}

export { Footer };