import { CareerBlock } from "./CareerBlock";
import { des } from "../assets/description";
import { ToolsBlock } from "./ToolsBlock";
import { Axure} from "../assets/svg/Axure";
import { GitHubApr } from "../assets/svg/GitHubApr";
import { GitLab } from "../assets/svg/GitLab";
import { Jira } from "../assets/svg/Jira";
import { Slack } from "../assets/svg/Slack";
import { Trello } from "../assets/svg/Trello";

function Approach() {
    const BlockSubHeader = des.pages.subheader.blockAprSubHeader ;
    const BlockDescription = des.pages.description.blockAprDescription;
    const header = des.pages.header.approach;
    const subheader = des.pages.subheader.approach;
    const description = des.pages.description.approach;
    const margin={margin: '20px'}
    return(
        <section className="section section--company">
            <div className="container">
                <h1>{header}</h1>
                <p>{description}</p>
                <h2 dangerouslySetInnerHTML={{__html: subheader[0]}}></h2>
                <div className="company--wrapper  --approach">
                    <CareerBlock BlockSubHeader={BlockSubHeader.block1} BlockDescription={BlockDescription.block1} margin={margin}/>
                    <CareerBlock BlockSubHeader={BlockSubHeader.block2} BlockDescription={BlockDescription.block2} margin={margin}/>
                    <CareerBlock BlockSubHeader={BlockSubHeader.block3} BlockDescription={BlockDescription.block3} margin={margin}/>
                    <CareerBlock BlockSubHeader={BlockSubHeader.block4} BlockDescription={BlockDescription.block4} margin={margin}/>
                    <CareerBlock BlockSubHeader={BlockSubHeader.block5} BlockDescription={BlockDescription.block5} margin={margin}/>
                    <CareerBlock BlockSubHeader={BlockSubHeader.block6} BlockDescription={BlockDescription.block6} margin={margin}/>
                </div>
                <h2>{subheader[1]}</h2>
                <div className="tools">
                    <ToolsBlock toolSubheader="Trello" image={<Trello/>}/>
                    <ToolsBlock toolSubheader="Jira" image={<Jira/>}/>
                    <ToolsBlock toolSubheader="Slack" image={<Slack/>}/>
                    <ToolsBlock toolSubheader="Axure" image={<Axure/>}/>
                    <ToolsBlock toolSubheader="Github" image={<GitHubApr/>}/>
                    <ToolsBlock toolSubheader="Gitlab" image={<GitLab/>}/>
                </div>
            </div>
        </section>
    )
}

export { Approach };