import { colors } from "../colors";

function Enterprise() {
    return(

        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 480 480" fill={colors.red}>
                <g>
                    <g>
                        <rect x="168" y="160" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="168" y="192" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="168" y="224" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="168" y="256" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="200" y="160" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="200" y="192" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="200" y="224" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="200" y="256" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="232" y="160" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="232" y="192" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="232" y="224" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="232" y="256" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="264" y="160" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="200" y="128" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="232" y="128" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="264" y="128" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="264" y="192" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="264" y="224" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="264" y="256" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="296" y="160" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="296" y="192" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="296" y="224" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="296" y="256" width="16" height="16"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M472.009,120c-0.003,0-0.006,0-0.009,0H352V8c0.003-4.416-3.575-7.997-7.991-8c-0.003,0-0.006,0-0.009,0H136
                            c-4.416-0.003-7.997,3.575-8,7.991c0,0.003,0,0.006,0,0.009v112H8c-4.416-0.003-7.997,3.575-8,7.991c0,0.003,0,0.006,0,0.009v288
                            c-0.003,4.416,3.575,7.997,7.991,8c0.003,0,0.006,0,0.009,0h16.723c-0.464,2.642-0.706,5.318-0.723,8v40
                            c-0.003,4.416,3.575,7.997,7.991,8c0.003,0,0.006,0,0.009,0h416c4.416,0.003,7.997-3.575,8-7.991c0-0.003,0-0.006,0-0.009v-40
                            c-0.016-2.682-0.258-5.358-0.723-8H472c4.416,0.003,7.997-3.575,8-7.991c0-0.003,0-0.006,0-0.009V128
                            C480.003,123.584,476.425,120.003,472.009,120z M344,344c0,8.837-7.163,16-16,16s-16-7.163-16-16s7.163-16,16-16
                            C336.832,328.011,343.989,335.168,344,344z M152,328c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16
                            C136.011,335.168,143.168,328.011,152,328z M104,464H40v-32c0-17.673,14.327-32,32-32s32,14.327,32,32V464z M56,368
                            c0-8.837,7.163-16,16-16s16,7.163,16,16s-7.163,16-16,16C63.168,383.989,56.011,376.832,56,368z M105.212,397.425
                            c-3.017-2.91-6.4-5.414-10.064-7.449c12.202-12.784,11.731-33.04-1.053-45.243s-33.04-11.731-45.243,1.053
                            c-11.803,12.365-11.803,31.824,0,44.189c-7.636,4.238-13.98,10.467-18.358,18.024H16V136h112v187.056
                            c-10.986,12.327-10.615,31.039,0.852,42.92C116.852,372.613,108.253,384.053,105.212,397.425z M184,464h-64v-56
                            c0-17.673,14.327-32,32-32s32,14.327,32,32V464z M280,464h-80v-56c0-22.091,17.909-40,40-40c22.091,0,40,17.909,40,40V464z
                            M216,328c0-13.255,10.745-24,24-24s24,10.745,24,24s-10.745,24-24,24C226.751,351.986,216.014,341.249,216,328z M288.722,380.495
                            c-5.357-9.444-13.327-17.139-22.953-22.16c16.897-14.232,19.057-39.467,4.826-56.364c-14.232-16.897-39.467-19.057-56.364-4.826
                            c-16.897,14.232-19.057,39.467-4.826,56.364c1.468,1.743,3.082,3.357,4.826,4.826c-9.626,5.021-17.596,12.716-22.953,22.16
                            c-4.206-6-9.722-10.965-16.13-14.519c12.265-12.547,12.037-32.662-0.511-44.927c-8.079-7.898-19.744-10.91-30.637-7.913V16h192
                            v297.136c-2.607-0.719-5.296-1.101-8-1.136c-17.626-0.047-31.953,14.204-32,31.831c-0.022,8.248,3.151,16.185,8.852,22.145
                            C298.444,369.53,292.928,374.495,288.722,380.495z M360,432v32h-64v-56c0-17.673,14.327-32,32-32c17.673,0,32,14.327,32,32V432z
                            M440,464h-64v-32c0-17.673,14.327-32,32-32c17.673,0,32,14.327,32,32V464z M392,368c0-8.837,7.163-16,16-16s16,7.163,16,16
                            s-7.163,16-16,16C399.168,383.989,392.011,376.832,392,368z M464,408h-14.494c-4.378-7.557-10.722-13.786-18.358-18.024
                            c12.202-12.784,11.731-33.04-1.053-45.243c-12.784-12.202-33.04-11.731-45.243,1.053c-11.803,12.365-11.803,31.824,0,44.189
                            c-3.664,2.035-7.048,4.539-10.064,7.449c-3.04-13.372-11.64-24.812-23.64-31.449c11.467-11.881,11.839-30.593,0.853-42.92V136h112
                            V408z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="376" y="184" width="16" height="128"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="424" y="184" width="16" height="128"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="40" y="184" width="16" height="128"/>
                    </g>
                </g>
                <g>
                    <g>
                        <rect x="88" y="184" width="16" height="128"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M288.009,32c-0.003,0-0.006,0-0.009,0h-96c-4.416-0.003-7.997,3.575-8,7.991c0,0.003,0,0.006,0,0.009v64
                            c-0.003,4.416,3.575,7.997,7.991,8c0.003,0,0.006,0,0.009,0h96c4.416,0.003,7.997-3.575,8-7.991c0-0.003,0-0.006,0-0.009V40
                            C296.003,35.584,292.425,32.003,288.009,32z M280,96h-80V48h80V96z"/>
                    </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
        </svg>

    )
}

export { Enterprise }