function ProcessNumber({number, subheader, description}) {
    return(
        <div className="process">
            <div className="process--number">{number}
                <div className="dot"></div>
            </div>
            <h2 className="process--header">{subheader}</h2>
            <div className="process--text">{description}</div>
    </div>
    )
}
export { ProcessNumber };