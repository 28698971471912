import {colors}  from '../colors';

function Mvp() {
    return(
        <svg id="Icons" height="512" viewBox="0 0 74 74" width="512" xmlns="http://www.w3.org/2000/svg" fill={colors.fillNav}>
            <path d="m32.415 59.085a1.007 1.007 0 0 1 -.325-.054 26.357 26.357 0 0 1 -17.121-17.121 1 1 0 0 1 .127-.9l14.87-21.218c9.5-12.329 24.814-18.928 41-17.681a1 1 0 0 1 .921.92c1.244 16.184-5.352 31.5-17.645 40.974l-.036.027-21.218 14.868a.993.993 0 0 1 -.573.185zm-15.386-17.347a24.339 24.339 0 0 0 15.233 15.233l20.777-14.562c11.527-8.892 17.81-23.179 16.913-38.36-15.2-.9-29.484 5.391-38.375 16.93z"/><path d="m68.53 23.292a1.022 1.022 0 0 1 -.189-.017 22.066 22.066 0 0 1 -11.508-6.108 22.066 22.066 0 0 1 -6.108-11.508 1 1 0 0 1 1.965-.375 20.073 20.073 0 0 0 5.557 10.469 20.073 20.073 0 0 0 10.469 5.557 1 1 0 0 1 -.186 1.982z"/><path d="m45.685 36.146a7.831 7.831 0 1 1 5.539-2.291 7.81 7.81 0 0 1 -5.539 2.291zm0-13.665a5.835 5.835 0 1 0 4.125 1.709 5.8 5.8 0 0 0 -4.125-1.709z"/><path d="m40.285 71.962h-.06a1 1 0 0 1 -.793-.477l-7.869-12.876a1 1 0 1 1 1.707-1.043l7.119 11.649 5.389-6.825a14.518 14.518 0 0 0 2.984-10.8l-.658-5.06a1 1 0 1 1 1.983-.258l.658 5.061a16.525 16.525 0 0 1 -3.4 12.3l-6.279 7.953a1 1 0 0 1 -.781.376z"/><path d="m15.912 42.583a1 1 0 0 1 -.521-.146l-12.874-7.869a1 1 0 0 1 -.1-1.637l7.953-6.279a16.532 16.532 0 0 1 12.3-3.4l5.061.658a1 1 0 1 1 -.258 1.983l-5.06-.658a14.523 14.523 0 0 0 -10.8 2.984l-6.828 5.392 11.649 7.119a1 1 0 0 1 -.522 1.853z"/><path d="m15.224 72.038a1 1 0 0 1 -.707-1.707l8.931-8.931a1 1 0 1 1 1.414 1.414l-8.931 8.931a1 1 0 0 1 -.707.293z"/><path d="m2.962 59.776a1 1 0 0 1 -.707-1.707l8.931-8.931a1 1 0 1 1 1.414 1.414l-8.931 8.931a1 1 0 0 1 -.707.293z"/><path d="m3.568 71.432a1 1 0 0 1 -.707-1.707l9.612-9.612a1 1 0 0 1 1.414 1.414l-9.612 9.612a1 1 0 0 1 -.707.293z"/>
        </svg>
    )
}

export {Mvp};