

const LoginError = () => {

    return  (
    <div className="login_err">
        <h2> Authorisation Error !</h2>
        <p>check your password  or email</p>

    </div>
    )
    
}

export { LoginError }