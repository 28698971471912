import { SideBarListItem } from "./SideBarListItem";

function SideBarServices({closePanel=Function.prototype}) {
    return(
        <div className="sublist">
            <SideBarListItem path="/saas-development" name="SaaS Development" closePanel={closePanel} />
            <SideBarListItem path="/mvp-development" name="MVP Development" closePanel={closePanel} />
            <SideBarListItem path="/pwa-development" name="PWA Development" closePanel={closePanel} />
            <SideBarListItem path="/marketplace-development" name="Online marketplace development" closePanel={closePanel} />
            <SideBarListItem path="/blockchain-development" name="Blockchain" closePanel={closePanel} />
            <SideBarListItem path="/project-management" name="Project Management" closePanel={closePanel} />
        </div>
    )
}

export { SideBarServices };