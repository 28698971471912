import "../pages-css/services.css";

import { SectionMain } from "./SectionMain";
import { des } from "../assets/description";
import { Section } from "./Section";
import { Block }  from "./Block";

import marketplace from "../assets/images/marketplace-dev.png";
import { BlockItem } from "./BlockItem";


import { Businesses } from "../assets/svg/Businesses";
import { Handshake } from "../assets/svg/Handshake";
import { Marketplace } from "../assets/svg/Marketplace";
import { Commerce } from "../assets/svg/Commerce";
import { PeertoPeer } from "../assets/svg/PeertoPeer";
import { User } from "../assets/svg/User";
import { FastLoading } from "../assets/svg/FastLoading";
import { Secure } from "../assets/svg/Secure";
import { Seo } from "../assets/svg/Seo";
import { UserFriendly } from "../assets/svg/UserFriendly";
import { Resize } from "../assets/svg/Resize";



function MarketPlace() {

    const {Sec1_H, Sec2_H, Sec3_H} = des.Marketplace_Page.Header;
    const {Sec1_D, Sec2_D, Sec3_D} = des.Marketplace_Page.Description;
    const {Bl1_Sec2_S, Bl2_Sec2_S, Bl3_Sec2_S, Bl4_Sec2_S, Bl5_Sec2_S, Bl6_Sec2_S} = des.Marketplace_Page.SubHeader.Sec2_S;
    const {Bl1_Sec3_S, Bl2_Sec3_S, Bl3_Sec3_S, Bl4_Sec3_S, Bl5_Sec3_S, Bl6_Sec3_S} = des.Marketplace_Page.SubHeader.Sec3_S;
    const {Bl1_Sec3_T, Bl2_Sec3_T, Bl3_Sec3_T, Bl4_Sec3_T, Bl5_Sec3_T, Bl6_Sec3_T} = des.Marketplace_Page.Text.Sec3_T;
    const {Sec1_B} = des.Marketplace_Page.ButtonName;
        return(
            <>
                <SectionMain
                 imgPath={marketplace} 
                 alt="Marketplace" 
                 path="/contacts" 
                 classBtn="button button--contact" 
                 classTextBtn="button--text" 
                 name={Sec1_B} 
                 header={Sec1_H}
                 description={Sec1_D}/>

                 
                <Section 
                 header={Sec2_H}
                 description={Sec2_D}
                 sectionClass="section section-block--services"
                 wrapperClass="blocks--services second-blocks--services"
                 Blocks={
                    <>
                        <Block blockClass="block--services" subheader={Bl1_Sec2_S} image={<Businesses/>}/>
                        <Block blockClass="block--services" subheader={Bl2_Sec2_S} image={<Handshake/>}/>
                        <Block blockClass="block--services" subheader={Bl3_Sec2_S} image={<Marketplace/>}/>
                        <Block blockClass="block--services" subheader={Bl4_Sec2_S} image={<Commerce/>}/>
                        <Block blockClass="block--services" subheader={Bl5_Sec2_S} image={<PeertoPeer/>}/>
                        <Block blockClass="block--services" subheader={Bl6_Sec2_S} image={<User/>}/>
                    </>
                        }
                />

                <Section 
                 header={Sec3_H}
                 description={Sec3_D}
                 sectionClass="section--secondary section-block--services"
                 wrapperClass="blocks--services second-blocks--services"
                 Blocks={
                    <>
                        <Block blockClass="block--services" subheader={Bl1_Sec3_S} 
                        blockItem={
                            <> 
                                <BlockItem blockDescr={Bl1_Sec3_T[0]}/> 
                                <BlockItem blockDescr={Bl1_Sec3_T[1]}/>
                                <BlockItem blockDescr={Bl1_Sec3_T[2]}/>
                                <BlockItem blockDescr={Bl1_Sec3_T[3]}/>
                            </>
                        } 
                        image={<UserFriendly/>}/>
                        <Block blockClass="block--services" subheader={Bl2_Sec3_S} 
                        blockItem={
                            <> 
                                <BlockItem blockDescr={Bl2_Sec3_T[0]}/> 
                                <BlockItem blockDescr={Bl2_Sec3_T[1]}/>
                                <BlockItem blockDescr={Bl2_Sec3_T[2]}/>
                            </>
                        } 
                        image={<FastLoading/>}/>
                        <Block blockClass="block--services" subheader={Bl3_Sec3_S}
                         blockItem={
                            <> 
                                <BlockItem blockDescr={Bl3_Sec3_T[0]}/> 
                                <BlockItem blockDescr={Bl3_Sec3_T[1]}/>
                                <BlockItem blockDescr={Bl3_Sec3_T[2]}/>
                            </>
                        } 
                        image={<Resize/>}/>
                        <Block blockClass="block--services" subheader={Bl4_Sec3_S}
                         blockItem={
                            <> 
                                <BlockItem blockDescr={Bl4_Sec3_T[0]}/> 
                                <BlockItem blockDescr={Bl4_Sec3_T[1]}/>
                                <BlockItem blockDescr={Bl4_Sec3_T[2]}/>
                            </>
                        } 
                         image={<Secure/>}/>
                        <Block blockClass="block--services" subheader={Bl5_Sec3_S} 
                        blockItem={
                            <> 
                                <BlockItem blockDescr={Bl5_Sec3_T[0]}/> 
                                <BlockItem blockDescr={Bl5_Sec3_T[1]}/>

                            </>
                        } 
                        image={<Seo/>}/>
                        <Block blockClass="block--services" subheader={Bl6_Sec3_S} 
                        blockItem={
                            <> 
                                <BlockItem blockDescr={Bl6_Sec3_T[0]}/> 
                            </>
                        }  
                        image={<Businesses/>}/>


                    </>
                        }
                />
            </>

        )
}

export { MarketPlace };