import styled from "styled-components";

import disclaimer from "./legal-disclaimer";

const Legal = styled.div`
padding: 60px 5% 10px;
margin: 60px 0 10px;
display: grid;
place-content: center;
min-height: 70vh;
`;

const H1 = styled.h1`
    font-size: 36px;
    margin-bottom: 30px;
    color: var(--color-secondary);
    text-align: left;
    width: 50vw;
    @media screen and (max-width: 1000px) {
    width: 100%;
    }
`;

const P = styled.p`
    margin: ${props => props.margin || '0'};
    color: var(--color);
    text-align: left;
`;

const Separator = styled.div`
   width: 90%;
   height:1px;
   background: rgba(0, 0, 0, .2);
   margin: 0 auto;
`

const BlockWrapper = styled.div`
text-align: left;
margin-bottom: 30px;
width: 50vw;
@media screen and (max-width: 1000px) {
    width: 100%;
}
`;

const LegalDisclaimer = () => {
    const {header, p} = disclaimer;
    return (
        <>
        <Legal>
            <H1>{header}</H1>
            <BlockWrapper>
            <P margin='0 0 10px'>{p[0]}</P>
            <P margin='0 0 10px'>{p[1]}</P>
            <P>{p[2]}</P>
            </BlockWrapper>
        </Legal>
        <Separator></Separator>
        </>
    )
}

export {LegalDisclaimer};