import { Link } from "react-router-dom";


function VideoText() {

    return(
        <div className="text-slider">
            <h1>Be a <span className="part-is-red">market leader</span> with premium custom software development</h1>
            <h2>Create a Multi-Vendor Marketplace.</h2>
            <Link to="/contacts"  className="button button--contact ">
                <span className="button--text">get in touch</span>
            </Link>
    </div>
    )
}
export { VideoText }