
const des = {
    saas: 'Our expert team creates secure and scalable cloud platforms.',
    pwa: 'Developing engaging progressive web apps with latest technologies.',
    mvp: 'We help entrepreneurs to quickly validate ideas with an MVP.',
    marketplace: 'We build fast-loading custom online marketplaces that users love.',
    blockchain: 'We find solutions for businesses building a secured decentralized system based on private, public or hybrid blockchain network.',
    management: 'Our Project Managers are available to lead your projects! So you can keep your promise with your customers and deliver a product on time!',

    appoach: 'We help entrepreneurs to quickly validate ideas with an MVP.',
    about: 'Learn what makes us experts in custom product development.',
    career: 'Join our expert team and work on great ideas with us.',
    reviews: 'Our client’s success stories speak best about our work.',

    flyingLogo: {
        textLeft: 'The combination of great design and diligent app development.',
        textRight: 'We make sleek and modern designs for your business.',
    },
    button: {
        flyingBtn: 'Start Here',
    },

    quality: {
        header: 'Quality is Our Priority',
        subHeader: 'We sustain high quality on each phase of the project: documentation, planning, development, testing and maintenance.',
        vision: {
            subHeader: 'Our Vision',
            text: 'We want to be a company that delivers value into your life.',
        },
        mission: {
            subHeader: 'Our Mission',
            text: 'To reveal your full potential by bringing your project to life.',
        },
        standart: {
            subHeader: 'Our Standarts',
            text: 'Excellence, scalability and maintainability, performance efficiency and great UI/UX.',
        },

    },
    services: {
        header: 'Create an outstanding marketplace',
        subHeader: 'Oz builds optimized and engaging platforms that bring a great volume of traffic, increase conversion, and grow sales. We will help you create a successful, scalable, and high-quality custom marketplace website that your users will enjoy.',
    },
    blockchainSection: {
        header: 'Blockchain technology development',
        items: {
            smartContract: {
                header: 'Smart Contract Development',
                description: 'We back smart contract with sound logic, applying the ERC-20 Token Contracts Standard.',
            },
            blockchainDev: {
                header: 'Custom Blockchain Development',
                description: 'Among our capabilities: writing custom private Blockchain for your business.',
            },
            applicationDev: {
                header: 'Application Development',
                description: 'We shape your idea into a mobile app for iOS, Android, the Web, or create a cross-platform solution.',
            }
        },
    },
    managementSection: {
        header: 'Project Management',
        text1: 'Our Project Managers are available to lead your projects! So you can keep your promise with your customers and deliver a product on time!',
        text2: 'They will be there to take care of your needs and minimise risks for your business, discuss timescales, budget and quality,keep track of the progress by using project management software, such as Trello and Jira to plan available resources and project stages, coordinate project team, keep you informed of any updates, solve issues as they arise and adjust plans, provide documentation and evaluating completed tasks!',
    },
    tech: {
        headerSection: 'Expertise you can rely on',
        subHeader1: 'Markets for goods, rent and services - we can build them together',
        text1: 'We provide consultations on every stage of the project development and build products following the best industry standards.',
        subHeader2: 'The technology stack we work with',
        text2: 'We have picked a list of tools helping us build high-quality custom products that solve all business challenges',
    },
    advantages: {
        header: 'Why Clients Choose Us',
        text1: 'Trending Technology',
        text2: 'Ethical Business Morals',
        text3: 'Dependable Resources',
        text4: 'No extra costs',
        text5: 'Long Term Partnership',
        text6: 'Excellent Solutions',
        
    },
    contactPage:{
        header:'Talk to Oz',
        text: 'We are always ready to help. If you have any questions, please do not hesitate to contact us via the contact form below. Our customer service team will attend to your query as soon as possible.',
    },
    form: {
        header: 'Tell us your idea',
        btnName: 'Send',
    },
    contactSide: {
        sideName: ['Contact us', 'Mailing address', 'Working time'],
        itemName1: ['T. +1 (650) 2501707', '105 N 1ST ST.,#429', '08:00 — 19:00 (GMT +1)'],
        itemName2: ['E.', 'San Jose, CA 95103 USA', 'Monday — Saturday'],
    },
    modal: {
        header: 'Let’s Have a Chat',
    },
    pages:{
        header: {
            blockchain: 'Blockchain Development',
            blSectDescr: 'We build blockchain applications',
            blockchainDo: {
                subheader: {
                    smart: 'Smart contracts',
                    apps: 'DApps',
                    ai: 'Integrating AI in Blockchain',
                    crypto: 'Crypto funds',
                    token: 'Tokenization',
                    tranding: 'Trading Platform',
                    audit: 'WhiteHatAudit',
                    dev: 'Blockchain development',
                },
            
            },
            blSectTech : 'Technologies we use',
            blSectProcess: 'Our development process',
            processNumber: {
                subheader: ['Ideation', 'Proof of concept', 'Design', 'Development and deployment', 'Support'],
            },
            sectionServices: {
                saas: '<span class="part-is-red">SaaS</span> development',
                mvp: '<span class="part-is-red">Minimum Viable Product</span> development',
                pwa: '<span class="part-is-red">Progressive web apps</span> development',
                marketplace: 'Online <span class="part-is-red">marketplace</span> development',
                project: '<span class="part-is-red">Project</span> management',
            },
            about: [
                '<span class="part-is-red">About Us</span> ',
                'How we work'
            ],
            career:'Career in <span class="part-is-red">Oz</span>',
            approach: 'Our Approach',

            saasSections: {
                services: 'Need help building a SaaS product?',
                solution:'Create a remarkable <span class="part-is-red">cloud-based solution</span>',
            },
            mvpSections: {
                services: 'How your business benefits from MVP development',
                solution:'Who will benefit from <span class="part-is-red">MVP</span> in product development?',
            },
            
        },
        subheader: {

            career:'Hot vocation',
            blockSubHeader: {
                block1: 'SMM specialist',
                block2: 'React.js | React Native developer',
                block3: 'Business Analyst',
                block4: 'Laravel Developer',  
            },

            approach:[ 'Agile <span class="part-is-red">mindset</span>', 'Tools helping us here'],

            blockAprSubHeader: {
                block1: 'Weekly designed sprints',
                block2: 'Daily standups',
                block3: 'Strong communications',
                block4: 'Regular retrospectives',
                block5: 'In-house Product Manager',
                block6: 'Fully transparent process',  
            },
            saasSections: {
                services: {
                    block1: 'New SaaS product development',
                    block2: 'Integration with 3rd-party services',
                    block3: 'Tweaking an existing SaaS app',
                    block4: 'Data migration (moving to the cloud)',
                },
                solution: {
                    block1: 'CRM systems',
                    block2: 'ERP systems',
                    block3: 'CMS systems',
                    block4: 'Inventory management',
                    block5: 'E-commerce',
                    block6: 'Productivity apps',
                    block7: 'HRM systems',
                    block8: 'Storage apps',
                },
            },
            mvpSections: {
                services: {
                    block1: 'Launch quickly',
                    block2: 'Minimize costs',
                    block3: 'Test idea fast',
                },
                solution: {
                    block1: 'Startups',
                    block2: 'Small and medium businesses',
                    block3: 'Enterprises',
                },
            },
        },
        description: {
            blockchain: 'As a leading Blockchain Oriented Company, we have assisted hundreds of clients with their Blockchain requests. Delivered blockchain solutions provided trust, transparency, security and improved singularity for our customers across different'
           + 'business domains including finance, real estate, stock trading, healthcare, retail, supply chain, MLM, exchanges and more. We have proven experience linking blockchain technology and business processes.',
        
           blockchainDo: {
               description: {
                smart: 'Designing, development, audit and optimization of self-executing smart contracts using Ethereum and Hyperledger to automate transactions without involving middlemen',
                apps: 'Development of smart and tailored decentralized apps based on blockchain technology that are resistant, transparent, easy to use and intuitive.',
                ai: 'Blockchain is about transformation, and integration with AI opens a bunch of new opportunities to your blockchain solution.',
                crypto: 'All the technology stack to setup fully compliant blockchain crypto funds (Index/Hedge).',
                token: 'Tokenization of all business operations, assets, funds, goods and services to be manipulated on a blockchain',
                tranding: 'Crypto-trading platforms, trading terminals, bots and exchanges that enable to buy, sell and store digital assets in a safe and compliant manner.',
                audit: 'Blockchain audit and consulting: security scanning, detection and prevention of unauthorized access.',
                dev: 'TrendLine Global will help you to set up a Blockchain network for your business and build secure decentralized databases to help store data, manage supply chains, control transactions, or any other business needs that Blockchain'
                +'can solve without any risks for privacy.',
               },
           },
           processNumber: {
               description:[
                   'We create a conceptual workflow and a blockchain model of your application.',
                   'At this stage we demonstrate the practical potential of your blockchain project',
                   'We prepare user interfaces and admin console design.',
                   'Here we release alpha, beta, and ready-to-launch versions of the application.',
                   'We provide you with ongoing support and knowledge transfer'
               ],
           },
           saas: 'Moving to the cloud? Become an industry leader with a cost-effective and highly scalable SaaS solution.',
           mvp: 'We help entrepreneurs to quickly validate business ideas and save time and budget.',
           pwa: 'Looking to extend your online presence? Make your brand stand out with a cross-platform PWA (progressive web app).',
           marketplace: 'Have a great idea for a custom marketplace? We know how to create a platform that your users will love.',
           project: 'Our Project Managers are available to lead your projects! So you can keep your promise with your customers and deliver a product on time!',

           about: 'We follow flexible methodologies to design resourceful and highly scalable products, including custom web development solutions and mobile application development services. Each of our projects is designed to suit the diverse needs of our'
           +'clients, and our R&D team examines all essential requirement criteria depending on the competitor traits and the market scenario.',

           career:'Oz is in the process of active amplification and modernization. That is why we regularly look for successful professionals. We are also ready to grow with them.',
           blockDescription: {
                block1: 'To make the process effective, we divide it into short sprints. This way, everybody sees specific results at the early stage of development.' ,
                block2: 'We use daily meetings to share gained results and coordinate next efforts in successful sprint delivery.' ,
                block3: 'We have a broader engagement with our clients. We believe it is the fundamental key to project success.' ,
                block4: 'At the end of each sprint, we overview results and identify actions for following improvements.' ,  
            },

            approach: 'Best results delivered are only possible when all the team members love what they do. Our experience proves it.',
            blockAprDescription: {
                block1: 'To make the process effective, we divide it into short sprints. This way, everybody sees specific results at the early stage of development.',
                block2: 'We use daily meetings to share gained results and coordinate next efforts in successful sprint delivery.',
                block3: 'We have a broader engagement with our clients. We believe it is the fundamental key to project success.',
                block4: 'At the end of each sprint, we overview results and identify actions for following improvements.',
                block5: 'At Oz, our product manager is always bridging the gap between the dev team and a client, helping to get expected results.',
                block6: 'We support the transparent development process, using best industry practices and always looking for new approaches.',  
            },
            saasSections: {
                services: 'With SaaS development services, Oz will help your business stand out from competitors with a unique, secure, and scalable solution. Whether you need to create a new cloud-based application for your business, or manage an existing product,'
                +'we are happy to share our expertise and passion for cloud solutions, and help you solve any SaaS-related challenge.',
                solution: 'With SaaS development services, we build high-quality single-tenant and multi-tenant SaaS platforms, applying the best industry practices.',
            },
            mvpSections: {
                services: 'With progressive web app development services, you will get a fast and modern cost-effective alternative to native apps. Turn your website into a progressive web app and quickly boost your business.',
                solution: 'As a result of the MVP development process, you will have a ready basic web solution, which can be further developed into a fully-functional product. MVP model works perfect for any enterprise type, be it a startup looking for potential'
                +'business opportunities, or an established firm launching a new product.',
            },
            mvpBlocks: {
                block1: 'You don’t have to waste time and build a fully-fledged product. Only the core functionality is created.',
                block2: 'Less functionality means less development effort and smaller budget. It’s the best way to validate your idea cheaply.',
                block3: 'Get feedback from early adopters, and decide whether to further evolve your product, or abandon the idea.',
            }

        },
        alt: {
            blockchain: 'Blockchain',
        },
        button: {
            blockchain: 'Let’s implement your idea',
            saas: 'get in touch',
            mvp: 'get in touch',
            pwa: 'get in touch',
            marketplace: 'get in touch',
            project: 'get in touch',
        }
    },
/*******
<-------------- PWA PAGE ----------->
 *******/
        PWA_Page: {
                Header: {
                    Sec1_H: '<span class="part-is-red">Progressive web apps</span> development',
                    Sec2_H: 'How a PWA will benefit your website',
                    Sec3_H: 'Benefits of <span class="part-is-red">Progressive Web Apps</span>',
                },

                SubHeader: {
                    Sec1_S: '',
                    Sec2_S: {
                        Bl1_Sec2_S:'Rank higher in Google search',
                        Bl2_Sec2_S:'Improve user acquisition',
                        Bl3_Sec2_S:'Increase conversion rate',
                    },
                    Sec3_S: {
                        Bl1_Sec3_S:'Fast-loading',
                        Bl2_Sec3_S:'Secure',
                        Bl3_Sec3_S:'Light',
                        Bl4_Sec3_S:'Engaging',
                        Bl5_Sec3_S:'SEO-friendly',
                        Bl6_Sec3_S:'Working offline',
                        Bl7_Sec3_S:'Easy to install',
                        Bl8_Sec3_S:'Cross-platform',
                    },
                },

                Description: {
                    Sec1_D: 'Looking to extend your online presence? Make your brand stand out with a cross-platform PWA (progressive web app).',
                    Sec2_D: 'With progressive web app development services, you will get a fast and modern cost-effective alternative to native apps. Turn your website into a progressive web app and quickly boost your business.',
                    Sec3_D: 'By combining the best of the web and mobile, the PWA technology offers a great number of advantages. While still being a web page, your PWA website will be able to compete with native apps in many aspects, at the same time being cross-platform'
                            +'and more secure. What’s more, when collaborating with an experienced progressive web app agency, you get the cost of PWA development that is considerably lower than native app development.',
                },
                Text: {
                    Sec1_T: '',
                    Sec2_T: {
                        Bl1_Sec2_T: 'Mobile-first approach guarantees that your website will be listed higher in Google search results, be it on mobile or desktop.',
                        Bl2_Sec2_T: 'Your website visitors will quickly turn into customers as they can easily install your PWA directly from the website.',
                        Bl3_Sec2_T: 'Fast loading time (less than 1 second) and push notifications increase user engagement rate and boost conversion',
                    },
                    Sec3: '',
                },
                ButtonName: {
                    Sec1_B: 'get in touch',
                    Sec2_B: '',
                    Sec3_B: '',
                },
        },
/*******
<-------------- MARKETPLACE PAGE ----------->
 *******/
        Marketplace_Page: {
            Header: {
                Sec1_H: 'Online <span class="part-is-red">marketplace</span> development',
                Sec2_H: 'Building unique and engaging marketplace websites',
                Sec3_H: 'Create a remarkable <span class="part-is-red">cloud-based solution</span>',
            },

            SubHeader: {
                Sec1_S: '',
                Sec2_S: {
                    Bl1_Sec2_S:'Business to Business (B2B)',
                    Bl2_Sec2_S:'Consumer to Consumer (C2C)',
                    Bl3_Sec2_S:'Multi-vendor marketplace',
                    Bl4_Sec2_S:'Business to Consumer (B2C)',
                    Bl5_Sec2_S:'Peer to Peer (P2P)',
                    Bl6_Sec2_S:'Market network',
                },
                Sec3_S: {
                    Bl1_Sec3_S:'User-friendly',
                    Bl2_Sec3_S:'Fast-loading',
                    Bl3_Sec3_S:'Scalable',
                    Bl4_Sec3_S:'Secure',
                    Bl5_Sec3_S:'SEO-friendly',
                    Bl6_Sec3_S:'Manageable',

                },
            },

            Description: {
                Sec1_D: 'Have a great idea for a custom marketplace? We know how to create a platform that your users will love.',
                Sec2_D: 'Whether you need to create a B2B marketplace or a multi-vendor marketplace platform, we can help you with any marketplace website development.',
                Sec3_D: 'With SaaS development services, we build high-quality single-tenant and multi-tenant SaaS platforms, applying the best industry practices.',
            },
            Text: {
                Sec1_T: '',
                Sec2_T: '',
                Sec3_T: {
                    Bl1_Sec3_T: [
                        'UI',
                        'Easy navigation',
                        'Responsive layout',
                        'Enhanced UX'
                    ],
                    Bl2_Sec3_T:  [
                        'Open in less than 3 seconds',
                        'Prefetch soon-to-be-needed resources',
                        'CDN, cache, compressing HTML/CSS'
                    ],
                    Bl3_Sec3_T: [
                        'Pre-built static generation',
                        'Follow PRPL Pattern (Push, Render, Pre-cache, Lazy-load)',
                        'DevOps best standards',
                    ],
                    Bl4_Sec3_T: [
                        'SSL by default',
                        'Vulnerabilities free',
                        'Secure from main attacks (Injection, XSS, CSRF)',
                    ],
                    Bl5_Sec3_T: [
                        'SEO best practices',
                        'Server-side rendering (SSR) support',
                    ],
                    Bl6_Sec3_T: [
                        'Content management system (CMS) enabled by default',
                    ],
                },
            },
            ButtonName: {
                Sec1_B: 'get in touch',
                Sec2_B: '',
                Sec3_B: '',
            },
        },
/*******
<-------------- PROJECT-MANAGEMENT PAGE ----------->
 *******/
        Project: {
            Header: {
                Sec1_H: '<span class="part-is-red">Project</span> management',
                Sec2_H: 'Oz project management integrations',
                Sec3_H: '<span class="part-is-red">The features</span> you will need',
            },

            SubHeader: {
                Sec1_S: '',
                Sec2_S: {
                    Bl1_Sec2_S:'Jira',
                    Bl2_Sec2_S:'Project',
                    Bl3_Sec2_S:'Zapier',
                    Bl4_Sec2_S:'Excel',
                    Bl5_Sec2_S:'Outlook',
                    Bl6_Sec2_S:'Slack',
                },
                Sec3_S: {
                    Bl1_Sec3_S:'WBS Tool & Gantt Chart',
                    Bl2_Sec3_S:'Agile Project Management',
                    Bl3_Sec3_S:'Time and Financial Tracking',
                    Bl4_Sec3_S:'Resource Management System',
       
                },
            },

            Description: {
                Sec1_D: 'Our Project Managers are available to lead your projects! So you can keep your promise with your customers and deliver a product on time!',
                Sec2_D: 'Oz project management is integrated with other software for your easy on-boarding.',
                Sec3_D: 'With SaaS development services, we build high-quality single-tenant and multi-tenant SaaS platforms, applying the best industry practices.',
            },
            Text: {
                Sec1_T: '',
                Sec2_T: '',
                Sec3_T: {
                    Bl1_Sec3_T: 'Work breakdown structure for top level project planning & Gantt for perfect project visualisation and easier planning',
                    Bl2_Sec3_T: 'Keep development processes optimized and under constant control',
                    Bl3_Sec3_T: 'Keep careful track of all the actual task realisation and easily compare it to your plan',
                    Bl4_Sec3_T: 'Easily assign and distribute resources and work force to the created tasks',
                },
            },
            ButtonName: {
                Sec1_B: 'get in touch',
                Sec2_B: '',
                Sec3_B: '',
            },
        },
/*******
<-------------- NOTFOUND PAGE ----------->
 *******/
        NOTFOUND: {
            Header: 'Oops, page not found',
            SubHeader: '',
            Description:'The Link You Followed Probably Broken, or the page has been removed.',
            ButtonName: 'back to home',
        }

}

export { des };