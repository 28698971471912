
import { VideoText } from "../VideoElem/VideoText";
import { VideoBlock } from "../VideoElem/VideoBlock";
import video from "../blockchain.mp4";


function Video() {
    return(
        <section className=" section--secondary section--slider">
            <div className="container container--slider">
                <div className="wrapper-slider">
                    <VideoText/>
                    <VideoBlock path={video} height="260"/>
                </div>
            </div>
        </section>

    )
}

export { Video };