import { Ico } from "./Ico"
import { ReactJs } from "../../assets/svg/ReactJs";
import { Vue } from "../../assets/svg/Vue";
import { Angular } from "../../assets/svg/Angular";
import { PHP } from "../../assets/svg/PHP";
import { NodeJs } from "../../assets/svg/NodeJs";
import { Postgresql } from "../../assets/svg/Postgresql";

function StackTex({classIco}) {
    return(
        <>
            <Ico classIco={classIco} image={<ReactJs/>} name="React" />
            <Ico classIco={classIco} image={<Vue/>} name="Vue.js" />
            <Ico classIco={classIco} image={<Angular/>} name="Angular" />
            <Ico classIco={classIco} image={<PHP/>} name="PHP" />
            <Ico classIco={classIco} image={<NodeJs/>} name="Node.js" />
            <Ico classIco={classIco} image={<Postgresql/>} name="PostgreSQL" />
        </>
    )
}
export { StackTex }