import { Button } from "../elements/Button";




function LeftText(props) {
    const {
        partClass, 
        text, 
        name, 
        classBtn, 
        classTextBtn, 
        openModal=Function.prototype, 
        animate,
        addAnimateBtn=Function.prototype,
        removeAnimateBtn=Function.prototype} = props;

    return(
        <div className={`${partClass}--text`}>

            <h2 className="change--header">
                {text}
            </h2>
            <Button name={name} 
                    classBtn={classBtn} 
                    classTextBtn={classTextBtn} 
                    openModal={openModal} 
                    animate={animate}
                    addAnimateBtn={addAnimateBtn}
                    removeAnimateBtn={removeAnimateBtn}/>
        </div>
    )
}

export { LeftText };