import { BlockchainItem } from "./BlockchainItem";
import { SmartContract } from "../../assets/svg/SmartContract";
import { MobileApp } from "../../assets/svg/MobileApp";
import { Blockchain } from "../../assets/svg/Blockchain";
import { colors } from "../../assets/colors";
import { Button } from "../../elements/Button";


function BlockchainItems({text, openModal=Function.prototype}) {
const smartContractHeader = text.smartContract.header;
const smartContractDescr = text.smartContract.description;
const blockchainDevHeader = text.blockchainDev.header;
const blockchainDevDescr = text.blockchainDev.description;
const applicationDevHeader = text.applicationDev.header;
const applicationDevDescr = text.applicationDev.description;

    return(
        <div className="blockchin--wrapper">
            <BlockchainItem item="smart-contract" header={smartContractHeader} description={smartContractDescr} image={<SmartContract/>}/>
            <BlockchainItem item="blockchain-dev" openModal={openModal} btn={<Button name="Get Started" openModal={openModal} classBtn="button button--contact  btn-white" classTextBtn="button--text" />}header={blockchainDevHeader} description={blockchainDevDescr} image={<Blockchain fill={colors.white} />}/>
            <BlockchainItem item="mob-app" header={applicationDevHeader} description={applicationDevDescr} image={<MobileApp/>}/>
        </div>
    )
}
export { BlockchainItems }