import { colors } from "../colors";

function Suitcase() {
    return(


        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="27.137px" height="27.137px" viewBox="0 0 27.137 27.137" fill={colors.red}>
        <g>
            <g>
                <path d="M24.121,4.9h-3.977c0-2.078-1.691-3.769-3.77-3.769h-5.613c-2.046,0-3.708,1.642-3.76,3.769H3.016
                    C1.357,4.9,0,6.256,0,7.915v6.031c0,0.195,0.021,0.385,0.058,0.57c0.024,0.125,0.067,0.244,0.106,0.364
                    c0.017,0.05,0.027,0.103,0.047,0.149c0.061,0.158,0.138,0.306,0.224,0.449c0.003,0.004,0.005,0.01,0.008,0.014
                    c0.091,0.152,0.196,0.291,0.311,0.423v7.075c0,1.658,1.357,3.016,3.015,3.016h19.598c1.658,0,3.016-1.357,3.016-3.016v-7.075
                    c0.115-0.132,0.221-0.271,0.311-0.423c0.004-0.004,0.006-0.01,0.01-0.016c0.085-0.144,0.162-0.291,0.223-0.447
                    c0.02-0.049,0.03-0.102,0.047-0.151c0.04-0.12,0.082-0.237,0.106-0.364c0.036-0.186,0.058-0.375,0.058-0.57V7.913
                    C27.136,6.256,25.779,4.9,24.121,4.9z M10.761,2.638h5.613c1.216,0,2.204,0.967,2.252,2.262H8.5
                    C8.5,3.652,9.514,2.638,10.761,2.638z M26.136,13.945c0,0.604-0.276,1.047-0.507,1.312l-0.135,0.153
                    c-0.36,0.339-0.842,0.55-1.373,0.55h-7.538v1h7.538c0.451,0,0.876-0.106,1.262-0.287v6.317c0,1.111-0.904,2.016-2.016,2.016H3.769
                    c-1.111,0-2.015-0.904-2.015-2.016v-6.317c0.385,0.181,0.811,0.287,1.262,0.287h7.538v-1H3.016c-0.532,0-1.013-0.211-1.374-0.55
                    l-0.134-0.153c-0.231-0.265-0.507-0.708-0.507-1.312V7.914c0-1.111,0.904-2.015,2.016-2.015h9.045v8.045h3.014V5.899h9.046
                    c1.11,0,2.016,0.904,2.016,2.015v6.031H26.136z"/>
                <rect x="12.061" y="15.453" width="3.015" height="3.015"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
        

    )
}
export { Suitcase };