import "../pages-css/services.css";

import { SectionMain } from "./SectionMain";
import { des } from "../assets/description";
import { Section } from "./Section";
import { Block } from "./Block";

import { Star } from "../assets/svg/Star";
import project from "../assets/images/project_management.png";
import { JiraColor } from "../assets/svg/JiraColor";
import { ProjectColor } from "../assets/svg/ProjectColor";
import { ZapierColor } from "../assets/svg/ZapierColor";
import { ExcelColor } from "../assets/svg/ExcelColor";
import { OutLookColor } from "../assets/svg/OutLookColor";
import { SlackColor } from "../assets/svg/SlackColor";

function Project() {
    // const name = des.pages.button.project;
    // const header = des.pages.header.sectionServices.project;
    // const description = des.pages.description.project;

    const {Sec1_H, Sec2_H, Sec3_H} = des.Project.Header;
    const {Sec1_D, Sec2_D, Sec3_D} = des.Project.Description;
    const {Bl1_Sec2_S, Bl2_Sec2_S, Bl3_Sec2_S, Bl4_Sec2_S, Bl5_Sec2_S, Bl6_Sec2_S} = des.Project.SubHeader.Sec2_S;
    const {Bl1_Sec3_S, Bl2_Sec3_S, Bl3_Sec3_S, Bl4_Sec3_S} = des.Project.SubHeader.Sec3_S;
    const {Bl1_Sec3_T, Bl2_Sec3_T, Bl3_Sec3_T, Bl4_Sec3_T} = des.Project.Text.Sec3_T;
    const {Sec1_B} = des.Marketplace_Page.ButtonName;
    const h2Style = {height: '50px'};
        return(
            <>
                <SectionMain
                 imgPath={project} 
                 alt="PProject" 
                 path="/contacts" 
                 classBtn="button button--contact" 
                 classTextBtn="button--text" 
                 name={Sec1_B} 
                 header={Sec1_H}
                 description={Sec1_D}/>

                <Section 
                 header={Sec2_H}
                 description={Sec2_D}
                 sectionClass="section section-block--services"
                 wrapperClass="blocks--services third-blocks--services"
                 Blocks={
                    <>
                        <Block blockClass="block--services" subheader={Bl1_Sec2_S} image={<JiraColor/>}/>
                        <Block blockClass="block--services" subheader={Bl2_Sec2_S} image={<ProjectColor/>}/>
                        <Block blockClass="block--services" subheader={Bl3_Sec2_S} image={<ZapierColor/>}/>
                        <Block blockClass="block--services" subheader={Bl4_Sec2_S} image={<ExcelColor/>}/>
                        <Block blockClass="block--services" subheader={Bl5_Sec2_S} image={<OutLookColor/>}/>
                        <Block blockClass="block--services" subheader={Bl6_Sec2_S} image={<SlackColor/>}/>
                    </>
                }
                />
                <Section 
                 header={Sec3_H}
                 description={Sec3_D}
                 sectionClass="section--secondary section-block--services"
                 wrapperClass="blocks--services"
                 Blocks={
                    <>
                        <Block blockClass="block--services" subheader={Bl1_Sec3_S} blockDescr={Bl1_Sec3_T} image={<Star/>}  style={h2Style }/>
                        <Block blockClass="block--services" subheader={Bl2_Sec3_S} blockDescr={Bl2_Sec3_T} image={<Star/>}  style={h2Style }/>
                        <Block blockClass="block--services" subheader={Bl3_Sec3_S} blockDescr={Bl3_Sec3_T} image={<Star/>}  style={h2Style }/>
                        <Block blockClass="block--services" subheader={Bl4_Sec3_S} blockDescr={Bl4_Sec3_T} image={<Star/>}  style={h2Style }/>
                    </>
                }
                />
            </>
        )
}

export { Project };