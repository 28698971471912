

function Button(props) {
    const {
        name, 
        classBtn,
        classTextBtn, 
        type, 
        openModal=Function.prototype} = props;


    return(
        <button type={type} 
                className={classBtn} 
                onClick={openModal}
                >
            <span className={classTextBtn}>{name}</span>
        </button>
    )
}

export { Button };

