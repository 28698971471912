import { AdvItem } from "./AdvItem";
import { des } from "../../assets/description";
import { Innovation } from "../../assets/svg/Innovation";
import { Handshake } from "../../assets/svg/Handshake";
import { Shield } from "../../assets/svg/Shield";
import { SaveMoney } from "../../assets/svg/SaveMoney";
import { Partnership } from "../../assets/svg/Partnership";
import { LightBulb } from "../../assets/svg/LightBulb";

function AdvItems() {
    return(
        <div className="advantages">
            <AdvItem text={des.advantages.text1} image={<Innovation/>} />
            <AdvItem text={des.advantages.text2} image={<Handshake/>} />
            <AdvItem text={des.advantages.text3} image={<Shield/>} />
            <AdvItem text={des.advantages.text4} image={<SaveMoney />} />
            <AdvItem text={des.advantages.text5} image={<Partnership/>} />
            <AdvItem text={des.advantages.text6} image={<LightBulb/>} />
        </div>
    )
}

export { AdvItems }