import { QualityItem } from "./QualityItem";
import { des } from "../assets/description";
import { Vision } from "../assets/svg/Vision";
import { Mission } from "../assets/svg/Mission";
import { Standart } from "../assets/svg/Standart"

function QualityItems() {
    return(
        <div className="quality--items">
            <QualityItem text={des.quality.vision.text} subheader={des.quality.vision.subHeader}  image={<Vision/>} />
            <QualityItem text={des.quality.mission.text} subheader={des.quality.mission.subHeader} image={<Mission/>} />
            <QualityItem text={des.quality.standart.text} subheader={des.quality.standart.subHeader} image={<Standart/>} />
        </div>
    )
}

export { QualityItems }