import { colors } from "../colors";

function Trade() {
    return(

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="512" height="512" fill={colors.red}>
            <g id="solid"><path d="M40,309a7.976,7.976,0,0,0,3.147-.648l35.217-15.093A23.986,23.986,0,0,0,120,277c0-.327-.012-.65-.025-.974l38-15.2A23.984,23.984,0,0,0,200,245c0-.327-.012-.65-.025-.974l38-15.2a23.96,23.96,0,0,0,37.382-1.664l36.668,11a24,24,0,1,0,45.926-10.838l44.9-42.259a23.982,23.982,0,0,0,33.97-31.972L464,127.515V133a8,8,0,0,0,16,0V109a8,8,0,0,0-8-8H448a8,8,0,0,0,0,16h3.828L424.62,142.607a23.994,23.994,0,0,0-31.5,29.645l-46.136,43.422a23.948,23.948,0,0,0-30.348,7.166l-36.668-11A24,24,0,0,0,232,213c0,.327.012.651.025.974l-38,15.2A23.984,23.984,0,0,0,152,245c0,.327.012.65.025.974l-38,15.2A23.984,23.984,0,0,0,72,277c0,.524.023,1.042.056,1.558L36.849,293.646A8,8,0,0,0,40,309Z"/><path d="M343.506,167.646a7.965,7.965,0,0,0-2.415-.374H302.433A259.206,259.206,0,0,0,305.293,136H351.67A95.165,95.165,0,0,1,343.506,167.646Zm-4.824-88.374A95.327,95.327,0,0,1,351.664,120H305.289A259.786,259.786,0,0,0,300.8,79.272Zm-11.849-16H297.208a259.331,259.331,0,0,0-7.915-25.316A96.326,96.326,0,0,1,326.833,63.272Zm-84.961-30.23a96.326,96.326,0,0,1,28.26,0,243.138,243.138,0,0,1,10.521,30.23h-49.3A242.911,242.911,0,0,1,241.872,33.042Zm-14.357,46.23h56.976A243.69,243.69,0,0,1,289.283,120H222.72A243.8,243.8,0,0,1,227.515,79.272ZM289.285,136a243.551,243.551,0,0,1-3.05,31.272H225.766A243.317,243.317,0,0,1,222.718,136ZM222.714,37.954a258.969,258.969,0,0,0-7.922,25.318H185.167A96.326,96.326,0,0,1,222.714,37.954Zm-49.4,41.318H211.2A259.674,259.674,0,0,0,206.716,120h-46.38A95.327,95.327,0,0,1,173.318,79.272Zm-4.773,88.356A95.737,95.737,0,0,1,160.331,136h46.378a259.578,259.578,0,0,0,2.861,31.272H170.909A7.99,7.99,0,0,0,168.545,167.628Z"/><path d="M488,461H432V229H400V461H352V293H320V461H272V269H240V461H192V301H160V461H112V333H80V461H24a8,8,0,0,0,0,16H488a8,8,0,0,0,0-16Z"/></g>
        </svg>

    )
}
export { Trade };