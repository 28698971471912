import { des } from "../../assets/description";
import { Button } from "../../elements/Button";

function SectionHeader({openModal=Function.prototype}) {

    const header = des.pages.header.blockchain;
    const name = des.pages.button.blockchain;

    return(
        <div className="blockchain--header">
            <h1>{header}</h1>     
            <Button classBtn="button button--contact btn--blockchain " classTextBtn="button--text button-text--blockchain" openModal={openModal} name={name}/>
        </div>
    )
}

export { SectionHeader }