import { Icon } from "./Icon";


function ModalClose({closeModal=Function.prototype}) {
    return(
        <div className="modal--close " onClick={closeModal}>
            <Icon iClass="material-icons"  name="close"/>
        </div>
    )
}

export { ModalClose };