import { FooterItem } from "../elements/FooterItem";
import { FooterSocial } from "../elements/FooterSocial";
import { FooterLink } from "../elements/FooterLink";

function FooterWrapper() {
    return(
        <div className="footer--wrapper">
            <FooterItem itemClass="service" item1={<FooterLink path="/saas-development" name="SaaS development"/>} item2={<FooterLink path="/mvp-development" name="MVP development"/>} item3={<FooterLink path="/pwa-development" name="PWA development"/>} item4={<FooterLink path="/marketplace-development" name="Online marketplace development"/>} item5={<FooterLink path="/blockchain-development" name="Blockchain"/>} item6={<FooterLink path="project-management" name="Project management"/>}/>
            <FooterItem 
                itemClass="company" 
                item1={<FooterLink path="/about-us" name="About"/>} 
                item2={<FooterLink path="/approach" name="Approach"/>} 
                item3={<FooterLink path="/career" name="Careers"/>}
                item4={<FooterLink path="/privacy-policy" name="Privacy Policy"/>}
                item5={<FooterLink path="/legal-disclaimer" name="Legal Disclaimer"/>}/>
            <FooterItem itemClass="contact" item1={<FooterLink path="/contacts" name="Get in touch" classLink='contact-footer'/>} />
            <FooterSocial name="socials"/>
        </div>
    )
}

export { FooterWrapper };