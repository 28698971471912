import { des } from "../assets/description";
import { BlockchainItems } from "./BlockchainElem/BlockchainItems";

function Blockchain({openModal=Function.prototype}) {
    const headerSection = des.blockchainSection.header;
    return(
        <section className="section section--blockchain">
            <div className="container">
                <h1>{headerSection}</h1>
                <BlockchainItems text={des.blockchainSection.items} openModal={openModal}/>
            </div>
        </section>
    )
}

export { Blockchain };