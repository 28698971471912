import { colors } from "../colors";
function Passport() {
    return(

    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 469.17 469.17" fill={colors.red}>
        <g>
            <g>
                <path d="M154.585,134.152c-53.019,0-96,42.981-96,96c0,53.019,42.981,96,96,96c53.019,0,96-42.981,96-96
                    C250.585,177.133,207.604,134.152,154.585,134.152z M225.632,194.152h-34.598c-3.309-16.58-8.477-29.981-13.754-40.32
                    C198.422,160.141,215.777,174.801,225.632,194.152z M178.585,230.152c0,7.142-0.535,13.703-1.27,20h-45.457
                    c-0.738-6.297-1.274-12.859-1.274-20c0-7.145,0.527-13.705,1.258-20h45.489C178.058,216.447,178.585,223.008,178.585,230.152z
                    M152.257,150.386c0.789-0.023,1.531-0.234,2.328-0.234c0.805,0,1.55,0.215,2.351,0.238c6.004,9.211,13.305,23.782,17.726,43.762
                    H134.51C138.952,174.103,146.28,159.539,152.257,150.386z M131.89,153.832c-5.258,10.317-10.43,23.733-13.742,40.32h-34.61
                    C93.394,174.801,110.749,160.145,131.89,153.832z M74.585,230.152c0-6.946,1.168-13.57,2.84-20h38.293
                    c-0.668,6.336-1.133,12.926-1.133,20c0,7.078,0.477,13.66,1.156,20H77.425C75.753,243.722,74.585,237.098,74.585,230.152z
                    M83.538,266.152h34.649c3.344,16.617,8.519,30.133,13.68,40.313C110.737,300.152,93.39,285.496,83.538,266.152z M156.909,309.918
                    c-0.789,0.024-1.527,0.234-2.324,0.234s-1.535-0.211-2.328-0.234c-5.734-8.82-13.203-23.424-17.726-43.766h40.11
                    C170.124,286.481,162.663,301.078,156.909,309.918z M177.311,306.465c5.168-10.19,10.34-23.695,13.676-40.313h34.645
                    C215.78,285.494,198.436,300.149,177.311,306.465z M193.433,250.152c0.676-6.34,1.152-12.922,1.152-20
                    c0-7.074-0.461-13.664-1.129-20h38.289c1.672,6.43,2.84,13.055,2.84,20c0,6.946-1.168,13.57-2.84,20H193.433z"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="74.585" y="374.152" width="160" height="16"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="333.559" y="268.423" transform="matrix(0.2589 -0.9659 0.9659 0.2589 -7.9406 542.4973)" width="32" height="16"/>
            </g>
        </g>
        <g>
            <g>
                
                    <rect x="266.652" y="368.841" transform="matrix(0.2588 -0.9659 0.9659 0.2588 -124.8553 590.9692)" width="111.997" height="16"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M434.858,50.792L249.402,1.098C246.628,0.355,243.842,0,241.101,0c-14.129,0.001-27.058,9.43-30.89,23.726l-16.726,62.426
                    H26.585c-8.836,0-16,7.163-16,16v320c0,8.837,7.164,16,16,16h184.801l111.664,29.92c2.774,0.743,5.558,1.098,8.301,1.098
                    c14.129,0,27.058-9.43,30.89-23.726l95.246-355.461C462.062,72.913,451.929,55.366,434.858,50.792z M26.585,422.152v-320h240
                    c8.824,0,16,7.178,16,16v288c0,8.822-7.176,16-16,16H26.585z M442.034,85.842l-95.246,355.461
                    c-1.875,6.988-8.222,11.868-15.438,11.868c-1.39,0-2.789-0.186-4.16-0.554l-55.793-14.95c15.37-2.335,27.187-15.49,27.187-31.514
                    V279.309l8.195,2.196l8.285-30.91l-15.457-4.141l-1.023,3.824V123.129c4.988,1.337,10.746,2.88,16.328,4.376l18.922,61.862
                    l13.246,3.55l-1.254-57.129c8.699,2.332,17.582,4.711,23.402,6.271l10.285,18.137l8.832,2.366l-1.519-25.253l13.942-21.111
                    l-8.832-2.366l-17.973,10.565c-5.824-1.559-14.703-3.939-23.406-6.271l29.652-48.846l-13.246-3.55l-47.317,44.114
                    c-9.305-2.493-19.457-5.215-24.957-6.687c-5.39-10.074-15.886-17.004-28.105-17.004h-56.539l15.617-58.284
                    c1.875-6.987,8.223-11.867,15.438-11.868c1.39,0,2.789,0.186,4.16,0.554l185.457,49.694c4.129,1.106,7.578,3.754,9.715,7.455
                    C442.57,77.404,443.139,81.715,442.034,85.842z"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="339.372" y="218.479" transform="matrix(0.2558 -0.9667 0.9667 0.2558 39.6998 504.257)" width="16" height="15.728"/>
            </g>
        </g>
        <g>
            <g>
                
                    <rect x="369.74" y="226.546" transform="matrix(0.2558 -0.9667 0.9667 0.2558 54.5006 539.6177)" width="15.999" height="15.728"/>
            </g>
        </g>
        <g>
            <g>
                
                    <rect x="309.006" y="210.437" transform="matrix(0.2558 -0.9667 0.9667 0.2558 24.8764 468.9158)" width="15.999" height="15.728"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>

    )
}
export { Passport }