import { des } from "../../assets/description";
import { ProcessNumber } from "./ProcessNumber";

function SectionProcess () {
    const header = des.pages.header.blSectProcess;
    const sub =  des.pages.header.processNumber.subheader;
    const description = des.pages.description.processNumber.description;
    return(
        <section className="section--secondary section--process">
            <h1>{header}</h1>
            <div className="container container--process">
                <ProcessNumber number="01" subheader={sub[0]} description={description[0]} />
                <ProcessNumber number="02" subheader={sub[1]} description={description[1]} />
                <ProcessNumber number="03" subheader={sub[2]} description={description[2]} />
                <ProcessNumber number="04" subheader={sub[3]} description={description[3]} />
                <ProcessNumber number="05" subheader={sub[4]} description={description[4]} />
            </div>
        </section>
    )
}
export { SectionProcess }