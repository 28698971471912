import { HeaderWrapper } from "./HeaderWrapper";
import { Image } from "./Image";


function SectionMain(props) {
    const {
        path,
        classBtn,
        classTextBtn,
        name,
        header,
        imgPath,
        alt,
        description
    }= props;

    return(
        <section className="section --services">
            <div className="container container--services">
                <HeaderWrapper path={path} classBtn={classBtn} classTextBtn={classTextBtn} name={name} header={header} description={description}/>
                <Image imgPath={imgPath} alt={alt}/>
            </div>
        </section>

    )
}
export { SectionMain }