import { Form } from "../sections/PageContactElem/Form";


function ModalContent({closeModal=Function.prototype}) {
    return (
        <div className="modal--content ">
            <Form closeModal={closeModal}  close={true}/>
        </div> 
    )
}
export { ModalContent };