import { Ico } from "./Ico"
import { Rental } from "../../assets/svg/Rental";
import { Sedan } from "../../assets/svg/Sedan";
import { Insurance } from "../../assets/svg/Insurance";
import { Suitcase } from "../../assets/svg/Suitcase";
import { Shop } from "../../assets/svg/Shop";
import { Passport } from "../../assets/svg/Passport";

function ImageTex({classIco}) {
    return(
        <>
            <Ico classIco={classIco} image={<Rental/>} name="Rental" />
            <Ico classIco={classIco} image={<Sedan/>} name="Automobile" />
            <Ico classIco={classIco} image={<Insurance/>} name="Insurance" />
            <Ico classIco={classIco} image={<Suitcase/>} name="Finance" />
            <Ico classIco={classIco} image={<Shop/>} name="Commerce" />
            <Ico classIco={classIco} image={<Passport/>} name="Travel" />
        </>
    )
}
export { ImageTex }