import { SideBarItem } from "../elements/SideBarItem";
import { Button } from "../elements/Button";
import { SideBarSocial } from "../elements/SideBarSocial";
import { SideBarServices } from "../elements/SideBarServices";
import { SideBarCompany } from "../elements/SideBarCompany";

function SideBarPanel({animation, closePanel=Function.prototype, openModal=Function.prototype}) {
    return(
        <div className={`sidebar--panel ${animation}`}>
            <ul className="navigation--sidebar_list">
                <SideBarItem path="/" navItem="home" closePanel={closePanel} />
                <SideBarItem path="#!" navItem="services" sub="sub" closePanel={closePanel} sublist={<SideBarServices closePanel={closePanel}/>}/>
                <SideBarItem path="#!" navItem="company" sub="sub" closePanel={closePanel} sublist={<SideBarCompany closePanel={closePanel}/>}/>
                <SideBarItem path="/blockchain-development" navItem="blockchain" closePanel={closePanel}/>
                <SideBarItem path="/contacts" navItem="contact" closePanel={closePanel}/>
            </ul>
            <Button name="Inquire Now" classBtn="button button--form button--sidebar" classTextBtn="button--text" openModal={openModal}/>
            <SideBarSocial />
        </div>
    )
}
export { SideBarPanel }