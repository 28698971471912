import "../pages-css/contact.css";
import { des } from "../assets/description";
import { ModalContent } from "./PageContactElem/ModalContent";
import { ContactSide } from "./PageContactElem/ContactSide";

function PageContactSection(){
    const header = des.contactPage.header;
    const text = des.contactPage.text;
    return(
        <section className="section section--contact">
            <div className="container container--contact-page">
                <div className="contact-page--header">
                    <h1>{header}</h1>
                    <p>{text}</p>
                    <div className="contact--wrapper">
                        <ModalContent/>
                        <ContactSide/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export { PageContactSection };