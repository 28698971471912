
const LoginInput = (
    {
        image,
        type, 
        placeholder, 
        name, 
        blurHandler = Function.prototype,
        handler = Function.prototype,
        focus = Function.prototype,
        value
    }
    ) => {

    return (
        <div className="form-login--input">
            {image}
            <input 
                type={type} 
                placeholder={placeholder} 
                name={name}
                value={value} 
                required  
                onBlur={e => blurHandler(e)}
                onChange={e => handler(e)}
                onFocus={e => focus(e)}
            />
        </div>
    )
}

export { LoginInput }