

function ElemVideo({path, height}) {
    
    return(
        <video  height={height} loop  controls  muted>
            <source src={path}/>
        </video>
    )
}
export { ElemVideo }