import { Link } from "react-router-dom";

function ButtonLink(props) {
    const {
        name, 
        classBtn,
        classTextBtn, 
        path
    } = props;


    return(
        <Link to={path} className={classBtn} >
            <span className={classTextBtn}>{name}</span>
        </Link>
    )
}

export { ButtonLink };
