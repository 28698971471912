import { colors } from "../colors";

function CrossPlatform() {
    return(

                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" fill={colors.red}>
                    <g><path d="M476,184h-4V108a28.031,28.031,0,0,0-28-28H68a28.031,28.031,0,0,0-28,28V232H36A28.031,28.031,0,0,0,8,260V396a28.031,28.031,0,0,0,28,28h72a28.031,28.031,0,0,0,28-28V368h69.75l-4,16H184a8,8,0,0,0-8,8v24a8,8,0,0,0,8,8H328a8,8,0,0,0,8-8V392a8,8,0,0,0-8-8H310.25l-4-16H344v28a28.031,28.031,0,0,0,28,28H476a28.031,28.031,0,0,0,28-28V212A28.031,28.031,0,0,0,476,184ZM120,396a12.01,12.01,0,0,1-12,12H36a12.01,12.01,0,0,1-12-12V260a12.01,12.01,0,0,1,12-12h6.23l6.18,18.53A8,8,0,0,0,56,272H88a8,8,0,0,0,7.59-5.47L101.77,248H108a12.01,12.01,0,0,1,12,12ZM59.1,248H84.9l-2.67,8H61.77ZM320,400v8H192v-8ZM218.25,384l4-16h67.5l4,16ZM344,352H136V320H344Zm0-140v92H136V260a28.031,28.031,0,0,0-28-28H56V108A12.01,12.01,0,0,1,68,96H444a12.01,12.01,0,0,1,12,12v76H372A28.031,28.031,0,0,0,344,212Zm92.9-12-2.67,8H413.77l-2.67-8ZM488,396a12.01,12.01,0,0,1-12,12H372a12.01,12.01,0,0,1-12-12V212a12.01,12.01,0,0,1,12-12h22.23l6.18,18.53A8,8,0,0,0,408,224h32a8,8,0,0,0,7.59-5.47L453.77,200H476a12.01,12.01,0,0,1,12,12Z"/><path d="M440,384H408a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Z"/><path d="M88,384H56a8,8,0,0,0,0,16H88a8,8,0,0,0,0-16Z"/><path d="M264,328H248a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Z"/></g>
                </svg>

    )
}

export { CrossPlatform }