import { Link } from "react-router-dom";
import { useState } from "react";
import { Icon } from "../elements/Icon";

function SideBarItem({path, navItem, sub, sublist, closePanel=Function.prototype}) {
    const [subList, setSubList] = useState(false);
    const [rotate, setRotate] = useState({transform: 'rotate(0deg)'});
    const toggleSubList = () => {
        setSubList(subList ? false : true);
        if(subList) {
            setRotate({transform: 'rotate(0deg)'});
        } else {
            setRotate({transform: 'rotate(180deg)'});
        }
    }

    return(
        <li  className={`navigation--sidebar_list-item ${sub}`}>
            <Link to={path} onClick={closePanel} >{!sub && navItem} </Link>
                {sub &&
                <div onClick={toggleSubList}>
                    {navItem}
                    <Icon iClass="material-icons sub_list-item--icons" name="arrow_drop_down" styleIcon={rotate}/>
                    {subList && sublist}
                </div>
                    
                }
                
            </li>
         
    )
}
export { SideBarItem };