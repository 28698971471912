
import { ModalContent } from "../elements/ModalContent";
import { ModalHeader } from "../elements/ModalHeader";


function Modal({closeModal=Function.prototype}) {
    return(
        <div className="modal--wrapper">
            <div className="modal--container ">
       
                <ModalHeader/>
                <ModalContent closeModal={closeModal}/>
            </div>
        </div>
    )
}

export { Modal };