import "../pages-css/services.css";

import { SectionMain } from "./SectionMain";
import { des } from "../assets/description";
import { Section  } from "./Section";
import { Block } from "./Block"

import pwa from "../assets/images/PWA.png";

import { Stairs } from "../assets/svg/Stairs";
import { Magnet } from "../assets/svg/Magnet";
import { Increase } from "../assets/svg/Increase";
import { FastLoading } from "../assets/svg/FastLoading";
import { Secure } from "../assets/svg/Secure";
import { Responsibility } from "../assets/svg/Responsibility";
import { Light } from "../assets/svg/Light";
import { Seo } from "../assets/svg/Seo";
import { Offline } from "../assets/svg/Offline";
import { Install } from "../assets/svg/Install";
import { CrossPlatform } from "../assets/svg/CrossPlatform";


function PWA() {

    const {Sec1_H, Sec2_H, Sec3_H} = des.PWA_Page.Header;
    const {Sec1_D, Sec2_D, Sec3_D} = des.PWA_Page.Description;
    const {Bl1_Sec2_S, Bl2_Sec2_S, Bl3_Sec2_S} = des.PWA_Page.SubHeader.Sec2_S;
    const {Bl1_Sec3_S, Bl2_Sec3_S, Bl3_Sec3_S, Bl4_Sec3_S, Bl5_Sec3_S, Bl6_Sec3_S,Bl7_Sec3_S, Bl8_Sec3_S, } = des.PWA_Page.SubHeader.Sec3_S;
    const {Bl1_Sec2_T, Bl2_Sec2_T, Bl3_Sec2_T} = des.PWA_Page.Text.Sec2_T;
    const {Sec1_B} = des.PWA_Page.ButtonName;

        return(
            <>
                <SectionMain
                 imgPath={pwa} 
                 alt="PWA" 
                 path="/contacts" 
                 classBtn="button button--contact" 
                 classTextBtn="button--text" 
                 name={Sec1_B} 
                 header={Sec1_H}
                 description={Sec1_D}/>

                <Section 
                 header={Sec2_H}
                 description={Sec2_D}
                 sectionClass="section section-block--services"
                 wrapperClass="blocks--services"
                 Blocks={
                    <>
                        <Block blockClass="block--services" subheader={Bl1_Sec2_S} blockDescr={Bl1_Sec2_T}image={<Stairs/>}/>
                        <Block blockClass="block--services" subheader={Bl2_Sec2_S} blockDescr={Bl2_Sec2_T}image={<Magnet/>}/>
                        <Block blockClass="block--services" subheader={Bl3_Sec2_S} blockDescr={Bl3_Sec2_T}image={<Increase/>}/>
                    </>
                }
                />
                <Section 
                 header={Sec3_H}
                 description={Sec3_D}
                 sectionClass="section--secondary section-block--services"
                 wrapperClass="blocks--services"
                 Blocks={
                    <>
                        <Block blockClass="block--services" subheader={Bl1_Sec3_S} image={<FastLoading/>}/>
                        <Block blockClass="block--services" subheader={Bl2_Sec3_S} image={<Secure/>}/>
                        <Block blockClass="block--services" subheader={Bl3_Sec3_S} image={<Light/>}/>
                        <Block blockClass="block--services" subheader={Bl4_Sec3_S} image={<Responsibility/>}/>
                        <Block blockClass="block--services" subheader={Bl5_Sec3_S} image={<Seo/>}/>
                        <Block blockClass="block--services" subheader={Bl6_Sec3_S} image={<Offline/>}/>
                        <Block blockClass="block--services" subheader={Bl7_Sec3_S} image={<Install/>}/>
                        <Block blockClass="block--services" subheader={Bl8_Sec3_S} image={<CrossPlatform/>}/>

                    </>
                }
                />
            </>
        )
}

export { PWA };